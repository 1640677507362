import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { CreatePendService } from "../../../../../shared/pend/create-pend.service";
import { PendType } from "../../../../../shared/pend/pend-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { BulkAction } from "../bulk-action.enum";
import { BulkUpdate } from "../bulk-update.model";
import { BulkUpdatesService } from "../bulk-updates.service";
import { BulkChasesAssignmentRequest } from "../model/bulk-chase-assign.model";
import { BasicGridComponent } from "./../../../../../shared/grid/basic-grid/basic-grid.component";
import { BulkUpdatesComponent } from "./../bulk-updates.component";
import { BulkUpdateChaseService } from "./bulk-update-chase.service";

@Component({
  selector: "app-bulk-update-chase",
  templateUrl: "./bulk-update-chase.component.html",
  providers: [BulkUpdateChaseService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateChaseComponent implements OnInit, OnChanges {
  @Input()
  get isClinical(): string {
    return this.pIsClinical;
  }
  set isClinical(value: string) {
    this.pIsClinical = value;
    this.setPendCodesInputOptions();
  }
  @Input()
  get clinicalType(): string {
    return this.pClinicalType;
  }
  set clinicalType(value: string) {
    this.pClinicalType = value;
  }
  @Input()
  get hasClinicalNonClinicalChases(): string {
    return this.pMixChases;
  }
  set hasClinicalNonClinicalChases(value: string) {
    this.pMixChases = value;
    this.setPendCodesInputOptions();
  }

  constructor(
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private service: BulkUpdateChaseService,
    private messagingService: MessagingService,
    private authService: AuthService,
    protected readonly automapper: AutomapperService,
    protected readonly createPendService: CreatePendService,
    private bulkUpdatesService: BulkUpdatesService,
    private bulkUpdatesComponent: BulkUpdatesComponent
  ) { }

  get selectedThirdPartyPendCode(): boolean {
    return this.pendCodesInput.getSelectedOption(this.formGroup).extra.isThirdParty;
  }

  get selectedClinicalPendCode(): boolean {
    return this.pendCodesInput.getSelectedOption(this.formGroup).extra.isClinical;
  }

  get filteredPendCodeOptions(): SelectableInput[] {
    let pendCodes = this.originalPendCodeOptions;
    let autoClosePends = pendCodes.filter(x => x.extra.isAutoclose);
    pendCodes = this.isClinical ? pendCodes.filter(x => x.extra.isClinical) : pendCodes.filter(x => !x.extra.isClinical || x.value === PendType.PC140);
    if (this.isClinical && !this.hasClinicalNonClinicalChases) {
      const autoClosePendCodes = autoClosePends.filter(x => x.extra.pendTypeCategoryId !== 9);
      const autoCloseCustomPendCodes = autoClosePends.filter(x => x.extra.pendTypeCategoryId === 9 && x.extra.isClinical);
      autoClosePends = autoClosePendCodes.concat(autoCloseCustomPendCodes);
      const clinicalPendCodes = [];
      pendCodes.forEach(x => {
        const pendText = x.text.split("-");
        if (ArrayHelper.isAvailable(pendText)) {
          clinicalPendCodes.push(x.text);
        }
      });
      pendCodes = pendCodes.filter(pendType => clinicalPendCodes.includes(pendType.text)).concat(autoClosePends);
    } else if (this.hasClinicalNonClinicalChases) {
      pendCodes = this.originalPendCodeOptions;
    }

    pendCodes = pendCodes.filter((v, i) => pendCodes.findIndex(item => item.value === v.value) === i);
    pendCodes = pendCodes.sort((a, b) => Number(a.text.split("-")[0].substring(2)) - Number(b.text.split("-")[0].substring(2)));

    return pendCodes;
  }

  get isFinalBulkUpdate(): boolean {
    if (this.basicGridComponent && ArrayHelper.isAvailable(this.basicGridComponent.data)) {
      return this.bulkUpdatesService.checkValidationStatus(this.basicGridComponent.data);
    }
  }


  get pendCodeToMatch(): string[] {
    const pendCodes: string[] = [];
    switch (this.clinicalType) {
      case "Abstraction":
        pendCodes.push("PC302", "PC303");
        break;
      case "Overread":
        pendCodes.push("PC301", "PC303");
        break;
      case "Overread2":
        pendCodes.push("PC300", "PC303");
        break;
      default:
        break;
    }
    return pendCodes;
  }

  get selectedStatus(): number {
    return Number(this.form.get(this.statusInput.key).value);
  }

  get selectedUsers(): number {
    return Number(this.form.get(this.userInput.key).value);
  }
  gridRefresh = new EventEmitter<null>();

  bulkUpdateTypes: SelectItem[];

  @Input() actionControls: string[] = [];
  clientOrgs: SelectItem[];
  statuses: SelectableInput[];
  users: SelectableInput[];
  formGroup: FormGroup;
  chasesNotes: Textarea;
  chasesOwner: Radiobutton;
  bulkUpdate: BulkUpdate;
  chaseIdsList: string[];
  user: UserToken;
  selectedPendType: number;
  selectedOwner: string;
  data: any;
  serverGridConfiguration = new GridConfiguration();
  serverRequest = new GridRequest();
  serverGridSelection: any;

  isShowPendCodeError = false;
  isShowPendCodes = false;
  isShowStatusType = false;
  isShowAssignUser = false;
  isShowOwner = false;

  // For Validation
  isShowClientError = false;
  isShowStatusError = false;

  isShowPendCode = false;
  isShowOwners = false;

  isShowAssignUsers = false;
  isShowOwnersError = false;

  checkError = false;

  isShowValidateGrid = false;
  @Input() isShowChaseControls = true;
  bulkUpdateTypeItem = "bulkUpdateType";
  bulkUpdateActionItem = "bulkUpdateActions";
  chaseIds = "chaseIds";
  clientOrgsId = "clientId";
  projectId = "projectId";
  selectedRequestedDate = "selectedRequestedDate";
  selectedDueDate = "selectedDueDate";
  selectedCommitmentDate = "selectCommitmentDate";
  originalChaseId = "originalChaseId";
  copyOverChaseId = "copyOverChaseId";
  @Output() isShowContinueButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isShowControls: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isShowEditBulk: EventEmitter<boolean> = new EventEmitter<boolean>();
  private modelErrors: any[] = [];
  private newLine = "\r";
  notesErrorMessages = "";

  pendCodesInput: Dropdown;
  invoiceNumberInput: Textbox;
  invoiceAmountInput: Textbox;
  pendCompanyInput: Dropdown;
  pendSeverityInput: Dropdown;
  form: FormGroup;
  statusInput: Dropdown;
  userInput: Dropdown;
  isShowPendCodeServerity = true;
  originalPendCodeOptions: SelectableInput[] = [];
  private pIsClinical = "";

  private pClinicalType = "";

  private pMixChases = "";

  @Input() isMoveChase = false;
  @Input() isChaseAssignUser = false;
  @ViewChild(BasicGridComponent) private basicGridComponent: BasicGridComponent;

  pendReasonInput: Dropdown;
  isPendReasonVisible = false;
  pendReasons: SelectableInput[];
  @Output() refreshEvent = new EventEmitter<boolean>();
  @Input() refreshFlag: boolean;
  ngOnChanges(changes: SimpleChanges) {
    this.isShowStatusType = this.isMoveChase;
    if (this.isMoveChase) {
      this.getWorkflowStatuses();
    }
    if (this.isChaseAssignUser) {
      this.isShowAssignUser = this.isChaseAssignUser;
      this.getUsers();
    }
    if (changes.refreshFlag) {
      this.refreshEvent.emit(true);
    }
  }

  ngOnInit() {
    this.user = this.authService.user;
    this.isShowChaseControls = true;
    this.initializeControls();
    if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.MoveChases) {
      this.getWorkflowStatuses();
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.ReassignChases) {
      this.getUsers();
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.ActivateChases) {
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.DueDate3rdPartyChases) {
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.RequestDate3rdPartyChases) {
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.PendChases) {
      this.isShowPendCode = true;
      this.getPendControls();
      this.getPends();
      this.getPendCompanyDropdownList();
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([
          this.pendCodesInput,
          this.invoiceNumberInput,
          this.invoiceAmountInput,
          this.pendCompanyInput,
          this.chasesNotes,
          this.pendReasonInput,
          this.pendSeverityInput,
        ]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.CommitmentDateUpdateChases) {
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.CopyChartToChase) {
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    }

  }

  getNotes(): void {
    this.chasesNotes = new Textarea({
      key: "chasesNotes",
      label: "Note",
      placeholder: "Type notes here...",
      rows: 6,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000),
      ],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters. ",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });

    this.modelErrors = [
      { key: "chasesNotes", message: "Please enter notes. Minimum 4 characters and Maximum 1000 characters allowed" },
    ];
  }

  getWorkflowStatuses(): void {
    this.service
      .getWorkflowStatusesList()
      .subscribe(result => {
        this.statuses = result.map(item => ({ text: item.workflowStatusName, value: item.workflowStatusId, disabled: false, extra: [], label: item.workflowStatusName }));
        this.statuses.splice(this.statuses.findIndex(opt => opt.text === "Dataload"), 1);
        this.statusInput = new Dropdown({ ...this.statusInput, options: this.statuses } as any);
        this.changeDetector.markForCheck();
      });
  }

  getUsers(): void {
    const userModal = new BulkChasesAssignmentRequest({
      chaseIds: this.actionControls[this.chaseIds].split(","),
      projectId: this.actionControls[this.projectId] !== null ? this.actionControls[this.projectId] : null,
    });
    this.service
      .getUsersList(userModal)
      .subscribe(result => {
        this.users = result.map(item => ({ text: `${item.firstName} ${item.lastName}`, value: item.userId.toString(), disabled: false, extra: [], label: `${item.firstName} ${item.lastName}` }));
        this.userInput = new Dropdown({ ...this.userInput, options: this.users } as any);
        this.changeDetector.markForCheck();
      });
  }

  getPends() {
    this.createPendService.getPendDropdown(null, null)
      .pipe(map((pendDropdowns: any) => {
        const pendCodes = pendDropdowns.pendCodes.filter(code => !code.isThirdParty).map(this.automapper.curry("PendCode", "SelectableInput"));
        const severity = pendDropdowns.pendSeverity.map(this.automapper.curry("PendSeverity", "SelectableInput"));
        return { pendCodes, severity };
      }))
      .subscribe(({ pendCodes, severity }: any) => {
        this.originalPendCodeOptions = pendCodes;
        this.setPendCodesInputOptions();
        this.pendSeverityInput = new Dropdown({ ...this.pendSeverityInput, options: severity } as any);
        this.changeDetector.markForCheck();
      });
  }

  getValidatedMoveReassignChases(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    if (!this.bulkUpdatesComponent.hasValidationError && !this.bulkUpdatesComponent.formGroup.invalid) {
      this.isShowValidateGrid = true;
    }
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ProjectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
    ];

    if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.MoveChases) {
      filters.push(new GridFilter({
        input: new Textbox(),
        key: "Status",
        value: this.selectedStatus.toString(),
        show: false,
      }));
      this.serverGridConfiguration.columns = [
        new GridColumnDefinition({ field: "chaseId", header: "Chase" }),
        new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
        new GridColumnDefinition({ field: "currentWorkflowStatusName", header: "Current Workflow Status" }),
        new GridColumnDefinition({ field: "newWorkflowStatusName", header: "New Workflow Status" }),
        new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
        new GridColumnDefinition({ field: "message", header: "Comments" }),
      ];
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.ReassignChases) {
      filters.push(new GridFilter({
        input: new Textbox(),
        key: "AssignedToUserId",
        value: this.selectedUsers.toString(),
        show: false,
      }));
      this.serverGridConfiguration.columns = [
        new GridColumnDefinition({ field: "chaseId", header: "Chase" }),
        new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
        new GridColumnDefinition({ field: "currentAssignedUser", header: "Current Assigned To User" }),
        new GridColumnDefinition({ field: "newAssignedUser", header: "New Assigned To User" }),
        new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
        new GridColumnDefinition({ field: "message", header: "Comments" }),
      ];
    }

    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;

    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/chases/movereassign";
    this.getBulkValidationData(url);

  }

  getBulkValidationData(url: string) {
    this.serverRequest.setForm();
    const request = this.serverRequest.createRequestObject();
    this.service.getBulkValidationData(url, request).subscribe(res => {
      this.data = res;
      this.changeDetector.markForCheck();
    });
  }

  getValidatedActivateChases(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    if (!this.bulkUpdatesComponent.hasValidationError && !this.bulkUpdatesComponent.formGroup.invalid) {
      this.isShowValidateGrid = true;
    }
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "projectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "action",
        value: "0",
        show: false,
      }),
    ];
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseId", header: "Chase" }),
      new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
      new GridColumnDefinition({ field: "isActive", header: "Active(Yes/No)" }),
      new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
      new GridColumnDefinition({ field: "message", header: "Comments" }),
    ];

    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/chases/activate";
    this.getBulkValidationData(url);
  }

  getValidatedPendChases(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    this.isShowValidateGrid = true;
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseID", header: "Chase" }),
      new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
      new GridColumnDefinition({ field: "owner", header: "Owner" }),
      new GridColumnDefinition({ field: "validationStatus", header: "ValidationStatus" }),
      new GridColumnDefinition({ field: "message", header: "Message" }),
    ];
    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;

    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Owner",
        value: null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "PendType",
        value: this.formGroup.get("pendTypeId").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "invoiceNumber",
        value: this.formGroup.get("invoiceNumber").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "amount",
        value: this.formGroup.get("amount").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "pendCompanyId",
        value: this.formGroup.get("pendCompanyId").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "pendReasonId",
        value: this.formGroup.get("pendReasonId").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "pendSeverityId",
        value: this.formGroup.get("pendSeverityId").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "projectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
    ];
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/pendchases";
    this.getBulkValidationData(url);
  }


  getValidatedDueDateForThirdPartyChase(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    this.isShowValidateGrid = true;
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "DueDate",
        value: this.actionControls[this.selectedDueDate],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "projectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "action",
        value: "0",
        show: false,
      }),
    ];
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseId", header: "Chase" }),
      new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
      new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
      new GridColumnDefinition({ field: "message", header: "Comments" }),
    ];

    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/chases/thirdPartyChase";
    this.getBulkValidationData(url);
  }


  getValidatedRequestedDateForThirdPartyChase(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    this.isShowValidateGrid = true;
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "RequestedDate",
        value: this.actionControls[this.selectedRequestedDate],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "projectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "action",
        value: "0",
        show: false,
      }),
    ];
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseId", header: "Chase" }),
      new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
      new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
      new GridColumnDefinition({ field: "message", header: "Comments" }),
    ];

    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/chases/thirdPartyChase";
    this.getBulkValidationData(url);
  }

  getValidatedCommitmentDateForUpdateChase(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    this.isShowValidateGrid = true;
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "commitmentDate",
        value: this.actionControls[this.selectedCommitmentDate],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "projectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "action",
        value: "0",
        show: false,
      }),
    ];
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseId", header: "Chase" }),
      new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Chase Key" }),
      new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
      new GridColumnDefinition({ field: "message", header: "Comments" }),
    ];
    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/chases/thirdPartyChase";
    this.getBulkValidationData(url);
  }

  getValidatedCopyOverChartChases(): void {
    this.isShowControls.emit(true);
    this.isShowEditBulk.emit(true);
    this.isShowValidateGrid = true;
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "originalChaseId", header: "Original Chase" }),
      new GridColumnDefinition({ field: "copyOverChaseId", header: "Copy Over Chase" }),
      new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
      new GridColumnDefinition({ field: "message", header: "Message" }),
    ];
    const filters = [
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "OriginalChaseIdCsv",
        value: this.actionControls[this.originalChaseId],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "CopyOverChaseIdCsv",
        value: this.actionControls[this.copyOverChaseId],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "projectId",
        value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "action",
        value: "0",
        show: false,
      }),
    ];
    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/chase/copyoverchartchase";
    this.getBulkValidationData(url);
  }

  refreshGrid(): void {
    this.gridRefresh.emit();
  }

  validateNotes() {
    const notes = this.formGroup.value?.chasesNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.chasesNotes.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.formGroup.get(this.chasesNotes.key).setErrors(null);
    }
  }

  validate() {
    this.bulkUpdatesComponent.validateContinue();
    this.validateNotes();
    this.isShowControls.emit(true);

    this.checkError = false;

    if (this.isShowStatusType !== false) {
      if (this.selectedStatus === undefined || this.selectedStatus === 0) {
        this.isShowStatusError = true;
        this.checkError = true;
      }
    }

    if (this.isShowAssignUser !== false) {
      if (this.selectedUsers === undefined || this.selectedUsers === 0) {
        this.isShowAssignUsers = true;
        this.checkError = true;
      }
    }

    if (this.formGroup.invalid) {
      this.formService.markAllAsTouched(this.formGroup);
      this.notesErrorMessages = "";
      this.DisplayErrors();
      this.checkError = true;
      return;
    }

    if (this.checkError) {
      return;
    }
    this.isShowControls.emit(false);
    if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.MoveChases) {
      this.getValidatedMoveReassignChases();
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.ReassignChases) {
      this.getValidatedMoveReassignChases();
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.ActivateChases) {
      this.getValidatedActivateChases();
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.DueDate3rdPartyChases) {
      this.getValidatedDueDateForThirdPartyChase();
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.RequestDate3rdPartyChases) {
      this.getValidatedRequestedDateForThirdPartyChase();
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.CommitmentDateUpdateChases) {
      this.getValidatedCommitmentDateForUpdateChase();

    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.CopyChartToChase) {
      this.getValidatedCopyOverChartChases();
    } else {
      this.getValidatedPendChases();
    }
    if (
      this.actionControls[this.bulkUpdateActionItem] === BulkAction.MoveChases
      || this.actionControls[this.bulkUpdateActionItem] === BulkAction.ActivateChases
    ) {
      this.isShowEditBulk.emit(false);
      this.isShowContinueButton.emit(false);
    } else {
      this.isShowChaseControls = false;
      this.isShowContinueButton.emit(true);
    }
  }

  finalBulkUpdate() {
    const chaseIds = this.actionControls[this.chaseIds];
    if (chaseIds != null) {
      this.chaseIdsList = this.actionControls[this.chaseIds].split(",");
    }
    this.bulkUpdate = new BulkUpdate({
      clientId: this.actionControls[this.clientOrgsId],
      chaseIds: this.chaseIdsList,
      originalChaseIdCsv: this.actionControls[this.originalChaseId],
      copyOverChaseIdCsv: this.actionControls[this.copyOverChaseId],
      status: String(this.selectedStatus),
      assignedToUserId: this.selectedUsers,
      notes: `${this.formGroup.get("chasesNotes").value} via Bulk Action`,
      bulkType: this.actionControls[this.bulkUpdateTypeItem],
      bulkAction: this.actionControls[this.bulkUpdateActionItem],
      owner: null,
      action: 1,
      pendType: this.formGroup.get("pendTypeId") ? this.formGroup.get("pendTypeId").value.toString() : null,
      amount: this.formGroup.get("amount") ? this.formGroup.get("amount").value : null,
      invoiceNumber: this.formGroup.get("invoiceNumber") ? this.formGroup.get("invoiceNumber").value : null,
      pendCompanyId: this.formGroup.get("pendCompanyId") ? this.formGroup.get("pendCompanyId").value : null,
      pendReasonId: this.formGroup.get("pendReasonId") ? this.formGroup.get("pendReasonId").value : null,
      pendSeverityId: this.formGroup.get("pendSeverityId") ? this.formGroup.get("pendSeverityId").value : null,
      projectId: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
      dueDate: this.actionControls[this.bulkUpdateActionItem] === BulkAction.DueDate3rdPartyChases ? this.actionControls[this.selectedDueDate] : null,
      requestedDate: this.actionControls[this.bulkUpdateActionItem] === BulkAction.RequestDate3rdPartyChases ? this.actionControls[this.selectedRequestedDate] : null,
      commitmentDate: this.actionControls[this.bulkUpdateActionItem] === BulkAction.CommitmentDateUpdateChases ? this.actionControls[this.selectedCommitmentDate] : null,
    });
    if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.MoveChases || this.actionControls[this.bulkUpdateActionItem] === BulkAction.ReassignChases) {
      this.service.finishBulkUpdate(this.bulkUpdate).subscribe(data => {
        if (data > 0) {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        } else {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        }
      });
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.ActivateChases) {
      this.service.finishActivateBulkUpdate(this.bulkUpdate).subscribe(data => {
        if (data > 0) {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        } else {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        }
      });
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.DueDate3rdPartyChases || this.actionControls[this.bulkUpdateActionItem] === BulkAction.RequestDate3rdPartyChases) {
      this.service.finishDueOrRequestedOrCommitmentDateBulkUpdate(this.bulkUpdate).subscribe({
        next: () => {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        },
        error: () => {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        },
      });
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.CommitmentDateUpdateChases) {
      this.service.finishDueOrRequestedOrCommitmentDateBulkUpdate(this.bulkUpdate).subscribe({
        next: () => {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        },
        error: () => {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        },
      });
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.CopyChartToChase) {
      this.service.finishCopyOverChartBulkUpdate(this.bulkUpdate).subscribe(data => {
        if (data > 0) {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        } else {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        }
      });
    } else {
      this.service.finishBulkUpdateChases(this.bulkUpdate).subscribe(data => {
        if (data > 0) {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        } else {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        }
      });
    }
  }

  onSelectType(event) {
    this.isShowAssignUser = true;
    this.isShowStatusType = true;
    this.getNotes();
    this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
  }

  onBulkActionChange(event) {
    this.isShowPendCodes = false;
    this.isShowStatusType = false;
    this.isShowAssignUser = false;
    this.isShowOwner = false;

    if (event === BulkAction.MoveChases) {
      this.isShowStatusType = true;
      this.getNotes();
      this.isShowAssignUser = true;
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);

    }

    if (event === BulkAction.ActivateChases) {
      this.isShowPendCodes = true;
      this.isShowAssignUser = true;
      this.isShowOwner = true;
      this.getNotes();
      this.formGroup = this.formService.createFormGroup([this.chasesNotes, this.chasesOwner]);
    }
  }

  onSelectedUsers(event) {
    if (event) {
      this.isShowAssignUsers = false;
      this.form.get(this.userInput.key).setValue(event.value);
    } else {
      this.isShowAssignUsers = true;
    }
  }

  onSelectedStatus(event) {

    if (event) {
      this.isShowStatusError = false;
      this.form.get(this.statusInput.key).setValue(event.value);
    } else {
      this.isShowStatusError = true;
    }
  }

  private DisplayErrors() {
    for (const key in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(key)) {
        const control = this.formGroup.controls[key];
        if (control.invalid) {
          const modelError = this.modelErrors.filter(obj => {
            return obj.key === key;
          });

          if (modelError) {
            this.notesErrorMessages += (modelError[0] != null) ? modelError[0].message + this.newLine : "";
          }
        }
      }
    }
  }

  getPendControls() {
    this.pendCodesInput = new Dropdown({
      key: "pendTypeId",
      label: "Pend Code",
      placeholder: "Select...",
      validators: [Validators.required],
      errorMessages: {
        required: "Pend Code is required.",
      },
    });
    this.invoiceNumberInput = new Textbox({
      key: "invoiceNumber",
      label: "Invoice Number",
      validators: [
        Validators.maxLength(10),
      ],
      errorMessages: {
        maxlength: "Invoice number can not be more than 10 characters.",
      },
      hidden: true,
    });
    this.invoiceAmountInput = new Textbox({
      key: "amount",
      label: "Invoice Amount",
      type: TextboxType.NUMBER,
      validators: [
        Validators.max(10000),
        Validators.pattern(RegExHelper.invoiceAmount),
      ],
      errorMessages: {
        max: "Enter number between 0 - 10000.",
        pattern: "Enter a dollar amount between 0 - 10000.",
      },
      placeholder: "0.00",
      hidden: true,
    });
    this.pendCompanyInput = new Dropdown({
      key: "pendCompanyId",
      label: "Select Company",
      placeholder: "Select...",
      hidden: true,
    });
    this.pendReasonInput = new Dropdown({
      key: "pendReasonId",
      label: "Reason",
      placeholder: "Select from List",
    });
    this.pendSeverityInput = new Dropdown({
      key: "pendSeverityId",
      label: "Severity",
      placeholder: "Select...",
      hidden: true,
      errorMessages: {
        required: "Severity is required.",
      },
    });
  }

  protected getPendCompanyDropdownList(): void {
    this.createPendService.getPendCompaniesDropdown()
      .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(data => {
        this.pendCompanyInput = new Dropdown({ ...this.pendCompanyInput, options: data } as any);
      });
  }

  setPendCodeInvoiceControls(): void {
    if (this.selectedClinicalPendCode) {
      if (this.formGroup.get("pendTypeId").value.toString() === PendType.PC304.toString() || this.formGroup.get("pendTypeId").value.toString() === PendType.PC301.toString() || this.formGroup.get("pendTypeId").value.toString() === PendType.PC140.toString()) {
        this.isShowPendCodeServerity = false;
        this.formGroup.get(this.pendSeverityInput.key).clearValidators();
        this.formGroup.get(this.pendSeverityInput.key).reset();
      } else {
        this.isShowPendCodeServerity = true;
        this.formGroup.get(this.pendSeverityInput.key).setValidators([Validators.required]);
      }
      this.pendSeverityInput = new Dropdown({ ...this.pendSeverityInput, hidden: false } as any);
    } else if (this.selectedThirdPartyPendCode) {
      this.pendCompanyInput = new Dropdown({ ...this.pendCompanyInput, hidden: false } as any);

      this.invoiceAmountInput = new Textbox({ ...this.invoiceAmountInput, hidden: false } as any);

      this.invoiceNumberInput = new Textbox({ ...this.invoiceNumberInput, hidden: false } as any);
    } else {
      this.getPendReasonDropdown();
      this.formGroup.get(this.pendCompanyInput.key).setValue(null);
      this.pendCompanyInput = new Dropdown({ ...this.pendCompanyInput, hidden: true } as any);

      this.formGroup.get(this.invoiceAmountInput.key).setValue(null);
      this.invoiceAmountInput = new Textbox({ ...this.invoiceAmountInput, hidden: true } as any);

      this.formGroup.get(this.invoiceNumberInput.key).setValue(null);
      this.invoiceNumberInput = new Textbox({ ...this.invoiceNumberInput, hidden: true } as any);

      this.formGroup.get(this.pendSeverityInput.key).setValue(null);
      this.formGroup.get(this.pendSeverityInput.key).clearValidators();
      this.pendSeverityInput = new Dropdown({ ...this.pendSeverityInput, hidden: true } as any);
    }

    this.changeDetector.markForCheck();
  }


  getPendReasonDropdown() {
    const pendCodeValue = this.formGroup.get("pendTypeId")?.value;
    if (pendCodeValue === PendType.PC100 || pendCodeValue === PendType.PC107) {
      this.isPendReasonVisible = true;
      this.createPendService.getPendReasons(pendCodeValue)
      .subscribe(data => {
        this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, options: [...data] } as any);
        this.changeDetector.markForCheck();
      });
    } else {
      this.isPendReasonVisible = false;
    }
  }

  isShowNotesError(key: string) {
    return !this.formGroup.get(key).valid && this.formGroup.get(key).touched;
  }

  private setPendCodesInputOptions(): void {
    this.pendCodesInput = new Dropdown({ ...this.pendCodesInput, options: this.filteredPendCodeOptions } as any);
  }

  initializeControls(): void {
    this.statusInput = new Dropdown({
      key: "status",
      label: "Select a Status",
      placeholder: "Select Status",
    });

    this.userInput = new Dropdown({
      key: "user",
      label: "Assign to User",
      placeholder: "Select User",
    });
    this.form = this.formService.createFormGroup([this.statusInput, this.userInput]);
  }

}
