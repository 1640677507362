import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-dob-discrepancy-dw",
  templateUrl: "./dob-discrepancy-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DobDiscrepancyDWComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.DOB_DISCREPANCY_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID2, HideFilters.ORG_ID];
}
