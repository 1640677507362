import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { Observable, Subject, Subscription, of } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { AuthService } from "../../../auth/auth.service";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ChasePendItem } from "../chase-pend-item.model";
import { CreateEditPendDirective } from "../create-edit-pend-component.model";
import { CreatePendService } from "../create-pend.service";
import { EditPendService } from "../edit-pend.service";
import { PendStatus } from "../pend-status.enum";
import { PendType } from "../pend-type.enum";

@Component({
  selector: "app-edit-pend-modal",
  templateUrl: "./edit-pend-modal.component.html",
  styleUrls: ["./edit-pend-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPendModalComponent extends CreateEditPendDirective implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  private getUsersSubscription: Subscription;
  private getPendReasonsSubscription: Subscription;
  @Input() chasePendIds: number[];
  @Input() chaseIds: number[]; // TODO: Is this necessary? Do we want to create multiple alerts for multiple chases?

  private pChases = [];
  @Input()
  get chases(): any[] {
    return this.pChases;
  }
  set chases(value: any[]) {
    this.pChases = value;
  }

  inputs: DynamicInput[];
  isCancelConfirmationModalVisible = false;
  isResolveConfirmationModalVisible = false;
  isThirdParty: boolean;
  isActive = false;
  pendType = null;


  constructor(
    protected readonly formService: FormService,
    protected readonly automapper: AutomapperService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly messagingService: MessagingService,
    protected readonly createPendService: CreatePendService,
    protected readonly editPendService: EditPendService,
    protected readonly authService: AuthService,
    private chaseDetailStateService: ChaseDetailStateService
  ) {
    super(formService, automapper, changeDetector, messagingService, createPendService, authService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.onShow
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.resetForm();

        if (this.hasChasePendId) {
          this.getPendCode();
          if (this.pChases.length === 1) {

            this.isThirdParty = this.pChases[0].isThirdParty;
          } else if (this.pChases.length > 1) {

            this.isThirdParty = this.pChases.map(chaseItem => chaseItem.isThirdParty).includes(false) ? false : true;
            this.checkIfInvoicePendCode();
          }
          this.getData();

        }

        this.pendCodesInput = new Dropdown({ ...this.pendCodesInput, disabled: true, hidden: !this.hasChasePendId } as any);
        this.statusInput = new Dropdown({ ...this.statusInput, disabled: false, value: null } as any);
        this.ownerInput = new Dropdown({ ...this.ownerInput, value: null } as any);
        this.assignedToInput = new Dropdown({ ...this.assignedToInput, disabled: false, hidden: false } as any);
        this.setInputs();
        this.createForm();

        this.subscribeToGetUsers();
        this.subscribeToGetPendReasons();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get hasChasePendId(): boolean {
    return ArrayHelper.isAvailable(this.chasePendIds);
  }

  get chasePendIdsText(): string {
    if (!ArrayHelper.isAvailable(this.chasePendIds)) {
      return "";
    }

    return this.chasePendIds.join(", ");
  }

  checkIfInvoicePendCode(): void {
    if (this.hasChasePendId) {
      if (this.isThirdParty) {
        this.form.get(this.companyInput.key).enable();
        this.companyInput.disabled = false;
        this.companyInput.validators = null;
        this.companyInput = new Dropdown({ ...this.companyInput, hidden: false } as any);

        this.form.get(this.amountInput.key).enable();
        this.amountInput.disabled = false;
        this.amountInput = new Textbox({ ...this.amountInput, hidden: false } as any);

        this.form.get(this.invoiceInput.key).enable();
        this.invoiceInput.disabled = false;
        this.invoiceInput = new Textbox({ ...this.invoiceInput, hidden: false } as any);
      } else {
        this.form.get(this.companyInput.key).disable();
        this.companyInput = new Dropdown({ ...this.companyInput, hidden: true } as any);

        this.form.get(this.amountInput.key).disable();
        this.amountInput = new Textbox({ ...this.amountInput, hidden: true } as any);

        this.form.get(this.invoiceInput.key).disable();
        this.invoiceInput = new Textbox({ ...this.invoiceInput, hidden: true } as any);
      }
      this.setInputs();
      this.changeDetector.markForCheck();
    }
  }

  save(): void {
    const notes = this.form.value?.notes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.form.get(this.noteInput.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.form.get(this.noteInput.key).setErrors(null);
    }
    if (this.form.valid) {
      switch (this.form.get(this.statusInput.key).value) {
        case PendStatus.Closed:
          this.isCancelConfirmationModalVisible = true;
          break;
        case PendStatus.Resolved:
          this.isResolveConfirmationModalVisible = true;
          break;
        default:
          this.savePend();
          break;
      }
    } else {
      this.markAllAsTouched();
    }
    this.changeDetector.markForCheck();
  }


  private getData(): void {
    const chasePendId = this.chasePendIds[0];
    this.editPendService.getChasePendData(chasePendId).subscribe(result => {
      this.form.get(this.pendCodesInput.key).setValue(result.pendTypeId);
      this.form.get(this.pendReasonInput.key).setValue(result.pendReasonId);
      this.form.get(this.statusInput.key).setValue(result.pendStatusId);
      const ownerValue = this.getOwnerValue(result.owner);
      this.form.get(this.ownerInput.key).setValue(ownerValue);
      this.form.get(this.companyInput.key).setValue(result.pendCompanyId);
      this.form.get(this.amountInput.key).setValue(result.amount);
      this.form.get(this.invoiceInput.key).setValue(result.invoiceNumber);
      this.form.get(this.assignedToInput.key).setValue(result.assignedToUserId);
      this.form.get(this.noteInput.key).setValue(result.notes);
      this.form.get(this.severityInput.key).setValue(result.pendSeverityId);
      this.checkIfInvoicePendCode();
      this.setControlsVisibiltyForPC304();
      this.setInputs();
    });
  }

  private subscribeToGetUsers(): void {
    if (this.getUsersSubscription != null) {
      this.getUsersSubscription.unsubscribe();
    }

    this.getUsersSubscription = this.form.get(this.ownerInput.key).valueChanges
      .pipe(
        debounceTime(300),
        switchMap(() => this.getUsers())
      )
      .subscribe((data: any[]): void => {
        this.assignedToInput = new Dropdown({ ...this.assignedToInput, options: data } as any);
        this.setInputs();
      });
  }

  private getUsers(): Observable<SelectableInput[]> {
    const ownerValue = this.form.get(this.ownerInput.key).value;
    const firstChasePendId = this.chasePendIds[0];

    if (!StringHelper.isAvailable(ownerValue)) {
      return of([]);
    } else {
      const roleType = this.clinical ? "C" : "N";
      return this.editPendService.getAssignedToUsersList(ownerValue, firstChasePendId, roleType)
        .pipe(map(this.automapper.curryMany("PendUser", "SelectableInput")));
    }
  }

  setInputs(): void {
    this.inputs = [
      this.statusInput,
      this.ownerInput,
      this.companyInput,
      this.amountInput,
      this.invoiceInput,
      this.pendReasonInput,
      this.assignedToInput,
      this.severityInput,
    ];
    this.formService.updateDom.next();
  }

  private getOwnerValue(owner: string): string {
    // TODO: the backend should send the same value we save.
    switch (owner) {
      case "Client":
        return "C";
      case "Organization":
        return "O";
      default:
        return null;
    }
  }

  savePend(): void {
    const chasePendItem = new ChasePendItem({
      ...this.form.value,
      chaseIds: this.chaseIds,
      chasePendIds: this.chasePendIds,
    });
    this.editPendService.updatePend(chasePendItem).subscribe(data => {
      if (data > 0) {
        this.onUpdate.emit();
        this.chaseDetailStateService.setData({
          pendStatusId: this.form.get(this.statusInput.key).value,
        });
        this.messagingService.showToast("Pend saved successfully", SeverityType.SUCCESS);
        this.close();
        this.changeDetector.detectChanges();
      } else {
        this.messagingService.showToast("Pend saved failed", SeverityType.ERROR);
      }
    });
    this.isCancelConfirmationModalVisible = false;
    this.isResolveConfirmationModalVisible = false;
  }

  cancelConfirmation($event) {
    this.isCancelConfirmationModalVisible = false;
    this.isResolveConfirmationModalVisible = false;
  }

  private subscribeToGetPendReasons(): void {
    if (this.getPendReasonsSubscription != null) {
      this.getPendReasonsSubscription.unsubscribe();
    }

    this.getPendReasonsSubscription = this.form.get(this.pendCodesInput.key).valueChanges
      .pipe(
        debounceTime(300),
        switchMap(() => this.getPendReasons())
      )
      .subscribe((data: any[]): void => {
        data.forEach(x => x.value = Number(x.value));
        this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, options: data } as any);
        this.setInputs();
      });
  }

  private getPendReasons(): Observable<SelectableInput[]> {
    const pendCodeValue = this.form.get(this.pendCodesInput.key).value;

    if (pendCodeValue === PendType.PC100 || pendCodeValue === PendType.PC107) {
      this.form.get(this.pendReasonInput.key).enable();
      this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: false } as any);
      this.form.get(this.noteInput.key).setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(1000)]);

      return this.createPendService.getPendReasons(pendCodeValue);
    } else {
      this.form.get(this.pendReasonInput.key).disable();
      this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: true } as any);
      this.form.get(this.noteInput.key).setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(1000)]);

      return of([]);
    }
  }

  getPendCode(): void {
    this.createPendService
      .getAllPendDropdown(this.pendType, this.isActive)
      .subscribe(result => {
        const pendCode = result.pendCodes.map(this.automapper.curry("PendCode", "SelectableInput"));
        this.pendCodesInput = new Dropdown({ ...this.pendCodesInput, options: pendCode } as any);
        this.formService.updateDom.next();
      });
  }

  private setControlsVisibiltyForPC304(): void {
    const pend = this.pendCodesInput.getSelectedOption(this.form);
    this.severityInput = new Dropdown({ ...this.severityInput, hidden: false } as any);
    this.ownerInput = new Dropdown({ ...this.ownerInput, hidden: false } as any);

    if (this.form.get(this.pendCodesInput.key).value === PendType.PC140) {
      this.severityInput.hidden = !this.clinical;
    } else if (this.form.get(this.pendCodesInput.key).value === PendType.PC304 || this.form.get(this.pendCodesInput.key).value === PendType.PC301) {
      this.severityInput.hidden = true;
      this.form.get(this.severityInput.key).setValue(this.severityInput.options[2].value);
      this.ownerInput = new Dropdown({ ...this.ownerInput, hidden: true } as any);
    } else if (!pend.extra.isClinical) {
      this.severityInput.hidden = true;
      if (this.form.get(this.pendCodesInput.key).value === PendType.PC700) {
        this.form.get(this.severityInput.key).setValue(this.severityInput.options[2].value);
      }
    } else {
      this.severityInput.hidden = false;
      this.ownerInput.hidden = false;
    }
  }
}
