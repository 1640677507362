import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AutomapperService } from "../core/automapper/automapper.service";
import { BASE_API_URL } from "../core/environment.tokens";
import { GainSightPxService } from "../core/meta/gainsightPx.service";
import { LocalService } from "../core/storage/local.service";
import { SessionService } from "../core/storage/session.service";
import { StorageValueHelper } from "../core/storage/storage-value-helper.model";
import { SingleSignInService } from "../platform/header/account-menu/single-sign-in/single-sign-in.service";
import { ListItem } from "../shared/list/list-item";
import { StringHelper } from "../utilities/contracts/string-helper";
import { UserToken } from "./user-token.model";

// TODO: Put in a service before using somewhere else.
// TODO: This doesn't work? Testing for now...
if (environment.production) {
    (window as any).newrelic = (window as any).newrelic || {};
}

@Injectable({
    providedIn: "root",
})
export class AuthService {
    readonly AUTHENTICATION_KEY = "authentication";
    readonly tempAuthKey = "temp_auth";
    redirectUrl = "";
    isUserChanged = false;
    failedLogInAttemptCount = 0;
    readonly defaultUserLandingUrl = "/user/loginLanding";
    readonly activeUserSession = "active_session";
    private readonly localStorageKeysToClear: string[] = [
      "active_session",
      "refresh",
      "ng2Idle.main.expiry",
      "ng2Idle.main.idling",
  ];

    private readonly sessionStorageKeysToClear: string[] = [
      "authentication",
      "user_menus",
  ];
    constructor(
        @Inject(BASE_API_URL) private readonly baseApiUrl: string,
        private router: Router,
        private automapper: AutomapperService,
        private readonly http: HttpClient,
        private readonly session: SessionService,
        private readonly local: LocalService,
        private readonly gainSightPxService: GainSightPxService,
        private singleSignInService: SingleSignInService,
        private zone: NgZone
    ) { }

    get user(): UserToken {
        const user = this.session.get(this.AUTHENTICATION_KEY, {});
        return new UserToken(user);
    }

    get isAuthenticated(): boolean {
        return this.user.isAuthenticated;
    }

    get isEmployee(): boolean {
        return this.user.isEmployeeRole && !this.user.isManagerRole && !this.user.isLeadRole;
    }
    get username(): string {
        return this.user.username;
    }
    get sessionId(): string {
      return this.user.sessionId;
  }
    get userId(): number {
        return this.user.userId;
    }

    get tempUserTokenForMfa(): UserToken {
        const user = this.session.get(this.tempAuthKey, {});
        return new UserToken(user);
    }

    get userEmailAddress(): string {
        return this.user.emailAddress;
    }

    get userOrganizationName(): string {
        return this.user.organizationName;
    }

    get userFirstName(): string {
        return this.user.firstName;
    }

    get userLastName(): string {
        return this.user.lastName;
    }

    get routerSnapshotUrl(): string {
      return this.router.routerState.snapshot.url;
    }

    static getClientId(): string {
        if (StringHelper.isAvailable(window.sessionStorage.getItem("ssoClientId"))) {
            return StorageValueHelper.get(window.sessionStorage.getItem("ssoClientId")).toString();
        }
        return "";
    }

    static getDirectoryIdUrl(): string {
        if (StringHelper.isAvailable(window.sessionStorage.getItem("ssoDirectoryIdUrl"))) {
            return StorageValueHelper.get(window.sessionStorage.getItem("ssoDirectoryIdUrl")).toString();
        }
        return "";
    }

    login(username: string, password: string): void {
        this.sendLoginRequest(username, password).subscribe(
            this.loginSuccess
        );
    }

    logout(isTimeOut?: boolean): void {
      if (StringHelper.isAvailable(this.username) && StringHelper.isAvailable(this.sessionId)) {
          this.sendLogoutRequest(this.username, this.sessionId, isTimeOut).subscribe();
      }
      this.logoutComplete();
    }

    private sendLoginRequest(username: string, password: string): Observable<UserToken> {
        const url = `${this.baseApiUrl}authentication/login`;

        this.failedLogInAttemptCount = this.failedLogInAttemptCount + 1;

        return this.http
            .post(url, { userName: username, password, failedLogInAttemptCount: this.failedLogInAttemptCount })
            .pipe(
                map(this.automapper.curry("default", "UserToken"))
            );
    }

    private loginSuccess = (user: UserToken): void => {
        if (user.isSsoLoginMandatory) {
          this.session.put("ssoLoginEmail", user.emailAddress);
          this.router.navigateByUrl("login/sso").then(response => {
            // get the response from canDeactivate
            if (response) {
              // if response is true, redirect to the right page
              this.clearLocalStorage();
              this.singleSignInService.setLoginRedirect(true);
              this.router.navigateByUrl("login/sso");
            }
          });
        } else if (user.isMFAEnabled || user.isUserMFAEnabled) {
          this.setTemporaryTokenForMfa(user);
          this.router.navigateByUrl("login/mfa").then(response => {
            // get the response from canDeactivate
            if (response) {
              // if response is true, redirect to the right page
              this.clearLocalStorage();
              this.singleSignInService.setLoginRedirect(true);
              this.router.navigateByUrl("login/mfa");
            }
          });
        } else {
          this.loginVerified(user);
        }
    }

    private clearGridState(): void {
      const gridKeys = Object.keys(sessionStorage).filter(key => key.includes("GRID_"));
      gridKeys.forEach(key => sessionStorage.removeItem(key));
    }

    private setUserToken(value: UserToken): void {
        this.session.put(this.AUTHENTICATION_KEY, value);
    }

    private setTemporaryTokenForMfa(value: UserToken): void {
        this.session.put(this.tempAuthKey, value);
    }

    private removeTempUserTokenForMfa() {
        this.session.delete(this.tempAuthKey);
    }

    sendLogoutRequest(username: string, sessionId: string, isTimeOut?: boolean): Observable<object> {
      const url = `${this.baseApiUrl}authentication/logout?userName=${username}&sessionId=${sessionId}&isTimeOut=${isTimeOut}`;
      return this.http.post(url, {});
    }

    private logoutComplete = (): void => {
      this.clearStorage();
            // NOTE: When users logout we refresh to possibly get newly deployed code.
      this.local.put("refresh", true);

      this.zone.run(() => {this.router.navigate(["login"]); });
    }

    getMicroserviceUrl(): Observable<string> {
        const url = `${this.baseApiUrl}authentication/resetpassword/identitymicroserviceurl`;
        return this.http.get(url).pipe(map((response: any) => response as string));
    }

    sendCode(userId: number, mfaType: number) {
        const url = `${this.baseApiUrl}mfa/code/send`;
        return this.http.post(url, { userId, mfaType });
    }

    verifyCode(userId: number, code: string, mfaType: number) {
        const url = `${this.baseApiUrl}mfa/code/verify`;
        this.http.post(url, { userId, code, mfaType })
            .subscribe(() => {
                this.loginVerified(this.tempUserTokenForMfa);
                this.removeTempUserTokenForMfa();
            });
    }

    loginVerified(user: UserToken) {
        this.redirectUrl = StringHelper.isAvailable(user.userOnboardingUrl) ? user.userOnboardingUrl : this.defaultUserLandingUrl;

        this.router.navigateByUrl(this.redirectUrl).then(response => {
          // get the response from canDeactivate
          // if redirection indicated or user logs in for the first time
          if (response || !this.session.hasSessionKey(this.AUTHENTICATION_KEY)) {
            // if response is true, redirect to the right page
            this.clearLocalStorage();
            if (this.isAuthenticated && this.userId !== user.userId) {
              this.isUserChanged = true;
              this.session.clear();
            }
            if (user.organizationId?.toString() !== sessionStorage.getItem("current_so")) {
              this.clearGridState();
            }

            this.failedLogInAttemptCount = 0;
            this.setUserToken(user);
            this.singleSignInService.setLoginRedirect(true);

            // TODO: Put in a service before using somewhere else.
            // TODO: This doesn't work? Testing for now...
            if (environment.production) {
              (window as any).newrelic.setCustomAttribute("mrcsUserId", user.userId);
              (window as any).newrelic.setCustomAttribute("mrcsOrgId", user.organizationId);
            }
            this.gainSightPxService.initialize(user);


            if (user.isTemporaryPassword) {
              this.redirectUrl = "/onboarding";
            }

            if (this.isUserChanged) {
              location.replace(this.redirectUrl);
            } else {
              this.router.navigateByUrl(this.redirectUrl);
            }
          } else {
            this.singleSignInService.setLoginRedirect(false);
          }
        });
    }

    singleSignIn(selectedUserId: number): void {
      this.generateNewTokenForLogin(selectedUserId).subscribe(
        this.loginSuccess
      );
    }

    private clearLocalStorage(): void {
      const analyticsUrl = "/analytics/";
      if (this.routerSnapshotUrl.substring(0, analyticsUrl.length) === analyticsUrl) {
        Object.keys(localStorage).forEach(key => {
          if (key.includes(analyticsUrl)) {
            localStorage.removeItem(key);
          }
        });
      }
    }

    private generateNewTokenForLogin(selectedUserId: number): Observable<UserToken> {
        const url = `${this.baseApiUrl}authentication/single/signIn?currentUserId=${this.userId}&currentSessionId=${this.sessionId}`;
        return this.http.post(url, selectedUserId).pipe(map(this.automapper.curry("default", "UserToken")));
    }

    getSsoConfig(emailAddress: string): Observable<ListItem[]> {
        const url = `${this.baseApiUrl}authentication/sso/verify?emailAddress=${emailAddress}`;
        return this.http.get(url).pipe(
            map(this.automapper.curryMany("default", "ListItem"))
          );
    }

    ssoLogin(emailAddress: string, clientID: string, idToken: string): void {
        const url = `${this.baseApiUrl}authentication/sso/login`;
        this.http.post(url, { userName: emailAddress, password: clientID, identityToken: idToken })
        .pipe(map(this.automapper.curry("default", "UserToken")))
        .subscribe(
            this.loginSuccess
        );
    }

    setupUserSessionEvents() {
      const sessionId = this.sessionId;
      const userName = this.username;
      // Initialize or update the session data for the new user
      this.updateUserSessionData(sessionId, userName, true);
      // Handle tab closure
      window.addEventListener("beforeunload", () => this.handleTabUnload(sessionId, userName));
    }
    // Handles session data update and performs logout if it's the last tab
    private handleTabUnload(sessionId: string, userName: string): void {
    this.updateUserSessionData(sessionId, userName, false);
    this.handleLogoutForLastTab(sessionId);
    }
    // Updates the session data in local storage
    private updateUserSessionData(sessionId: string, userName: string, isTabIncrement: boolean): void {
    const userSessionData = this.getUserSessionData();
    const userIndex = userSessionData.users.findIndex(user => user.sessionId === sessionId && user.userName === userName);
    if (userIndex !== -1) {
    // Update existing user data
    const currentTabCount = parseInt(userSessionData.users[userIndex].tabCount, 10);
    const updatedTabCount = currentTabCount + (isTabIncrement ? 1 : -1);
    userSessionData.users[userIndex].tabCount = updatedTabCount.toString();
    } else if (isTabIncrement) {
    // Add new user data if not found and isTabIncrement is true
    userSessionData.users.push({
      sessionId,
      userName,
      tabCount: "1", // Initial tab count should be 1 when adding a new session
    });
    sessionStorage.setItem("current_so", this.user.organizationId.toString());
    }
    this.saveUserSessionData(userSessionData);
  }
  // Retrieves user session data from local storage
    private getUserSessionData(): { users: { sessionId: string; userName: string; tabCount: string }[] } {
    const storedData = localStorage.getItem(this.activeUserSession);
    return storedData ? JSON.parse(storedData) : { users: [] };
  }
  // Saves user session data to local storage
    private saveUserSessionData(data: { users: { sessionId: string; userName: string; tabCount: string }[] }): void {
    localStorage.setItem(this.activeUserSession, JSON.stringify(data));
  }
  // Checks if it is the last tab and performs logout
    private async handleLogoutForLastTab(sessionId: string): Promise<void> {
    const userSessionData = this.getUserSessionData();
    const userData = userSessionData.users.find(user => user.sessionId === this.sessionId);
    if (userData && parseInt(userData.tabCount, 10) <= 0) {
      await this.logoutUserOnTabClose(userData.userName, userData.sessionId);
    }
  }
    private async logoutUserOnTabClose(userName: string, sessionId: string): Promise<void> {
    await fetch(`${this.baseApiUrl}authentication/logout?userName=${encodeURIComponent(userName)}&sessionId=${encodeURIComponent(sessionId)}&isTimeOut=true`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: { "Content-Type": "application/json" },
      keepalive: true,
    });
  }
    logOutSsoUser(isTimeOut?: boolean): void {
    this.sendLogoutRequest(this.username, this.sessionId, isTimeOut).subscribe();
  }
    clearStorage(): void {
    this.localStorageKeysToClear.forEach(key => localStorage.removeItem(key));
    this.sessionStorageKeysToClear.forEach(key => sessionStorage.removeItem(key));
}
}
