<div class="project-list-container">
  <h3>{{headerTitle}}</h3>
  <ul>
    <li *ngFor="let project of projects; let i = index; trackBy: trackByIndex">
      <div class="project-list-image-container">
        <a [routerLink]="['/project',project.id,downloadFileType]"><app-icon iconName="folder"></app-icon></a>

      </div>
      <div class="project-list-info-container">
        <div class="descr">
          {{project.description}} ({{project.id}})
        </div>
      </div>

    </li>
  </ul>
</div>
