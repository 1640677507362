import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LookerMemberManagementDashboardType } from "../../looker-member-management-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-member-oversight-dashboard",
  templateUrl: "./member-oversight-dashboard.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberOversightDashboardComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerMemberManagementDashboardType.Member_Management;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.CUSTOMER_NAME2];
}
