import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { UserToken } from "../../../../auth/user-token.model";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../core/storage/local.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { VendorDetail } from "../../invoice/vendor-detail/vendor-detail.model";
import { CONTRACT_NUMBER, PLAN_ID, PRODUCT_LIST } from "../../member/chase-detail/chase-detail-chart/attributes";
import { BulkUpdateChaseService } from "../../project/bulk-updates/bulk-update-chase/bulk-update-chase.service";
import { ProjectType } from "../../project/project-type.enum";
import { Project } from "../../project/project.model";
import { AddressDetailInfoEditService } from "../../retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.service";
import { AddressDetailInfoService } from "../../retrieval/psr/address-detail/address-detail-info/address-detail-info.service";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../service-org-admin/service-org-config/service-org-config.service";
import { AnalyticsRequest } from "../analytics-request.model";
import { SharedFilters } from "../analytics-shared-filters.class";
import { AnalyticsService } from "../analytics.service";
import { ContactMethodType } from "../contact-method-type.enum";
import { EntityAttribute } from "../entity-attribute.model";
import { LookerMemberManagementDashboardType } from "../looker-member-management-dashboard-type.enum";
import { AnalyticsItemRequest } from "../models/analytics-item-request.model";
import { MemberManagementService } from "./member-management.service";

@Component({
  selector: "app-analytics-member-management",
  templateUrl: "./analytics-member-management.component.html",
  styleUrls: ["./analytics-member-management.component.scss"],
})
export class AnalyticsMemberManagementComponent extends SharedFilters implements OnInit, AfterViewInit {
  isMemberManagmentFiltersVisible = false;
  projectsInput: CheckboxGroup;
  productsInput: Autocomplete;
  measuresInput: CheckboxGroup;
  hccInput: CheckboxGroup;
  form: FormGroup;
  menuItems = List<MenuItem>();
  isVisible = false;
  lookerUrl: string;
  @Input() additionalForm: FormGroup;
  memberIdInput: Textbox;
  memberFirstNameInput: Textbox;
  memberLastNameInput: Textbox;
  memberDobInput: Textbox;
  memberKeyInput: Textbox;
  overread2FromDate: Calendar;
  overread2ToDate: Calendar;
  npiInput: Textbox;
  chaseIdInput: Textbox;
  userFirstNameInput: Textbox;
  userLastNameInput: Textbox;
  diagnosisCodeInput: Textbox;
  dateOfServiceInput: Textbox;
  @Input() filters: string[];
  @Input() hideFilters: string[];
  @Input() dashboardType: LookerMemberManagementDashboardType;
  formFilterRequest: FormGroup;
  @Output() removeFilter = new EventEmitter<FormGroup>();
  analyticsFilter: AnalyticsRequest;
  pFormGroupKey: string;
  isFilterUpdated = false;
  vrcCodeInput: Autocomplete;
  abstractionFromDate: Calendar;
  abstractionToDate: Calendar;
  pendCodesInput: CheckboxGroup;
  pendStatusInput: CheckboxGroup;
  pendCreateDateFrom: Calendar;
  pendCreateDateTo: Calendar;
  coderIdInput: Autocomplete;
  coderNameInput: Autocomplete;
  overReaderIdInput: Autocomplete;
  overReaderNameInput: Autocomplete;
  codingDateInput: Textbox;
  overReadDateInput: Textbox;
  chaseIdsInput: Textbox;
  coderName = "CoderName";
  overReaderName = "ReaderName";
  conditionCategoryInput: CheckboxGroup;
  chartReceivedDateFromInput: Textbox;
  chartReceivedDateToInput: Textbox;
  updateDateFrom: Calendar;
  updateDateTo: Calendar;
  completionDateFromInput: Calendar;
  completionDateToInput: Calendar;
  workflowStatusInput: CheckboxGroup;
  contactDateFromInput: Calendar;
  contactDateToInput: Calendar;
  startDateInput: Calendar;
  endDateInput: Calendar;
  retrievalDateFrom: Textbox;
  retrievalDateTo: Textbox;
  retrievalTypeInput: CheckboxGroup;
  fileCreateDateFromInput: Textbox;
  fileCreateDateToInput: Textbox;
  dateOfServiceDateFromInput: Textbox;
  dateOfServiceDateToInput: Textbox;
  dataLoadStartDateInput: Textbox;
  dataLoadEndDateInput: Textbox;
  organizationInput: CheckboxGroup;
  clientsInput: CheckboxGroup;
  billingDateFrom: Calendar;
  billingDateTo: Calendar;
  createFromDate: Calendar;
  createToDate: Calendar;
  pendOwnerInput: CheckboxGroup;
  isParentOrg = false;
  errorMessage = "Please select at least one Project";
  showErrorMessage = false;
  showBorder = false;
  dateFilterInput: any;
  toDateFilterInput: any;
  currentDateFilters: string;
  currentDateFilterTab: string;
  @ViewChild("tabTemplate", { static: true }) tabTemplate: any;
  isDateErrorExist = false;
  dateErrorMessage: string;
  userToken: UserToken;
  organizationId: number;
  paymentYearInput: CheckboxGroup;
  serviceYearInput: CheckboxGroup;
  rafCalcSourceInput: CheckboxGroup;
  retrievalOwnerInput: Radiobutton;
  lineOfBusinessInput: CheckboxGroup;
  isHelpModalOpen = false;
  pendTypeInput: CheckboxGroup;
  isFilterIconVisible = false;

  constructor(private readonly formService: FormService,
              private readonly automapper: AutomapperService,
              private readonly retrievalPageService: RetrievalPageService,
              private serviceOrgConfigurationService: ServiceOrgConfigurationService,
              private analyticsService: AnalyticsService,
              private changeDetector: ChangeDetectorRef,
              private memberManagementService: MemberManagementService,
              private session: LocalService,
              private readonly router: Router,
              private readonly bulkUpdateChaseService: BulkUpdateChaseService,
              private readonly userService: UserService,
              private messagingService: MessagingService,
              private addressDetailService: AddressDetailInfoService,
              private addressDetailInfoService: AddressDetailInfoEditService
  ) {
    super();
  }
  ngOnInit() {
    this.userToken = this.userService.getUserToken();
    this.isParentOrg = this.userToken.isParentOrg;
    this.organizationId = this.userToken.organizationId;
    this.getMenuItems();
    this.createForm();
    this.getWorkflowStatus();
    this.getAllSelectableInputs();
    if (StringHelper.isAvailable(this.getRouteUrlValueForSession)) {
      this.showFiltersOrGetData();
    }
    this.getSharedFilterData();
    this.getContractNumbers();
  }

  ngAfterViewInit(): void {
    this.getProjectStatus(() => {
      this.setControlValue("projectStatus", this.projectStatusInput);
    });
  }

  private getSharedFilterData(): void {
    this.genericGet(this.analyticsService, "getDocumentStateGroups", options => {
      this.documentStatus = new CheckboxGroup({ ...this.documentStatus, options: [...options] } as any);
      this.setControlValue("documentStatusAsCsv", this.documentStatus);
    });
    const contactMethod = Object.keys(ContactMethodType).filter(v => !isNaN(Number(v))).join(",");
    this.genericGet(this.analyticsService, "getContactMethodTypes", options => {
      this.contactMethodInput = new CheckboxGroup({ ...this.contactMethodInput, options: [...options] } as any);
      this.setControlValue("contactMethodAsCsv", this.contactMethodInput);
    },              contactMethod);
    this.genericGet(this.addressDetailService, "getVendors", (options: VendorDetail[]) => {
      const mappedOptions = options.map(vendor => new Object({ text: vendor.name, value: vendor.name }));
      this.vendorNameInput = new CheckboxGroup({ ...this.vendorNameInput, options: [...mappedOptions] } as any);
      this.setControlValue("vendorName", this.vendorNameInput);
    });
    this.genericGet(this.addressDetailInfoService, "getSpecialHandlingReasons", options => {
      const mappedOptions = options.map(items => new Object({ text: items.text, value: items.text }));
      this.specialHandlingInput = new CheckboxGroup({ ...this.specialHandlingInput, options: [...mappedOptions] } as any);
      this.setControlValue("specialHandling", this.specialHandlingInput);
    });
    this.genericGet(this.retrievalPageService, "getDocumentSourceTypes", result => {
      const expectedRetrievalOptions = result.map(opt => new SelectableInput({ text: opt.text, value: opt.text }));
      this.expectedRetrievalInput = new CheckboxGroup({ ...this.expectedRetrievalInput, options: [...expectedRetrievalOptions] } as any);
      this.setControlValue("expectedRetrieval", this.expectedRetrievalInput);
    });
  }

  showFilters(): void {
    this.isMemberManagmentFiltersVisible = true;
    if (this.filters.includes(this.currentDateFilters)) {
      this.form.get(this.dateFilterInput.key).setValidators(null);
      this.form.get(this.dateFilterInput.key).setErrors(null);
    }
  }
  onDateChange(fromDate: Calendar, toDate: Calendar) {
    if (this.form.get(fromDate.key).value && this.form.get(toDate.key).value) {
      {
        if ((new Date(this.form.get(fromDate.key).value)) < (new Date(this.form.get(toDate.key).value))) {
          this.showBorder = false;
          this.form.get(fromDate.key).setErrors(null);
          this.form.get(toDate.key).setErrors(null);
          this.isDateErrorExist = false;
          this.getTab(this.selectedTab);
        } else {
          if (this.filters.includes("UpdateDate")) {
            this.form.get(toDate.key).setErrors({ required: true });
          } else {
            this.form.get(fromDate.key).setErrors({ required: true });
          }
          this.showBorder = true;
          this.isDateErrorExist = false;
          this.getTab(this.selectedTab);
        }
      }
    } else if (this.form.get(fromDate.key).value && !this.form.get(toDate.key).value) {
      this.isDateErrorExist = true;
      this.dateErrorMessage = `Please Select a  ${toDate.label.replace("Date", "")} Date.`;
      this.form.get(fromDate.key).setErrors({ "server-error": "" });
      this.showBorder = false;
    } else {
      if (!this.form.get(fromDate.key).value && this.form.get(toDate.key).value) {
        this.isDateErrorExist = true;
        this.form.get(fromDate.key).markAsTouched();
        this.dateErrorMessage = `Please Select a  ${fromDate.label.replace("Date", "")} Date.`;
        this.form.get(fromDate.key).setErrors({ "server-error": "" });
        this.showBorder = false;
      } else {
        this.form.get(fromDate.key).setErrors({ "server-error": "" });
      }
    }
  }
  closeFilters(): void {
    if (this.filters.includes("FileCreateDate")) {
      this.verifyDateRange(this.fileCreateDateFromInput, this.fileCreateDateToInput);
    }
    if (this.filters.includes(this.currentDateFilters)) {
      this.getTab(this.selectedTab);
    }
    if (!this.filters.includes("UpdateDate")) {
      this.setDefaultDateValues();
    }
    if (this.isValidForm && !this.isDateErrorExist) {
      this.getLookerUrl(true, true);
      this.isMemberManagmentFiltersVisible = false; this.showErrorMessage = false;
    } else {
      this.getTab(this.selectedTab);
      this.showErrorMessage = true;
    }
  }
  setDefaultDateValues() {
    if (this.dateFilterInput && this.dateFilterInput.key && !this.form.get(this.dateFilterInput.key).value
    && this.toDateFilterInput && this.toDateFilterInput.key && !this.form.get(this.toDateFilterInput.key).value) {
      this.form.get(this.dateFilterInput.key).setValue("01/01/2020");
      this.form.get(this.toDateFilterInput.key).setValue(new Date());
    }
  }
  checkFromDateGreaterThanToDate() {
    if (this.form.get(this.dateFilterInput?.key)?.value && this.form.get(this.toDateFilterInput?.key)?.value) {
      {
        if ((new Date(this.form.get(this.dateFilterInput.key).value)) < (new Date(this.form.get(this.toDateFilterInput.key).value))) {
          this.errorMessage = "";
          this.form.get(this.dateFilterInput.key).setErrors(null);
        } else if (this.form.get(this.dateFilterInput.key).value == null) {
          this.form.get(this.dateFilterInput.key).setErrors({ required: true });
          this.errorMessage = "Please select a To Date that is after the From Date.";
        }
      }
    } else if (!this.isDateErrorExist && !this.filters.includes("UpdateDate")) {
      this.form.get(this.dateFilterInput?.key)?.setErrors(null);
    }
  }
  getTab(tab: string) {
    this.checkFromDateGreaterThanToDate();
    if (this.form.get(this.updateDateFrom.key).invalid) {
      this.errorMessage = "Please select Update Date";
    } else if (this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = "Please select at least one Project";
    }

    if (tab && (tab === this.currentDateFilterTab) && !this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = "";
    }
    if (tab && (tab === this.currentDateFilterTab) && this.isDateErrorExist && !this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = this.dateErrorMessage;
    }
    if (this.isDateErrorExist && tab && (tab !== this.currentDateFilterTab)) {
      this.errorMessage = this.dateErrorMessage;
    }
  }
  get selectedTab(): string {
    if (this.tabTemplate.tabPanels) {
      const selectedTab = this.tabTemplate.tabPanels._results.filter(x => x.previousIsActive === true);
      if (ArrayHelper.isAvailable(selectedTab)) {
        return selectedTab[0].header;
      }
    } else {
      return "";
    }
  }
  get isValidForm(): boolean {
    let result = true;
    if (this.form.invalid) {
      this.formService.markAllAsTouched(this.form);
      this.changeDetector.markForCheck();
      result = false;
    }
    return result;
  }

  private createForm(): void {
    const futureDate = this.analyticsService.getCalenderFutureDate();

    this.productsInput =
      new Autocomplete({
        key: "products",
        label: "Products",
      });
    this.hccInput =
      new CheckboxGroup({
        key: "hcc",
        label: "HCC Codes",
      });
    this.memberIdInput =
      new Textbox({
        key: "memberId",
        label: "Member ID",
      });
    this.memberFirstNameInput =
      new Textbox({
        key: "memberFirstName",
        label: "Member First Name",
      });
    this.memberLastNameInput =
      new Textbox({
        key: "memberLastName",
        label: "Member Last Name",
      });
    this.memberDobInput =
      new Textbox({
        key: "memberDob",
        label: "Member DOB",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.memberKeyInput =
      new Textbox({
        key: "memberKey",
        label: "Member Key",
      });
    this.overread2ToDate =
      new Calendar({
        key: "overread2ToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate,
      });
    this.npiInput =
      new Textbox({
        key: "npi",
        label: "NPI",
      });
    this.chaseIdInput =
      new Textbox({
        key: "chaseId",
        label: "Chase ID",
      });
    this.userFirstNameInput =
      new Textbox({
        key: "userFirstName",
        label: "User First Name",
      });
    this.userLastNameInput =
      new Textbox({
        key: "userLastName",
        label: "User Last Name",
      });
    this.diagnosisCodeInput =
      new Textbox({
        key: "diagnosisCode",
        label: "Diagnosis Code",
      });
    this.dateOfServiceInput =
      new Textbox({
        key: "dateOfService",
        label: "Date of Service",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.vrcCodeInput =
      new Autocomplete({
        key: "vrcCode",
        label: "VRC Codes",
      });
    this.abstractionFromDate =
      new Calendar({
        key: "abstractionFromDate",
        label: "From",
        placeholder: "Enter From Date",
        errorMessages: {
          isValid: "Please select a From Date that is before the To Date.",
        },
      });
    this.abstractionToDate =
      new Calendar({
        key: "abstractionToDate",
        label: "To",
        placeholder: "Enter To Date",
        errorMessages: {
          isValid: "Please select a To Date that is after the From Date.",
        },
      });
    this.pendCreateDateFrom =
      new Calendar({
        key: "pendFromDate",
        label: "From",
        placeholder: "Enter From Date",
      });
    this.pendCreateDateTo =
      new Calendar({
        key: "pendToDate",
        label: "To",
        placeholder: "Enter To Date",
      });
    this.pendCodesInput = new CheckboxGroup({
      key: "pendCodes",
      label: "SELECT PEND(S)",
    });
    this.pendStatusInput = new CheckboxGroup({
      key: "pendStatus",
      label: "SELECT PEND STATUS(S)",
    });
    this.coderIdInput =
      new Autocomplete({
        key: "coderId",
        label: "Coder ID",
      });
    this.coderNameInput =
      new Autocomplete({
        key: "coderName",
        label: "Coder Name",
      });
    this.overReaderIdInput =
      new Autocomplete({
        key: "overReaderId",
        label: "Over Reader ID",
      });
    this.overReaderNameInput =
      new Autocomplete({
        key: "overReaderName",
        label: "Over Reader Name",
      });
    this.codingDateInput =
      new Textbox({
        key: "codingDate",
        label: "Coding Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.overReadDateInput =
      new Textbox({
        key: "overReadDate",
        label: "Over Read Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.chaseIdsInput =
      new Textbox({
        key: "chaseId",
        label: "ChaseID",
      });
    this.conditionCategoryInput =
      new CheckboxGroup({
        key: "conditionCategory",
        label: "Condition Category",
      });
    this.chartReceivedDateFromInput =
      new Textbox({
        key: "chartReceivedDateFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.chartReceivedDateToInput =
      new Textbox({
        key: "chartReceivedDateTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.contactDateFromInput =
      new Calendar({
        key: "contactFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date(),
      });
    this.contactDateToInput =
      new Calendar({
        key: "contactToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate,
      });
    if (this.filters.includes("UpdateDate")) {
      this.updateDateFrom =
        new Calendar({
          key: "updateFromDate",
          label: "From",
          placeholder: "Enter From Date",
          maxDate: new Date(),
          validators: [Validators.required],
          errorMessages: {
            required: "Please select Update Date.",
          },
        });
    } else {
      this.updateDateFrom =
        new Calendar({
          key: "updateFromDate",
          label: "From",
          placeholder: "Enter From Date",
          maxDate: new Date(),
        });
    }
    this.updateDateTo =
      new Calendar({
        key: "updateToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate,
      });
    this.completionDateFromInput =
      new Calendar({
        key: "completionDateFrom",
        label: "From",
        errorMessages: {
          isValid: "Please select a From Date that is before the To Date.",
        },
      });
    this.completionDateToInput =
      new Calendar({
        key: "completionDateTo",
        label: "To",
        errorMessages: {
          isValid: "Please select a To Date that is after the From Date.",
        },
      });
    this.workflowStatusInput = new CheckboxGroup({
      key: "workflowStatus",
      label: "SELECT WORKFLOW STATUS(S)",
    });
    this.startDateInput =
      new Calendar({
        key: "startDate",
        label: "Start Date",
        placeholder: "Enter Start Date",
      });
    this.endDateInput =
      new Calendar({
        key: "endDate",
        label: "End Date",
        placeholder: "Enter End Date",
      });
    this.retrievalTypeInput =
      new CheckboxGroup({
        key: "RetrievalType",
        label: "Retrieval Types",
      });
    this.retrievalDateFrom =
      new Textbox({
        key: "retrievalFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.retrievalDateTo =
      new Textbox({
        key: "retrievalToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.fileCreateDateFromInput =
      new Textbox({
        key: "fileCreateDateFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.fileCreateDateToInput =
      new Textbox({
        key: "fileCreateDateTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dateOfServiceDateFromInput =
      new Textbox({
        key: "dateOfServiceFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dateOfServiceDateToInput =
      new Textbox({
        key: "dateOfServiceToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dataLoadStartDateInput =
      new Textbox({
        key: "dataLoadStartDate",
        label: "Start Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dataLoadEndDateInput =
      new Textbox({
        key: "dataLoadEndDate",
        label: "End Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.organizationInput =
      new CheckboxGroup({
        key: "organization",
        label: "Service Organizations",
      });
    this.clientsInput =
      new CheckboxGroup({
        key: "client",
        label: "Clients",
      });
    this.paymentYearInput =
      new CheckboxGroup({
        key: "paymentYear",
        label: "Payment Year",
      });
    this.serviceYearInput =
      new CheckboxGroup({
        key: "serviceYear",
        label: "Service Year",
      });
    this.rafCalcSourceInput =
      new CheckboxGroup({
        key: "rafCalcSource",
        label: "RAF Cals Source",
      });
    if (this.filters.includes("Projects")) {
      this.projectsInput =
        new CheckboxGroup({
          key: "projects",
          label: "Member Management Projects",
          validators: [Validators.required],
          errorMessages: {
            required: "Please select at least one Project.",
          },
        });
    } else {
      this.projectsInput =
        new CheckboxGroup({
          key: "projects",
          label: "Member Management Projects",
        });
    }
    if (this.filters.includes("Overread2Date")) {
      this.currentDateFilters = "Overread2Date";
      this.currentDateFilterTab = "Overread2 Date";
      this.overread2FromDate = new Calendar({
        value: null,
        key: "overread2FromDate",
        label: "From",
        validators: [Validators.required],
        maxDate: new Date(),
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      });
      this.dateFilterInput = this.overread2FromDate;
      this.toDateFilterInput = this.overread2ToDate;
    } else {
      this.overread2FromDate =
        new Calendar({
          key: "overread2FromDate",
          label: "From",
          placeholder: "Enter From Date",
        });
    }
    this.billingDateFrom =
      new Calendar({
        key: "billingFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date(),
      });
    this.billingDateTo =
      new Calendar({
        key: "billingToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate,
      });
    this.createFromDate =
      new Calendar({
        key: "createFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date(),
      });
    this.createToDate =
      new Calendar({
        key: "createToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate,
      });
    this.pendOwnerInput = new CheckboxGroup({
      key: "pendOwner",
      label: "Pend Owner",
      options: [
        { text: "Client", value: "Client" },
        { text: "Organization", value: "Organization" },
      ],
    });
    this.pendTypeInput = new CheckboxGroup({
      key: "pendType",
      label: "Pend Type",
      options: [
        { text: "RETRIEVAL", value: "RETRIEVAL" },
        { text: "CLINICAL", value: "CLINICAL" },
      ],
    });
    if (this.filters.includes("PaymentYear")) {
      this.paymentYearInput = new CheckboxGroup({
        ...this.paymentYearInput,
        errorMessages: {
          required: "Please select at least one Payment Year.",
        },
      });
    }
    if (this.filters.includes("ServiceYear")) {
      this.serviceYearInput = new CheckboxGroup({
        ...this.serviceYearInput,
        errorMessages: {
          required: "Please select at least one Service Year.",
        },
      });
    }
    if (this.filters.includes("RafCalcSource")) {
      this.rafCalcSourceInput = new CheckboxGroup({
        ...this.rafCalcSourceInput,
        errorMessages: {
          required: "Please select at least one RAF Cals Source.",
        },
      });
    }
    this.lineOfBusinessInput = new CheckboxGroup({
      key: "lineOfBusiness",
      label: "Line Of Business",
    });
    this.retrievalOwnerInput = new Radiobutton({
      options: [
        new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
        new SelectableInput({ text: "Client", value: "Client" }),
      ],
      key: "retrievalOwner",
      label: "Retrieval Owner",
    });
    this.measuresInput = new CheckboxGroup({
      key: "measures",
      label: "Measures",
    });
    this.setDateDefaultValues(this.filters);
    this.Createform();
  }
  Createform() {
    this.form = this.formService.createFormGroup([this.projectsInput, this.productsInput, this.addressIdInput, this.addressGroupInput,
                                                  this.hccInput, this.memberIdInput, this.memberFirstNameInput, this.memberLastNameInput, this.memberDobInput, this.memberKeyInput,
                                                  this.npiInput, this.chaseIdInput, this.userFirstNameInput, this.userLastNameInput, this.diagnosisCodeInput, this.dateOfServiceInput,
                                                  this.vrcCodeInput, this.abstractionFromDate, this.abstractionToDate, this.pendCodesInput,
                                                  this.pendStatusInput,
                                                  this.pendCreateDateFrom,
                                                  this.pendCreateDateTo, this.coderIdInput, this.coderNameInput, this.overReaderIdInput,
                                                  this.overReaderNameInput, this.overReadDateInput, this.codingDateInput, this.chaseIdsInput, this.conditionCategoryInput,
                                                  this.chartReceivedDateFromInput,
                                                  this.chartReceivedDateToInput,
                                                  this.updateDateFrom,
                                                  this.updateDateTo,
                                                  this.completionDateFromInput,
                                                  this.completionDateToInput,
                                                  this.workflowStatusInput,
                                                  this.contactDateFromInput,
                                                  this.contactDateToInput,
                                                  this.startDateInput,
                                                  this.endDateInput,
                                                  this.retrievalDateFrom,
                                                  this.retrievalDateTo,
                                                  this.retrievalTypeInput,
                                                  this.fileCreateDateFromInput,
                                                  this.fileCreateDateToInput,
                                                  this.dateOfServiceDateFromInput,
                                                  this.dateOfServiceDateToInput,
                                                  this.dataLoadStartDateInput,
                                                  this.dataLoadEndDateInput,
                                                  this.organizationInput,
                                                  this.clientsInput,
                                                  this.overread2FromDate,
                                                  this.overread2ToDate,
                                                  this.billingDateFrom,
                                                  this.billingDateTo,
                                                  this.createFromDate,
                                                  this.createToDate,
                                                  this.pendOwnerInput,
                                                  this.paymentYearInput,
                                                  this.serviceYearInput,
                                                  this.rafCalcSourceInput,
                                                  this.retrievalOwnerInput,
                                                  this.lineOfBusinessInput,
                                                  this.documentQueueId,
                                                  this.documentStatus,
                                                  this.confirmationNumber,
                                                  this.providerGatewayPin,
                                                  this.expectedRetrievalInput,
                                                  this.projectStatusInput,
                                                  this.vendorNameInput,
                                                  this.specialHandlingInput,
                                                  this.measuresInput,
                                                  this.healthPlanInput,
                                                  this.contractNumberInput,
                                                  this.moveDateStart,
                                                  this.moveDateEnd,
                                                  this.chaseTags,
                                                  this.dateRangeStart,
                                                  this.dateRangeEnd,
                                                  this.qaDateFrom,
                                                  this.qaDateTo,
                                                  this.userStatus,
                                                  this.userEmail,
                                                  this.login,
                                                  this.currentChaseStatus,
                                                  this.currentProcessStep,
                                                  this.pendBy,
                                                  this.vendorInvoiceType,
                                                  this.pendTypeInput,
    ]);
    this.setForm(this.form);
  }

  dateValidationToAndForm(value: string) {
    if (value === "abstraction") {
      if (StringHelper.isAvailable(this.form.get(this.abstractionToDate.key).value)
        || StringHelper.isAvailable(this.form.get(this.abstractionFromDate.key).value)
      ) {
        const abstractionFromDate = this.form.get(this.abstractionFromDate.key).value;
        this.abstractionFromDate = new Calendar({
          ...this.abstractionFromDate,
          value: abstractionFromDate,
          validators: [Validators.required],
          placeholder: "Enter From Date",
          errorMessages: {
            required: "Enter From Date",
          },
        } as any);
        const abstractionToDate = this.form.get(this.abstractionToDate.key).value;
        this.abstractionToDate = new Calendar({
          ...this.abstractionToDate,
          value: abstractionToDate,
          validators: [Validators.required],
          placeholder: "Enter To Date",
          errorMessages: {
            required: "Enter To Date",
          },
        } as any);
      } else {
        const abstractionFromDate = this.form.get(this.abstractionFromDate.key).value;
        this.abstractionFromDate = new Calendar({
          ...this.abstractionFromDate,
          value: abstractionFromDate,
          placeholder: "Enter From Date",
        } as any);
        const abstractionToDate = this.form.get(this.abstractionToDate.key).value;
        this.abstractionToDate = new Calendar({
          ...this.abstractionToDate,
          value: abstractionToDate,
          placeholder: "Enter To Date",
        } as any);
      }
    }
    if (value === "pend") {
      if (StringHelper.isAvailable(this.form.get(this.pendCreateDateTo.key).value)
        || StringHelper.isAvailable(this.form.get(this.pendCreateDateFrom.key).value)
      ) {
        const pendFromDate = this.form.get(this.pendCreateDateFrom.key).value;
        this.pendCreateDateFrom = new Calendar({
          ...this.pendCreateDateFrom,
          value: pendFromDate,
          validators: [Validators.required],
          placeholder: "Enter From Date",
          errorMessages: {
            required: "Enter From Date",
          },
        } as any);
        const pendToDate = this.form.get(this.pendCreateDateTo.key).value;
        this.pendCreateDateTo = new Calendar({
          ...this.pendCreateDateTo,
          value: pendToDate,
          validators: [Validators.required],
          placeholder: "Enter To Date",
          errorMessages: {
            required: "Enter To Date",
          },
        } as any);
      } else {
        const pendFromDate = this.form.get(this.pendCreateDateFrom.key).value;
        this.pendCreateDateFrom = new Calendar({
          ...this.pendCreateDateFrom,
          value: pendFromDate,
          placeholder: "Enter From Date",
        } as any);
        const pendToDate = this.form.get(this.pendCreateDateTo.key).value;
        this.pendCreateDateTo = new Calendar({
          ...this.pendCreateDateTo,
          value: pendToDate,
          placeholder: "Enter To Date",
        } as any);
      }
    }
    if (value === "chartReceived") {
      if (StringHelper.isAvailable(this.form.get(this.chartReceivedDateFromInput.key).value)
        || StringHelper.isAvailable(this.form.get(this.chartReceivedDateToInput.key).value)
      ) {
        const chartReceivedDateFromInput = this.form.get(this.chartReceivedDateFromInput.key).value;
        this.chartReceivedDateFromInput = new Textbox({
          ...this.chartReceivedDateFromInput,
          value: chartReceivedDateFromInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
            required: "Field is required.",
          },
        } as any);
        const chartReceivedDateToInput = this.form.get(this.chartReceivedDateToInput.key).value;
        this.chartReceivedDateToInput = new Textbox({
          ...this.chartReceivedDateToInput,
          value: chartReceivedDateToInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
            required: "Field is required.",
          },
        } as any);
      } else {
        const chartReceivedDateFromInput = this.form.get(this.chartReceivedDateFromInput.key).value;
        this.chartReceivedDateFromInput = new Textbox({
          ...this.chartReceivedDateFromInput,
          value: chartReceivedDateFromInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
          },
        } as any);
        const chartReceivedDateToInput = this.form.get(this.chartReceivedDateToInput.key).value;
        this.chartReceivedDateToInput = new Textbox({
          ...this.chartReceivedDateToInput,
          value: chartReceivedDateToInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
          },
        } as any);
      }
    }

    const pendStatus = this.form.get(this.pendStatusInput.key).value;
    this.pendStatusInput = new CheckboxGroup({
      ...this.pendStatusInput,
      value: pendStatus,
    } as any);
    // this.getPendStatus();
    this.Createform();
    this.formService.markAllAsTouched(this.form);
  }
  private getProjects(): void {
    if (this.isParentOrg) {
      if (this.filters.includes("Client")) {
        this.getProjectsByClient();
      } else {
        this.getProjectsByOrganization();
      }
    } else {
      this.getProjectsByType();
    }
  }
  private getProjectsByClient() {
    const selectedClientIds = this.clientsInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedClientIds)) {
      this.analyticsService.getProjectsByClients(selectedClientIds, ProjectType.RISK)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.bindProjects(options, true);
        });
    }
  }
  private getProjectsByOrganization() {
    const selectedOrgIds = this.organizationInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedOrgIds)) {
      this.analyticsService.getProjects(selectedOrgIds, ProjectType.MEMBER_MANAGEMENT)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.bindProjects(options, true);
        });
    }
  }
  private getProjectsByType(): void {
    const memberManagmentProject = new Project({
      projectTypeId: ProjectType.MEMBER_MANAGEMENT,
    });
    const chartLakeProject = new Project({
      projectTypeId: ProjectType.CHART_LAKE,
    });
    const memberManagmentProjects$ = this.retrievalPageService.getProjects(memberManagmentProject).pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")));
    const chartLakeProjects$ = this.retrievalPageService.getProjects(chartLakeProject).pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")));
    let projects = [];
    this.sink.add(
      forkJoin({ memberManagmentProjects: memberManagmentProjects$, chartLakeProjects: chartLakeProjects$ }).subscribe(res => {
        projects = [...res.memberManagmentProjects];
        if (ArrayHelper.isAvailable(res.chartLakeProjects)) {
          projects = [...res.memberManagmentProjects, ...res.chartLakeProjects];
        }
        this.bindProjects(projects, false);
      })
    );
  }

  private bindProjects(options: any, loadHccControl: boolean): void {
    const restrictedDashboards = [];
    this.memberManagementService.getRestrictedProjects().subscribe(restrictedProjects => {
      if (NumberHelper.isAvailable(this.dashboardType) &&
        restrictedDashboards.includes(this.dashboardType) &&
        ArrayHelper.isAvailable(restrictedProjects)) {
        const filteredOptions = (options as SelectableInput[]).filter(rp => restrictedProjects.includes(rp.value as number));
        this.setProjects(filteredOptions, loadHccControl);
      } else {
        this.setProjects(options, loadHccControl);
      }
    });
}

  private setProjects(options: any, loadHccControl: boolean): void {
    this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
    this.setProjectControlValue(this.projectsInput);
    // if (loadHccControl) {
    //   this.getHccListByProject();
    // }
    this.formService.updateDom.next();
  }

  private getProducts(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId)
      .subscribe(configuration => {
        this.getProductsInput(configuration);
        this.setProductsInput();
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }

  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    productOptions.unshift(new SelectableInput({ text: "*clear filter", value: "" }));
    this.productsInput = new Autocomplete({ ...this.productsInput, options: productOptions } as any);
  }

  private getOrganizations(): void {
    this.analyticsService
      .getOrganizations()
      .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.organizationInput = new CheckboxGroup({ ...this.organizationInput, options } as any);
        this.setOrganizationInput();
        this.getProjects();
        this.changeDetector.markForCheck();
      });
  }
  private getClients(): void {
    const selectedOrgIds = this.organizationInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedOrgIds)) {
      this.analyticsService.getClients(selectedOrgIds)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.clientsInput = new CheckboxGroup({ ...this.clientsInput, options } as any);
          this.setClientInputValue();
          this.formService.updateDom.next();
        });
    }
  }
  getMenuItems() {
    const analyticsItemRequest = new AnalyticsItemRequest({
      projectType: ProjectType.RISK,
      reportUrl: this.getRouteUrlValue,
    });

    this.analyticsService
      .getAnalyticMenuItems(analyticsItemRequest)
      .subscribe(menuItem => {
        this.menuItems = this.assignAndNotify(menuItem);
      });
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const items = List(data);
    return items;
  }

  private getHccList(): void {
    this.memberManagementService
      .getHccList()
      .pipe(map(this.automapper.curryMany("HccModel", "SelectableInput")))
      .subscribe(options => {
        this.hccInput = new CheckboxGroup({ ...this.hccInput, options } as any);
        this.conditionCategoryInput = new CheckboxGroup({ ...this.conditionCategoryInput, options } as any);
        this.setHccControlValue(this.hccInput);
        this.setConditionCategoryControlValue(this.conditionCategoryInput);
        this.formService.updateDom.next();
      });
  }
  private bindHccControl(options: any): void {
    this.hccInput = new CheckboxGroup({ ...this.hccInput, options } as any);
    this.conditionCategoryInput = new CheckboxGroup({ ...this.conditionCategoryInput, options } as any);
    this.setHccControlValue(this.hccInput);
    this.setConditionCategoryControlValue(this.conditionCategoryInput);
    this.formService.updateDom.next();

  }
  private getLookerUrl(isUpdateFilter: boolean, isChangeFormat?: boolean): void {
    this.isFilterUpdated = isUpdateFilter;
    if (!isUpdateFilter) {
      this.setFilteredInputValue(this.form, this.analyticsFilter);
      this.formFilterRequest = this.form;
    } else {
      this.formFilterRequest = this.form;
    }
    const analyticsRequest = this.bindFilterData(isChangeFormat);

    this.analyticsService.getLookerUrl(analyticsRequest).subscribe(data => {
      this.session.put(this.getRouteUrlValueForSession, analyticsRequest);
      this.isVisible = true;
      this.lookerUrl = data;
      this.changeDetector.markForCheck();
    });
  }

  bindFilterData(isUpdate?: boolean): AnalyticsRequest {
    const [toDateAbstract, fromDateAbstract] = this.getDateFromDatePicker(this.abstractionToDate, this.abstractionFromDate, isUpdate);
    const [toDateCompletion, fromDateCompletion] = this.getDateFromDatePicker(this.completionDateToInput, this.completionDateFromInput, isUpdate);
    const [toDateOverread2, fromDateOverread2] = this.getDateFromDatePicker(this.overread2ToDate, this.overread2FromDate, isUpdate);
    const [toDatePend, fromDatePend] = this.getDateFromDatePicker(this.pendCreateDateTo, this.pendCreateDateFrom, isUpdate);
    const [startDate, endDate] = this.getDateFromDatePicker(this.startDateInput, this.endDateInput, isUpdate);
    const [toDateBilling, fromDateBilling] = this.getDateFromDatePicker(this.billingDateTo, this.billingDateFrom, isUpdate);
    const [toDateUpdate, fromDateUpdate] = this.getDateFromDatePicker(this.updateDateTo, this.updateDateFrom, isUpdate);
    const [toContactDate, fromContactDate] = this.getDateFromDatePicker(this.contactDateToInput, this.contactDateFromInput, isUpdate);
    const [toCreateDate, fromCreateDate] = this.getDateFromDatePicker(this.createToDate, this.createFromDate, isUpdate);
    const [moveStartDate, moveEndDate] = this.getDateFromDatePicker(this.moveDateStart, this.moveDateEnd);
    const [dateRangeStart, dateRangeEnd] = this.getDateFromDatePicker(this.dateRangeStart, this.dateRangeEnd);
    const [qaDateFrom, qaDateTo] = this.getDateFromDatePicker(this.qaDateFrom, this.qaDateTo);

    return new AnalyticsRequest({
      projectIdsAsCsv: this.getFilterValue(this.projectsInput.key, true, true),
      productName: this.getFilterValue(this.productsInput.key, false),
      addressId: this.getSingleValueFilter(this.addressIdInput.key),
      addressGroup: this.getSingleValueFilter(this.addressGroupInput.key),
      hccAsCsv: this.getFilterValue(this.hccInput.key, true, false),
      dashboardType: this.dashboardType,
      memberId: this.form.get("memberId").value,
      memberFirstName: this.form.get("memberFirstName").value,
      memberLastName: this.form.get("memberLastName").value,
      memberDob: this.form.get("memberDob").value,
      memberKey: this.form.get("memberKey").value,
      overread2ToDate: toDateOverread2,
      overread2FromDate: fromDateOverread2,
      npi: this.form.get("npi").value,
      chaseId: this.form.get("chaseId").value,
      userFirstName: this.getFilterValue(this.userFirstNameInput.key, false),
      userLastName: this.getFilterValue(this.userLastNameInput.key, false),
      diagnosisCode: this.getSingleValueFilter(this.diagnosisCodeInput.key),
      dateOfService: this.getFilterValue(this.dateOfServiceInput.key, false),
      vrcCode: this.getFilterValue(this.vrcCodeInput.key, false),
      abstractionToDate: toDateAbstract,
      abstractionFromDate: fromDateAbstract,
      pendStatusCsv: this.getFilterValue(this.pendStatusInput.key, true, true),
      pendCodeCsv: this.getFilterValue(this.pendCodesInput.key, true, false),
      pendTypeIdCsv: this.getFilterValue(this.pendCodesInput.key, true, true),
      pendToDate: toDatePend,
      pendFromDate: fromDatePend,
      coderId: this.getFilterValue(this.coderIdInput.key, false),
      coderName: this.getFilterValue(this.coderNameInput.key, false),
      overReaderId: this.getFilterValue(this.overReaderIdInput.key, false),
      overReaderName: this.getFilterValue(this.overReaderNameInput.key, false),
      codingDate: this.getFilterValue(this.codingDateInput.key, false),
      overReadDate: this.getFilterValue(this.overReadDateInput.key, false),
      reportChaseID: this.getFilterValue(this.chaseIdsInput.key, false),
      conditionCategoryAsCsv: this.getFilterValue(this.conditionCategoryInput.key, true, false),
      chartReceivedDateFrom: this.form.get("chartReceivedDateFrom").value,
      chartReceivedDateTo: this.form.get("chartReceivedDateTo").value,
      updateFromDate: fromDateUpdate,
      updateToDate: toDateUpdate,
      completionFromDate: fromDateCompletion,
      completionToDate: toDateCompletion,
      workflowStatusCsv: this.getFilterValue(this.workflowStatusInput.key, true, true),
      startDate,
      endDate,
      contactFromDate: fromContactDate,
      contactToDate: toContactDate,
      retrievalFromDate: this.getFilterValue(this.retrievalDateFrom.key, false),
      retrievalToDate: this.getFilterValue(this.retrievalDateTo.key, false),
      retrievalTypeAsCsv: this.getFilterValue(this.retrievalTypeInput.key, true, true),
      fileCreateFromDate: this.form.get("fileCreateDateFrom").value,
      fileCreateToDate: this.form.get("fileCreateDateTo").value,
      dateOfServiceFromDate: this.form.get("dateOfServiceFromDate").value,
      dateOfServiceToDate: this.form.get("dateOfServiceToDate").value,
      dataLoadStartDate: this.form.get("dataLoadStartDate").value,
      dataLoadEndDate: this.form.get("dataLoadEndDate").value,
      serviceOrgIdsAsCsv: this.getFilterValue(this.organizationInput.key, true, true),
      clientIdsAsCsv: this.getFilterValue(this.clientsInput.key, true, true),
      billingFromDate: fromDateBilling,
      billingToDate: toDateBilling,
      createFromDate: fromCreateDate,
      createToDate: toCreateDate,
      pendOwnerAsCsv: this.getFilterValue(this.pendOwnerInput.key, true, true),
      paymentYear: this.getFilterValue(this.paymentYearInput.key, true, true),
      serviceYear: this.getFilterValue(this.serviceYearInput.key, true, true),
      rafCalcSource: this.getFilterValue(this.rafCalcSourceInput.key, true, true),
      lineOfBusinessAsCsv: this.getFilterValue(this.lineOfBusinessInput.key, true, true),
      retrievalOwner: this.getSingleValueFilter(this.retrievalOwnerInput.key),
      documentQueueId: this.getSingleValueFilter(this.documentQueueId.key),
      documentStatusAsCsv: this.getFilterValue(this.documentStatus.key, true, true),
      confirmationNumber: this.getSingleValueFilter(this.confirmationNumber.key),
      providerGatewayPin: this.getSingleValueFilter(this.providerGatewayPin.key),
      expectedRetrieval: this.getFilterValue(this.expectedRetrievalInput.key, true, true),
      projectStatus: this.getFilterValue(this.projectStatusInput.key, true, true),
      vendorName: this.getFilterValue(this.vendorNameInput.key, true, true),
      specialHandling: this.getFilterValue(this.specialHandlingInput.key, true, true),
      pendTypeAsCsv: this.getFilterValue(this.pendTypeInput.key, true, true),
      measureIdsAsCsv: this.getFilterValue(this.measuresInput.key, true, true),
      healthPlanNamesAsCsv: this.getFilterValue(this.healthPlanInput.key, true, true),
      contractNumbersAsCsv: this.getFilterValue(this.contractNumberInput.key, true, true),
      chaseTags: this.getSingleValueFilter(this.chaseTags.key),
      moveStartDate,
      moveEndDate,
      dateRangeStart,
      dateRangeEnd,
      login: this.getSingleValueFilter(this.login.key),
      userEmail: this.getSingleValueFilter(this.userEmail.key),
      userStatus: this.getFilterValue(this.userStatus.key, true, true),
      currentChaseStatus: this.getFilterValue(this.currentChaseStatus.key, true, true),
      currentProcessStep: this.getFilterValue(this.currentProcessStep.key, true, true),
      pendBy: this.getSingleValueFilter(this.pendBy.key),
      qaDateFrom,
      qaDateTo,
      vendorInvoiceType: this.getFilterValue(this.vendorInvoiceType.key, true, true),
      hideFilters: this.hideFilters,
    });
  }

  getSingleValueFilter(key: string) {
    const { pristine, value, touched } = this.form.get(key);
    if (!pristine && touched && !value) {
      return null;
    }
    return value ? value : this.setAnalyticsFilter(key);
  }

  getDateFromDatePicker(toKey: any, fromKey: any, isUpdate?: boolean): [string, string] {
    let todate = "";
    let fromdate = "";
    todate = this.form.get(toKey.key).value ? new Date(this.form.get(toKey.key).value).toLocaleDateString("en-ZA") : null;
    fromdate = this.form.get(fromKey.key).value ? new Date(this.form.get(fromKey.key).value).toLocaleDateString("en-ZA") : null;

    return [todate, fromdate];
  }

  showFilter(filterValue: string): boolean {
    return this.filters.includes(filterValue);
  }

  getAllSelectableInputs(): void {
    if (!this.isParentOrg) {
      this.getProjects();
      const restrictedDashboards = [];
      if (restrictedDashboards.includes(this.dashboardType)) {
        this.getHccList();
      }
    }
    this.getProducts();
    this.getVrcCode();
    this.getCoderNameList();
    this.getOverReaderNameList();
    this.getOrganizations();
  }

  // TODO : Need to refactor the below code.
  onRemoveFilter(event: FormGroup) {
    this.removeFilter.emit(event);
    const projectFilteredValues = [];
    const hccFilteredValues = [];
    const ccFilteredValues = [];
    const pendStatusFilteredValues = [];
    const pendCodesFilteredValues = [];
    const workFlowStatusFilteredValues = [];
    const organizationFilteredValues = [];
    const clientFilteredValues = [];
    Object.keys(event.controls).forEach(key => {
      const controlValue = this.formFilterRequest.get(key);
      if (ArrayHelper.isAvailable(controlValue.value)) {
        controlValue.value.forEach(selectedValue => {
          switch (key) {
            case "projects":
              const projectOptions = (key === selectedValue.text) ? this.projectsInput.options.find(a => a.text === selectedValue.value)
                : this.projectsInput.options.find(a => a.text === selectedValue.text);
              projectFilteredValues.push({ text: projectOptions.text, value: projectOptions.value });
              this.form.get(key).setValue(projectFilteredValues);
              break;
            case "hcc":
              const hccOptions = this.hccInput.options.find(a => a.text === selectedValue.value);
              hccFilteredValues.push({ text: hccOptions.text, value: hccOptions.value });
              this.form.get(key).setValue(hccFilteredValues);
              break;
            case this.conditionCategoryInput.key:
              const conditionCategoryOptions = this.conditionCategoryInput.options.find(a => a.text === selectedValue.value);
              ccFilteredValues.push({ text: conditionCategoryOptions.text, value: conditionCategoryOptions.value });
              this.form.get(key).setValue(ccFilteredValues);
              break;
            case this.pendStatusInput.key:
              const pendStatus = (key === selectedValue.text) ? this.pendStatusInput.options.find(a => a.text === selectedValue.value)
                : this.pendStatusInput.options.find(a => a.text === selectedValue.text);
              pendStatusFilteredValues.push({ text: pendStatus.text, value: pendStatus.value });
              this.form.get(key).setValue(pendStatusFilteredValues);
              break;
            case this.pendCodesInput.key:
              const pendCodes = (key === selectedValue.text) ? this.pendCodesInput.options.find(a => a.text === selectedValue.value)
                : this.pendCodesInput.options.find(a => a.text === selectedValue.text);
              pendCodesFilteredValues.push({ text: pendCodes.text, value: pendCodes.value });
              this.form.get(key).setValue(pendCodesFilteredValues);
              break;
            case this.workflowStatusInput.key:
              const workflowStatus = (key === selectedValue.text) ? this.workflowStatusInput.options.find(a => a.text === selectedValue.value)
                : this.workflowStatusInput.options.find(a => a.text === selectedValue.text);
              workFlowStatusFilteredValues.push({ text: workflowStatus.text, value: workflowStatus.value });
              this.form.get(key).setValue(workFlowStatusFilteredValues);
              break;
            case this.organizationInput.key:
              const organizationFilter = (key === selectedValue.text) ? this.organizationInput.options.find(a => a.text === selectedValue.value)
                : this.organizationInput.options.find(a => a.text === selectedValue.text);
              organizationFilteredValues.push({ text: organizationFilter.text, value: organizationFilter.value });
              this.form.get(key).setValue(organizationFilteredValues);
              break;
            case this.clientsInput.key:
              const clientFilter = (key === selectedValue.text) ? this.clientsInput.options.find(a => a.text === selectedValue.value)
                : this.clientsInput.options.find(a => a.text === selectedValue.text);
              clientFilteredValues.push({ text: clientFilter.text, value: clientFilter.value });
              this.form.get(key).setValue(clientFilteredValues);
              break;
            default:
              break;
          }
        });
      }
    });
    if (this.filters.includes("Projects")) {
      if (ArrayHelper.isAvailable(projectFilteredValues)) {
        this.getLookerUrl(true);
      } else {
        const analyticsRequest = this.bindFilterData();
        this.session.put(this.getRouteUrlValueForSession, analyticsRequest);
        this.isMemberManagmentFiltersVisible = true;
        this.isVisible = false;
      }
    } else {
      this.getLookerUrl(true);
    }
  }

  resetAllFilters(): void {
    this.showErrorMessage = false;
    this.dateErrorMessage = "";
    this.isDateErrorExist = false;
    this.form.reset();
    if (this.filters.includes("Projects")) {
      this.isVisible = false;
      this.session.delete(this.getRouteUrlValueForSession);
    } else {
      this.getLookerUrl(false);
    }
    this.removeFilter.emit(this.formFilterRequest);
  }

  get getRouteUrlValue(): string {
    return this.router.url;
  }
  get getRouteUrlValueForSession(): string {
    return `${window.location.href}/${this.organizationId}`;
  }

  showFiltersOrGetData(): void {
    const filterData = this.session.get(this.getRouteUrlValueForSession, new AnalyticsRequest());
    if (StringHelper.isAvailable(this.getRouteUrlValueForSession) && this.session.get(this.getRouteUrlValueForSession, null)) {
      const sessionAnalyticsFilters = this.session.get(this.getRouteUrlValueForSession, null);
      this.analyticsFilter = sessionAnalyticsFilters;
    } else {
      this.analyticsFilter = filterData;
    }

    const filterResult = Object.keys(filterData).map(key => ({ key, value: filterData[key] }));
    if (this.filters.includes("Projects")) {
      this.isMemberManagmentFiltersVisible = filterResult.filter(c => c.value).length < 2;
    }

    this.setDateValidation();
    this.setServiceOrganizationValidation();
    if (!this.isMemberManagmentFiltersVisible) {
      this.getLookerUrl(false);
    }
    const dashboardsWithoutFilter = [];
    if (dashboardsWithoutFilter.includes(this.dashboardType)) {
      this.isFilterIconVisible = true;
    }
    this.session.delete(this.getRouteUrlValueForSession);
  }
  setDateValidation() {
    if (this.filters.includes("PendDate")) {
      this.dateFilterInput = this.pendCreateDateFrom;
      this.toDateFilterInput = this.pendCreateDateTo;
      this.currentDateFilters = "PendDate";
      this.currentDateFilterTab = "Pend Date";
      const pendCreateDateFrom = this.form.get(this.pendCreateDateFrom.key).value;
      this.pendCreateDateFrom = new Calendar({
        ...this.pendCreateDateFrom,
        value: pendCreateDateFrom,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("BillingDate")) {
      this.dateFilterInput = this.billingDateFrom;
      this.toDateFilterInput = this.billingDateTo;
      this.currentDateFilters = "BillingDate";
      this.currentDateFilterTab = "Billing Date";
      const billingDateFrom = this.form.get(this.billingDateFrom.key).value;
      this.billingDateFrom = new Calendar({
        ...this.billingDateFrom,
        value: billingDateFrom,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("UpdateDate")) {
      this.dateFilterInput = this.updateDateFrom;
      this.toDateFilterInput = this.updateDateTo;
      this.currentDateFilters = "UpdateDate";
      this.currentDateFilterTab = "Update Date";
      const updateDateTo = this.form.get(this.updateDateTo.key).value;
      this.updateDateTo = new Calendar({
        ...this.updateDateTo,
        value: updateDateTo,
        validators: [Validators.required],
        placeholder: "Enter To Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("ContactDate")) {
      this.dateFilterInput = this.contactDateFromInput;
      this.toDateFilterInput = this.contactDateToInput;
      this.currentDateFilters = "ContactDate";
      this.currentDateFilterTab = "Contact Date";
      const contactDateFromInput = this.form.get(this.contactDateFromInput.key).value;
      this.contactDateFromInput = new Calendar({
        ...this.contactDateFromInput,
        value: contactDateFromInput,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("CreateDate")) {
      this.dateFilterInput = this.createFromDate;
      this.toDateFilterInput = this.createToDate;
      this.currentDateFilters = "CreateDate";
      this.currentDateFilterTab = "Create Date";
      const createFromDate = this.form.get(this.createFromDate.key).value;
      this.createFromDate = new Calendar({
        ...this.createFromDate,
        value: createFromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a End Date that is after the Start Date.",
        },
      } as any);
    }
  }
  private setServiceOrganizationValidation(): void {
    if (this.isParentOrg) {
      this.form.get("organization").setValidators([Validators.required]);
      this.form.get("organization").updateValueAndValidity();
      this.errorMessage = "Please select at least one Service Organization.";
      this.isMemberManagmentFiltersVisible = !StringHelper.isAvailable(this.analyticsFilter.serviceOrgIdsAsCsv);
    } else {
      this.form.get("organization").clearValidators();
      this.form.get("organization").updateValueAndValidity();
    }

  }

  private setProjectControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.projectIdsAsCsv)) {
      const projectFilteredValues = this.analyticsFilter.projectIdsAsCsv.split(/\s*,\s*/);
      projectFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.projectsInput.options)) {
          filterValue.push(this.projectsInput.options.find(a => a.value === +item || a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.projectsInput.key).setValue(control.selectedOptions);
    }
  }

  private setPendStatusControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.pendStatusCsv)) {
      const pendFilteredValues = this.analyticsFilter.pendStatusCsv.split(",");
      pendFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.pendStatusInput.options)) {
          filterValue.push(this.pendStatusInput.options.find(a => a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.pendStatusInput.key).setValue(control.selectedOptions);
    }
  }

  private setPendCodesControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.pendTypeIdCsv)) {
      const pendFilteredValues = this.analyticsFilter.pendTypeIdCsv.split(",");
      pendFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.pendCodesInput.options)) {
          filterValue.push(this.pendCodesInput.options.find(a => a.value === Number(item)));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.pendCodesInput.key).setValue(control.selectedOptions);
    }
  }

  private setHccControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.hccAsCsv)) {
      const hccFilteredValues = this.analyticsFilter.hccAsCsv.split(/\s*,\s*/);
      hccFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.hccInput.options)) {
          filterValue.push(this.hccInput.options.find(a => a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.hccInput.key).setValue(control.selectedOptions);
    }
  }

  private setConditionCategoryControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.conditionCategoryAsCsv)) {
      const ccFilteredValues = this.analyticsFilter.conditionCategoryAsCsv.split(/\s*,\s*/);
      ccFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.conditionCategoryInput.options)) {
          filterValue.push(this.conditionCategoryInput.options.find(a => a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.conditionCategoryInput.key).setValue(control.selectedOptions);
    }
  }

  private setProductsInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.productName)) {
      const optionsValue = this.productsInput.options.find(a => a.value === this.analyticsFilter.productName);
      this.productsInput.value = [optionsValue];
      this.form.get(this.productsInput.key).setValue(this.productsInput.value[0]);
    }
  }
  private setOrganizationInput(): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.serviceOrgIdsAsCsv)) {
      const ccFilteredValues = this.analyticsFilter.serviceOrgIdsAsCsv.split(/\s*,\s*/);
      ccFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.organizationInput.options)) {
          filterValue.push(this.organizationInput.options.find(a => a.value === item));
        }
      });
      this.organizationInput.selectedOptions = filterValue;
      this.form.get(this.organizationInput.key).setValue(this.organizationInput.selectedOptions);
    }
  }
  private setClientInputValue(): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.clientIdsAsCsv)) {
      const ccFilteredValues = this.analyticsFilter.clientIdsAsCsv.split(/\s*,\s*/);
      ccFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.clientsInput.options)) {
          filterValue.push(this.clientsInput.options.find(a => a.value === item));
        }
      });
      this.clientsInput.selectedOptions = filterValue;
      this.form.get(this.clientsInput.key).setValue(this.clientsInput.selectedOptions);
    }
  }
  private setFilteredInputValue(formControls: FormGroup, analyticsFilter: AnalyticsRequest): void {
    Object.keys(formControls.controls).forEach(key => {
      for (const analyticFilterKey of Object.keys(analyticsFilter)) {
        this.pFormGroupKey = analyticFilterKey;
        if (key === analyticFilterKey || key === this.controlKeyToMatch) {
          const analyticFilterValue = analyticsFilter[analyticFilterKey];
          this.form.get(key).setValue(analyticFilterValue);
        }
      }
    });
  }

  private setControlValue(filterProp: string, input: CheckboxGroup | Radiobutton): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter[filterProp])) {
      const filters = this.analyticsFilter[filterProp].split(/\s*,\s*/);
      filters.forEach((item: string) => {
        if (ArrayHelper.isAvailable(input.options)) {
          filterValue.push(input.options.find(a => a.value === item));
        }
      });
      if (input.hasOwnProperty("selectedOptions")) {
        (input as CheckboxGroup).selectedOptions = filterValue;
        this.form.get(input.key).setValue((input as CheckboxGroup).selectedOptions);
      } else {
        input.value = filterValue[0].value;
        this.form.get(input.key).setValue(input.value);
      }
    }
  }

  private setPendOwnerControlValue(): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.pendOwnerAsCsv)) {
      const pendOwnerFilter = this.analyticsFilter.pendOwnerAsCsv.split(/\s*,\s*/);
      pendOwnerFilter.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.pendOwnerInput.options)) {
          filterValue.push(this.pendOwnerInput.options.find(a => a.value === item));
        }
      });
      this.pendOwnerInput.selectedOptions = filterValue;
      this.form.get(this.pendOwnerInput.key).setValue(this.pendOwnerInput.selectedOptions);
    }
  }

  get controlKeyToMatch(): string {
    let controlKey: string;
    switch (this.pFormGroupKey) {
      case "productName":
        controlKey = "products";
        break;
      case "addressId":
        controlKey = "address";
        break;
      case "fileCreateFromDate":
        controlKey = "fileCreateDateFrom";
        break;
      case "fileCreateToDate":
        controlKey = "fileCreateDateTo";
        break;
      default:
        break;
    }
    return controlKey;
  }

  get isInvalidFilter(): boolean {
    return this.form.invalid;
  }

  getFilterValue(key: string, isCsvValue = true, isUseValue = true) {

    if (this.isFilterUpdated && isCsvValue) {
      return this.analyticsService.getValueForAnalyticsRequestAsCsv(this.form.get(key).value, isUseValue);
    } else if (this.isFilterUpdated) {
      if (key === "projects" || key === "vrcCode") {
        return this.form.get(key).value ? this.form.get(key).value.value : null;
      } else {
        return this.form.get(key).value ? this.form.get(key).value.value : this.form.get(key).value;
      }
    } else {
      return this.setAnalyticsFilter(key, isUseValue);
    }
  }

  private setAnalyticsFilter(key: string, isUseValue?: boolean) {
    const filterMap = {
        projects: () => this.analyticsFilter.projectIdsAsCsv,
        address: () => this.analyticsFilter.addressId,
        products: () => this.analyticsFilter.productName,
        pendCodes: () => this.getPendCodes(isUseValue),
        pendStatus: () => this.analyticsFilter.pendStatusCsv,
        hcc: () => this.analyticsFilter.hccAsCsv,
        [this.workflowStatusInput.key]: () => this.analyticsFilter.workflowStatusCsv,
        [this.chaseIdsInput.key]: () => this.analyticsFilter.reportChaseID,
        [this.conditionCategoryInput.key]: () => this.analyticsFilter.conditionCategoryAsCsv,
        [this.clientsInput.key]: () => this.analyticsFilter.clientIdsAsCsv,
        [this.pendOwnerInput.key]: () => this.analyticsFilter.pendOwnerAsCsv,
        [this.paymentYearInput.key]: () => this.analyticsFilter.paymentYear,
        [this.serviceYearInput.key]: () => this.analyticsFilter.serviceYear,
        [this.rafCalcSourceInput.key]: () => this.analyticsFilter.rafCalcSource,
        [this.lineOfBusinessInput.key]: () => this.analyticsFilter.lineOfBusinessAsCsv,
        [this.retrievalOwnerInput.key]: () => this.analyticsFilter.retrievalOwner,
        [this.fileCreateDateFromInput.key]: () => this.analyticsFilter.fileCreateFromDate,
        [this.fileCreateDateToInput.key]: () => this.analyticsFilter.fileCreateToDate,
        [this.documentStatus.key]: () => this.analyticsFilter.documentStatusAsCsv,
        [this.chaseTags.key]: () => this.analyticsFilter.chaseTags,
        [this.userStatus.key]: () => this.analyticsFilter.userStatus,
        [this.analyticsFilter.vendorInvoiceType]: () => this.vendorInvoiceType.key,
        [this.pendTypeInput.key]: () => this.analyticsFilter.pendTypeAsCsv,
    };
    return filterMap[key] ? filterMap[key]() : this.analyticsFilter[key];
}
  private getPendCodes(isUseValue?: boolean) {
    return isUseValue ? this.analyticsFilter.pendTypeIdCsv : this.analyticsFilter.pendCodeCsv;
}
  private getVrcCode(): void {
    this.memberManagementService
      .getVrcCodes()
      .subscribe(options => {
        this.vrcCodeInput = new Autocomplete({ ...this.vrcCodeInput, options } as any);
        this.setVrcCodeInput();
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }


  private getCoderNameList(): void {
    this.memberManagementService
      .getChaseQAList(this.coderName)
      .subscribe(options => {
        const coderName = options.map(item => new SelectableInput({
          text: item.text,
          value: item.text,
        }));
        const coderNameId = options.map(item => new SelectableInput({
          text: item.value.toString(),
          value: item.value,
        }));
        this.coderNameInput = new Autocomplete({ ...this.coderNameInput, options: coderName } as any);
        this.coderIdInput = new Autocomplete({ ...this.coderIdInput, options: coderNameId } as any);
        this.setCoderIdInput();
        this.setCoderNameInput();
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }

  private getOverReaderNameList(): void {
    this.memberManagementService
      .getChaseQAList(this.overReaderName)
      .subscribe(options => {
        const overReaderName = options.map(item => new SelectableInput({
          text: item.text,
          value: item.text,
        }));
        const overReaderId = options.map(item => new SelectableInput({
          text: item.value.toString(),
          value: item.value,
        }));
        this.overReaderNameInput = new Autocomplete({ ...this.overReaderNameInput, options: overReaderName } as any);
        this.overReaderIdInput = new Autocomplete({ ...this.overReaderIdInput, options: overReaderId } as any);
        this.setOverReaderIdInput();
        this.setOverReaderNameInput();
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }

  private getWorkflowStatus(): void {
    this.bulkUpdateChaseService
      .getWorkflowStatusesList()
      .subscribe(result => {
        const workflowStatus = result.map(item => new SelectableInput({
          text: item.workflowStatusName,
          value: item.workflowStatusName,
        }));
        this.workflowStatusInput = { ...this.workflowStatusInput, options: workflowStatus } as any;
        this.setWorkFlowStatusControlValue(this.workflowStatusInput);
        this.currentProcessStep = new CheckboxGroup({ ...this.currentProcessStep, options: workflowStatus } as any);
        this.setControlValue("currentProcessStep", this.currentProcessStep);
        this.formService.updateDom.next();
      });

  }


  private setCoderIdInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.coderId)) {
      const optionsValue = this.coderIdInput.options.find(a => a.value === this.analyticsFilter.coderId);
      this.coderIdInput.value = [optionsValue];
      this.form.get(this.coderIdInput.key).setValue(this.coderIdInput.value[0]);
    }
  }
  private setCoderNameInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.coderName)) {
      const optionsValue = this.coderNameInput.options.find(a => a.value === this.analyticsFilter.coderName);
      this.coderNameInput.value = [optionsValue];
      this.form.get(this.coderNameInput.key).setValue(this.coderNameInput.value[0]);
    }
  }

  private setOverReaderNameInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.overReaderName)) {
      const optionsValue = this.overReaderNameInput.options.find(a => a.value === this.analyticsFilter.overReaderName);
      this.overReaderNameInput.value = [optionsValue];
      this.form.get(this.overReaderNameInput.key).setValue(this.overReaderNameInput.value[0]);
    }
  }

  private setOverReaderIdInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.overReaderId)) {
      const optionsValue = this.overReaderIdInput.options.find(a => a.value === this.analyticsFilter.overReaderId);
      this.overReaderIdInput.value = [optionsValue];
      this.form.get(this.overReaderIdInput.key).setValue(this.overReaderIdInput.value[0]);
    }
  }
  private setVrcCodeInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.vrcCode)) {
      const optionsValue = this.vrcCodeInput.options.find(a => a.value === this.analyticsFilter.vrcCode);
      this.vrcCodeInput.value = [optionsValue];
      this.form.get(this.vrcCodeInput.key).setValue(this.vrcCodeInput.value[0]);
    }
  }
  private setWorkFlowStatusControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.workflowStatusCsv)) {
      const workFlowStatusFilteredValues = this.analyticsFilter.workflowStatusCsv.split(",");
      workFlowStatusFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.workflowStatusInput.options)) {
          filterValue.push(this.workflowStatusInput.options.find(a => a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.workflowStatusInput.key).setValue(control.selectedOptions);
    }
  }

  onOrganizationChange(): void {
    this.loadClientOrProjectControl();
  }
  loadClientOrProjectControl(): void {
    if (this.filters.includes("Client")) {
      this.getClients();
    } else {
      this.getProjects();
    }
  }

  onClientChange(): void {
    this.getProjects();
  }

  onProjectChange(): void {
    if (this.isParentOrg) {
      this.getMeasuresByProject();
    }
    this.getContractNumbers();
  }

  onInputChange(inputKey: string, message: string): void {
    const input = this.form.get(inputKey);
    if (input?.value?.length > 1) {
      input.value.pop();
      this.messagingService.showToast(`You can only select one ${message}`, SeverityType.ERROR);
    }
  }
  private getMeasuresByProject(): void {
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedProjectIds)) {
      this.genericGet(this.analyticsService, "getMeasuresList", options => {
        const result = options.map(option => new Object({ text: option.measureCode, value: option.measureCode }));
        this.bindMeasureList(result);
      },              selectedProjectIds);
    }
  }

  private bindMeasureList(options: any) {
    this.measuresInput = new CheckboxGroup({ ...this.measuresInput, options } as any);
    this.setControlValue("measureIdsAsCsv", this.measuresInput);
    this.formService.updateDom.next();
  }
  private getContractNumbers(): void {
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    const request = new EntityAttribute({
      projectIdsAsCsv: selectedProjectIds,
      projectTypeId: ProjectType.HEDIS,
      attributeId: CONTRACT_NUMBER.attributeId,
    });
    this.analyticsService.getEntityAttributes(request).pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.contractNumberInput = new CheckboxGroup({ ...this.contractNumberInput, options } as any);
        this.setControlValue("contractNumbersAsCsv", this.contractNumberInput);
        this.formService.updateDom.next();
      });
  }

  updateMinDate(fromDate: Calendar, toDate: Calendar): void {
    this.moveDateEnd = new Calendar({ ...toDate, minDate: new Date(this.form.get(fromDate.key).value) });
    this.changeDetector.markForCheck();
    this.formService.updateDom.next();
  }

  showHelpModal(): void {
    this.isHelpModalOpen = true;
  }
}
