import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { AnalyticsFilterAppliedComponent } from "./analytics-filter-applied/analytics-filter-applied.component";
import { AnalyticsHedisComponent } from "./analytics-hedis/analytics-hedis.component";
import { AnalyticsIvaComponent } from "./analytics-iva/analytics-iva.component";
import { AnalyticsMenuHeaderComponent } from "./analytics-menu-header/analytics-menu-header.component";
import { AnalyticsReportLandingComponent } from "./analytics-report-landing/analytics-report-landing.component";

import { AnalyticsInstrucionsModalComponent } from "./analytics-instrucions-modal/analytics-instrucions-modal.component";
import { AnalyticsMemberManagementComponent } from "./analytics-member-management/analytics-member-management.component";
import { MemberOversightDashboardComponent } from "./analytics-member-management/member-oversight-dashboard/member-oversight-dashboard.component";
import { AidTagHistoryTrackingComponent } from "./analytics-risk/aid-tag-history-tracking/aid-tag-history-tracking.component";
import { AnalyticsRiskOverviewDwComponent } from "./analytics-risk/analytics-risk-overview-dw/analytics-risk-overview-dw.component";
import { AnalyticsRiskOverviewComponent } from "./analytics-risk/analytics-risk-overview/analytics-risk-overview.component";
import { AnalyticsRiskComponent } from "./analytics-risk/analytics-risk.component";
import { AnnotationsReportDWComponent } from "./analytics-risk/annotations-report-dw/annotations-report-dw.component";
import { AutoCloseReportComponent } from "./analytics-risk/auto-close-report/auto-close-report.component";
import { CallCountDetailReportLegacyComponent } from "./analytics-risk/call-count-detail-report-legacy/call-count-detail-report-legacy.component";
import { CallCountDetailReportComponent } from "./analytics-risk/call-count-detail-report/call-count-detail-report.component";
import { ChartRoutingDwComponent } from "./analytics-risk/chart-routing-dw/chart-routing-dw.component";
import { ChartRoutingComponent } from "./analytics-risk/chart-routing/chart-routing.component";
import { ChaseCountsByStatusReportComponent } from "./analytics-risk/chase-counts-by-status-report/chase-counts-by-status-report.component";
import { ChaseMoveBackHistoryReportLegacyComponent } from "./analytics-risk/chase-move-back-history-report-legacy/chase-move-back-history-report-legacy.component";
import { ChaseMoveBackHistoryReportComponent } from "./analytics-risk/chase-move-back-history-report/chase-move-back-history-report.component";
import { ChaseMoveBackReportComponent } from "./analytics-risk/chase-move-back-report/chase-move-back-report.component";
import { ChaseMoveReportDwComponent } from "./analytics-risk/chase-move-report-dw/chase-move-report-dw.component";
import { ChaseMoveReportComponent } from "./analytics-risk/chase-move-report/chase-move-report.component";
import { ChaseStatusUnderlyingReportDWComponent } from "./analytics-risk/chase-status-underlying-report-dw/chase-status-underlying-report-dw.component";
import { ChaseStatusUnderlyingComponent } from "./analytics-risk/chase-status-underlying/chase-status-underlying.component";
import { ClientOverread2DWComponent } from "./analytics-risk/client-overread2-dw/client-overread2-dw.component";
import { ClientOverread2Component } from "./analytics-risk/client-overread2/client-overread2.component";
import { ClinicalAccuracyByChaseWithErrorDetailLegacyComponent } from "./analytics-risk/clinical-accuracy-by-chase-with-error-detail-legacy/clinical-accuracy-by-chase-with-error-detail-legacy.component";
import { ClinicalAccuracyByChaseWithErrorDetailComponent } from "./analytics-risk/clinical-accuracy-by-chase-with-error-detail/clinical-accuracy-by-chase-with-error-detail.component";
import { ClinicalAccuracyByMeasureComponent } from "./analytics-risk/clinical-accuracy-by-measure/clinical-accuracy-by-measure.component";
import { ClinicalProductionInventoryAndQaScoresComponent } from "./analytics-risk/clinical-production-inventory-and-qa-scores/clinical-production-inventory-and-qa-scores.component";
import { ClinicalProductivityAccuracyOverallDWComponent } from "./analytics-risk/clinical-productivity-accuracy-overall-dw/clinical-productivity-accuracy-overall-dw.component";
import { ClinicalProductivityAndAccuracyOverallComponent } from "./analytics-risk/clinical-productivity-and-accuracy-overall/clinical-productivity-and-accuracy-overall.component";
import { ClinicalProductivityOverviewComponent } from "./analytics-risk/clinical-productivity-overview/clinical-productivity-overview.component";
import { ClinicalProductivityReportDWComponent } from "./analytics-risk/clinical-productivity-report-dw/clinical-productivity-report-dw.component";
import { ClinicalProductivityComponent } from "./analytics-risk/clinical-productivity/clinical-productivity.component";
import { ClinicalSummaryComponent } from "./analytics-risk/clinical-summary/clinical-summary.component";
import { CoderProductivityDWComponent } from "./analytics-risk/coder-productivity-dw/coder-productivity-dw.component";
import { CoderProductivityComponent } from "./analytics-risk/coder-productivity/coder-productivity.component";
import { CodingDurationReportDwComponent } from "./analytics-risk/coding-duration-report-dw/coding-duration-report-dw.component";
import { CodingDurationReportComponent } from "./analytics-risk/coding-duration-report/coding-duration-report.component";
import { ConditionCategorQaReportDwComponent } from "./analytics-risk/condition-categor-qa-report-dw/condition-categor-qa-report-dw.component";
import { ConditionCategoryQaReportComponent } from "./analytics-risk/condition-category-qa-report/condition-category-qa-report";
import { ConfidenceScoreReportComponent } from "./analytics-risk/confidence-score-report/confidence-score-report.component";
import { CustomerInvoiceDetailComponent } from "./analytics-risk/customer-invoice-detail/customer-invoice-detail.component";
import { DataLoadErrorReportComponent } from "./analytics-risk/data-load-error-report/data-load-error-report.component";
import { DeletesTrackingDwComponent } from "./analytics-risk/deletes-tracking-dw/deletes-tracking-dw.component";
import { DeletesTrackingComponent } from "./analytics-risk/deletes-tracking/deletes-tracking.component";
import { DetailClinicalProductivityAndAccuracyOverallComponent } from "./analytics-risk/detail-clinical-productivity-and-accuracy-overall/detail-clinical-productivity-and-accuracy-overall.component";
import { DetailedAuditReportComponent } from "./analytics-risk/detailed-audit-report/detailed-audit-report.component";
import { DiagnosisByHccDwComponent } from "./analytics-risk/diagnosis-by-hcc-dw/diagnosis-by-hcc-dw.component";
import { DiagnosisByHccComponent } from "./analytics-risk/diagnosis-by-hcc/diagnosis-by-hcc.component";
import { DiagnosisDataMultipleModelsDWComponent } from "./analytics-risk/diagnosis-data-multiple-models-dw/diagnosis-data-multiple-models-dw.component";
import { DobDiscrepancyDWComponent } from "./analytics-risk/dob-discrepancy-dw/dob-discrepancy-dw.component";
import { DobDiscrepancyComponent } from "./analytics-risk/dob-discrepancy/dob-discrepancy.component";
import { FtDailySummaryComponent } from "./analytics-risk/ft-daily-summary/ft-daily-summary.component";
import { GapStatusComponent } from "./analytics-risk/gap-status/gap-status.component";
import { HccByDosComponent } from "./analytics-risk/hcc-by-dos/hcc-by-dos.component";
import { HccSummaryByAidDwComponent } from "./analytics-risk/hcc-summary-by-aid-dw/hcc-summary-by-aid-dw.component";
import { HccSummaryByAidComponent } from "./analytics-risk/hcc-summary-by-aid/hcc-summary-by-aid.component";
import { HccSummaryByClaimIdComponent } from "./analytics-risk/hcc-summary-by-claim-id/hcc-summary-by-claim-id.component";
import { HccSummaryByMemberDwComponent } from "./analytics-risk/hcc-summary-by-member-dw/hcc-summary-by-member-dw.component";
import { HccSummaryByMemberComponent } from "./analytics-risk/hcc-summary-by-member/hcc-summary-by-member.component";
import { HccSummaryByProjectDwComponent } from "./analytics-risk/hcc-summary-by-project-dw/hcc-summary-by-project-dw.component";
import { HccSummaryByProjectComponent } from "./analytics-risk/hcc-summary-by-project/hcc-summary-by-project.component";
import { HccSummaryByProviderDwComponent } from "./analytics-risk/hcc-summary-by-provider-dw/hcc-summary-by-provider-dw.component";
import { HccSummaryByProviderComponent } from "./analytics-risk/hcc-summary-by-provider/hcc-summary-by-provider.component";
import { HccSummaryReportDwComponent } from "./analytics-risk/hcc-summary-report-dw/hcc-summary-report-dw.component";
import { HccTrackingReportComponent } from "./analytics-risk/hcc-tracking-report/hcc-tracking-report.component";
import { HistoricalStatusIndicatorsDWReportComponent } from "./analytics-risk/historical-status-indicators-dwreport/historical-status-indicators-dwreport.component";
import { HistoricalStatusIndicatorsReportComponent } from "./analytics-risk/historical-status-indicators-report/historical-status-indicators-report.component";
import { IntakeProductivityLegacyComponent } from "./analytics-risk/intake-productivity-legacy/intake-productivity-legacy.component";
import { IntakeProductivityComponent } from "./analytics-risk/intake-productivity/intake-productivity.component";
import { LifeOfAChaseComponent } from "./analytics-risk/life-of-a-chase/life-of-a-chase.component";
import { LifeOfAChaseDWComponent } from "./analytics-risk/life-of-achase-dw/life-of-achase-dw.component";
import { ManaullyCreatedChasesReportComponent } from "./analytics-risk/manually_created_chases_report/manually-created-chases-report.component";
import { MemberAdditionalDetailsComponent } from "./analytics-risk/member-additional-details/member-additional-details.component";
import { MemberChaseDwComponent } from "./analytics-risk/member-chase-dw/member-chase-dw.component";
import { MemberChaseComponent } from "./analytics-risk/member-chase/member-chase.component";
import { OpenGapsByMeasureComponent } from "./analytics-risk/open-gaps-by-measure/open-gaps-by-measure.component";
import { OpenGapsByMemberComponent } from "./analytics-risk/open-gaps-by-member/open-gaps-by-member.component";
import { PendReportDWComponent } from "./analytics-risk/pend-report-dw/pend-report-dw.component";
import { PendReportComponent } from "./analytics-risk/pend-report/pend-report.component";
import { PendsSummaryDwComponent } from "./analytics-risk/pends-summary-dw/pends-summary-dw.component";
import { PendsSummaryComponent } from "./analytics-risk/pends-summary/pends-summary.component";
import { PersistencyDWReportComponent } from "./analytics-risk/persistency-dwreport/persistency-dwreport.component";
import { PersistencyReportComponent } from "./analytics-risk/persistency-report/persistency-report.component";
import { ProjectStatusReportDwComponent } from "./analytics-risk/project-status-report-dw/project-status-report-dw/project-status-report-dw.component";
import { ProjectStatusReportComponent } from "./analytics-risk/project-status-report/project-status-report.component";
import { ProjectSummaryDwComponent } from "./analytics-risk/project-summary-dw/project-summary-dw.component";
import { ProjectSummaryComponent } from "./analytics-risk/project-summary/project-summary.component";
import { ProviderGatewayActivityComponent } from "./analytics-risk/provider-gateway-activity/provider-gateway-activity.component";
import { ProviderOutreachByContactTypeReportComponent } from "./analytics-risk/provider-outreach-by-contact-type-report/provider-outreach-by-contact-type-report.component";
import { ProviderOutreachByContactTypeComponent } from "./analytics-risk/provider-outreach-by-contact-type/provider-outreach-by-contact-type.component";
import { ProviderStatusDwComponent } from "./analytics-risk/provider-status-dw/provider-status-dw.component";
import { ProviderStatusComponent } from "./analytics-risk/provider-status/provider-status.component";
import { ProviderValidationComponent } from "./analytics-risk/provider-validation/provider-validation.component";
import { RetrievalReportByChaseComponent } from "./analytics-risk/retrieval-report-by-chase/retrieval-report-by-chase.component";
import { RetrievalReportComponent } from "./analytics-risk/retrieval-report/retrieval-report.component";
import { RetrievalSummaryDwComponent } from "./analytics-risk/retrieval-summary-dw/retrieval-summary-dw.component";
import { RetrievalSummaryComponent } from "./analytics-risk/retrieval-summary/retrieval-summary.component";
import { RiskClinicalSummaryComponent } from "./analytics-risk/risk-clinical-summary/risk-clinical-summary.component";
import { RiskNLPDashboardOverviewComponent } from "./analytics-risk/risk-nlp-dashboard-overview/risk-nlp-dashboard-overview.component";
import { RiskNLPICDDashboardOverviewComponent } from "./analytics-risk/risk-nlp-icd-dashboard-overview/risk-nlp-icd-dashboard-overview.component";
import { RiskNLPICDDrilldownComponent } from "./analytics-risk/risk-nlp-icd-drilldown/risk-nlp-icd-drilldown.component";
import { RiskProjectHealthComponent } from "./analytics-risk/risk-project-health/risk-project-health.component";
import { RiskScoreTrendComponent } from "./analytics-risk/risk-score-trend/risk-score-trend.component";
import { RoiDWComponent } from "./analytics-risk/roi-dw/roi-dw.component";
import { RoiComponent } from "./analytics-risk/roi/roi.component";
import { SftpReportDwComponent } from "./analytics-risk/sftp-report-dw/sftp-report-dw.component";
import { SftpReportComponent } from "./analytics-risk/sftp-report/sftp-report.component";
import { SlaMilestoneReportDwComponent } from "./analytics-risk/sla-milestone-report-dw/sla-milestone-report-dw.component";
import { StalledDocumentReportDwComponent } from "./analytics-risk/stalled-document-report-dw/stalled-document-report-dw.component";
import { StalledDocumentsReportComponent } from "./analytics-risk/stalled-documents-report/stalled-documents-report.component";
import { SuspectingComponent } from "./analytics-risk/suspecting/suspecting.component";
import { TopAidsWithOpenChasesComponent } from "./analytics-risk/top-aids-with-open-chases/top-aids-with-open-chases.component";
import { Top50AidsWithOpenChasesComponent } from "./analytics-risk/top50-aids-with-open-chases/top50-aids-with-open-chases.component";
import { TrainingFeedbackDwComponent } from "./analytics-risk/training-feedback-dw/training-feedback-dw.component";
import { TrainingFeedbackComponent } from "./analytics-risk/training-feedback/training-feedback.component";
import { UserProductivityDashboardComponent } from "./analytics-risk/user-productivity-dashboard/user-productivity-dashboard.component";
import { UserProductivityComponent } from "./analytics-risk/user-productivity/user-productivity.component";
import { ValidationReasonCodeComponent } from "./analytics-risk/validation-reason-code/validation-reason-code.component";
import { ValidationReasonsCodeReportDwComponent } from "./analytics-risk/validation-reasons-code-report-dw/validation-reasons-code-report-dw.component";
import { AnalyticsRoutingModule } from "./analytics-routing.module";
import { UtcTimeZoneComponent } from "./utc-time-zone/utc-time-zone.component";


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AnalyticsRoutingModule,
    ],
    declarations: [
        AnalyticsRiskComponent,
        AnalyticsHedisComponent,
        AnalyticsMenuHeaderComponent,
        AnalyticsRiskOverviewComponent,
        HccSummaryByClaimIdComponent,
        HccSummaryByAidComponent,
        HccSummaryByProviderComponent,
        HccSummaryByProjectComponent,
        UserProductivityComponent,
        HccSummaryByMemberComponent,
        DiagnosisByHccComponent,
        ProjectSummaryComponent,
        ProviderStatusComponent,
        MemberChaseComponent,
        LifeOfAChaseComponent,
        ChaseStatusUnderlyingComponent,
        AnalyticsFilterAppliedComponent,
        ValidationReasonCodeComponent,
        AnalyticsReportLandingComponent,
        ClinicalSummaryComponent,
        RetrievalSummaryComponent,
        GapStatusComponent,
        OpenGapsByMeasureComponent,
        OpenGapsByMemberComponent,
        MemberAdditionalDetailsComponent,
        AnalyticsIvaComponent,
        ClinicalProductivityOverviewComponent,
        ClinicalAccuracyByMeasureComponent,
        ProjectStatusReportComponent,
        Top50AidsWithOpenChasesComponent,
        PendReportComponent,
        ClinicalProductivityAndAccuracyOverallComponent,
        RiskProjectHealthComponent,
        ConditionCategoryQaReportComponent,
        RetrievalReportComponent,
        CoderProductivityComponent,
        IntakeProductivityComponent,
        TrainingFeedbackComponent,
        ClinicalProductivityComponent,
        FtDailySummaryComponent,
        AutoCloseReportComponent,
        ClientOverread2Component,
        CallCountDetailReportComponent,
        ProviderOutreachByContactTypeComponent,
        RetrievalReportByChaseComponent,
        SftpReportComponent,
        ProviderValidationComponent,
        ClinicalAccuracyByChaseWithErrorDetailComponent,
        RiskNLPDashboardOverviewComponent,
        RiskNLPICDDashboardOverviewComponent,
        RiskNLPICDDrilldownComponent,
        ManaullyCreatedChasesReportComponent,
        DetailClinicalProductivityAndAccuracyOverallComponent,
        ClinicalProductionInventoryAndQaScoresComponent,
        ChaseMoveBackReportComponent,
        ChaseMoveBackHistoryReportComponent,
        PendsSummaryComponent,
        DobDiscrepancyComponent,
        SuspectingComponent,
        UtcTimeZoneComponent,
        PersistencyReportComponent,
        HistoricalStatusIndicatorsReportComponent,
        RoiComponent,
        CustomerInvoiceDetailComponent,
        ChartRoutingComponent,
        ConfidenceScoreReportComponent,
        CallCountDetailReportLegacyComponent,
        ChaseMoveBackHistoryReportLegacyComponent,
        ClinicalAccuracyByChaseWithErrorDetailLegacyComponent,
        IntakeProductivityLegacyComponent,
        HistoricalStatusIndicatorsDWReportComponent,
        RoiDWComponent,
        PersistencyDWReportComponent,
        CodingDurationReportComponent,
        StalledDocumentsReportComponent,
        ProviderGatewayActivityComponent,
        CoderProductivityDWComponent,
        ClientOverread2DWComponent,
        ChaseMoveReportComponent,
        ClinicalProductivityAccuracyOverallDWComponent,
        DeletesTrackingComponent,
        ClinicalProductivityReportDWComponent,
        UserProductivityDashboardComponent,
        ChaseCountsByStatusReportComponent,
        DetailedAuditReportComponent,
        PendReportDWComponent,
        AidTagHistoryTrackingComponent,
        DobDiscrepancyDWComponent,
        ChaseStatusUnderlyingReportDWComponent,
        LifeOfAChaseDWComponent,
        DiagnosisDataMultipleModelsDWComponent,
        AnnotationsReportDWComponent,
        DataLoadErrorReportComponent,
        AnalyticsInstrucionsModalComponent,
        ProjectStatusReportDwComponent,
        SlaMilestoneReportDwComponent,
        ValidationReasonsCodeReportDwComponent,
        PendsSummaryDwComponent,
        DeletesTrackingDwComponent,
        HccSummaryReportDwComponent,
        ConditionCategorQaReportDwComponent,
        DiagnosisByHccDwComponent,
        HccSummaryByAidDwComponent,
        HccSummaryByProviderDwComponent,
        MemberChaseDwComponent,
        HccSummaryByMemberDwComponent,
        ProjectSummaryDwComponent,
        AnalyticsRiskOverviewDwComponent,
        ProviderStatusDwComponent,
        HccSummaryByProjectDwComponent,
        RetrievalSummaryDwComponent,
        SftpReportDwComponent,
        StalledDocumentReportDwComponent,
        TopAidsWithOpenChasesComponent,
        RiskClinicalSummaryComponent,
        HccByDosComponent,
        ProviderOutreachByContactTypeReportComponent,
        ChartRoutingDwComponent,
        CodingDurationReportDwComponent,
        TrainingFeedbackDwComponent,
        ChaseMoveReportDwComponent,
        RiskScoreTrendComponent,
        HccTrackingReportComponent,
        AnalyticsMemberManagementComponent,
        MemberOversightDashboardComponent,
    ],

})
export class AnalyticsModule { }
