import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-pend-report-dw",
  templateUrl: "./pend-report-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendReportDWComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.PEND_REPORT_DW;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects"];
    this.hideFilters = [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID];
  }

}
