export enum RiskDxActionType {
  /**
   * Valid action
   */
  Valid = 0,

  /**
   * Invalid action
   */
  Invalid = 1,

  /**
   * Reset All action
   */
  ResetAll = 2,

  /**
   * Reset All coder's action to original state
   */
  ResetAllCoderActions = 3,
}
