import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "../../../shared/button/button.module";
import { ToolTipModule } from "../../../shared/tool-tip/tool-tip.module";
import { TextBoxWithButtonComponent } from "./textbox-with-button.component";

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ToolTipModule,
  ],
  declarations: [
    TextBoxWithButtonComponent,
  ],
  entryComponents: [
    TextBoxWithButtonComponent,
  ],
  exports: [
    TextBoxWithButtonComponent,
  ],
})
export class TextboxWithButtonModule { }
