import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { GridService } from "../grid.service";
import { GridRequest } from "../models/grid-request.model";
import { GridMenuDirective } from "./grid-menu-component.model";

interface IGridFilterKeyValue {
  key: string;
  value: string;
  inputValue: any;
}

@Component({
  selector: "app-server-grid-menu",
  templateUrl: "./grid-menu.component.html",
  styleUrls: ["./grid-menu.component.scss"],
})
export class ServerGridMenuComponent extends GridMenuDirective implements OnInit, OnChanges {
  @Input() request: GridRequest;
  @Input() filterHeaderText = "";
  @Input() filterFooterText = "";
  @Input() isMemberFilterValid = true;
  @Input() totalRecords = 0;
  @Input() paginationText = "";
  @Input() isFirstPage = false;
  @Input() isMemberQuery = false;
  @Input() onlyChartsInput = false;
  @Input() isLastPage = false;
  @Input() exportDataFromUrl = false;
  @Output() requestChange = new EventEmitter<GridRequest>(true);
  @Output() navigationChange = new EventEmitter<boolean>();
  @Output() memberChartEmit: EventEmitter<boolean> = new EventEmitter();
  filterCache: IGridFilterKeyValue[];
  @Input() isSaveQueryVisible = false;
  @Input() displayCheckedItemsInGridView: { value: string; disabled: boolean }[] = [];
  private readonly EXCEL_CELL_MAXIMUM_LIMIT = 32000;
  @Input() isExcelExportDuplicateRow = false;
  @Input() excelExportExpandColumnName = "";
  constructor(
    service: GridService,
    private changeDetector: ChangeDetectorRef,
    private http: HttpClient
  ) {
    super(service);
  }

  ngOnInit() {
    this.filterCache = this.gridFilterkeyValueList;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.request) {
      if (changes.request.isFirstChange()) { return; }

      if (changes.request.currentValue !== changes.request.previousValue) {
        this.filterCache = this.gridFilterkeyValueList;
      }
    }
  }

  get gridFilterkeyValueList(): IGridFilterKeyValue[] {
    return this.request.filters.map(x => {
      return { key: x.key, value: x.value, inputValue: x.inputValue };
    });
  }

  get filterForm(): FormGroup {
    return this.request == null ? null : this.request.form;
  }

  get isFilterFormValid(): boolean {
    return this.request.filters.some(x => x.hasValue && x.show) && this.isMemberFilterValid;
  }

  get displayPageControls(): boolean {
    return !this.configuration.isStateDisabled && this.totalRecords !== 0;
  }

  sortGrid(): void {
    this.request.sortField = this.sortField;
    this.request.sortDirection = this.isAscending;
    this.refreshGrid();
  }

  filterGrid(): void {
    this.refreshGrid();
  }

  export(): void {
    if (this.request != null && (!this.hasSelectedItems || this.exportDataFromUrl)) {
      this.exportFromRequest();
    } else {
      this.primeGrid.exportCSV({ selectionOnly: this.hasSelectedItems });
    }
    this.selectedItems = [];
    this.primeGrid.selectionChange.emit(this.selectedItems);
  }

  clearUnusedFilters(): void {
    if (!this.isSaveQueryVisible) {
      this.request.filters.forEach(x => {
        const index = this.filterCache.findIndex(o => o.key === x.key);

        if (index !== -1) {
          x.value = this.filterCache[index].value;
          x.inputValue = this.filterCache[index].inputValue;
        }
      });
    }
  }

  clearFilters(): void {
    this.request.resetFilters();
  }

  closeFilters(): void {
    if (this.request.form.invalid) {
      return;
    }

    this.filterCache = this.gridFilterkeyValueList;
    this.primeGrid.reset();
    super.closeFilters();
  }

  navigatePage(direction: boolean): void {
    this.navigationChange.emit(direction);
  }

  private refreshGrid(): void {
    this.request = new GridRequest(this.request);
    this.requestChange.emit(this.request);
  }

  private exportFromRequest(): void {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = this.request.url;
    const options = this.request.createRequestObject();

    if (url.includes("chase/query") && this.hasSelectedItems) {
      options.chaseIds = this.selectedItems.map(s => s.chaseId);
    }

    options.startRecord = 0;
    options.endRecord = 1000000;
    options.isExportAll = true;
    options.localTimeZone = localTimeZone;
    this.primeGrid.loading = true;
    options.localTimeZone = localTimeZone;
    this.changeDetector.markForCheck();
    this.http
      .post(url, options)
      .pipe(finalize(() => {
        this.primeGrid.loading = false;
        this.changeDetector.markForCheck();
      }))
      .subscribe((data: any) => {
        const savePrevData = this.primeGrid.value;

        // To avoid malicious code in CSV
        data.map((obj, index) => {
          Object.entries(obj).map(([key, val]) => {
            if (val[0] === ("=" || "+" || "-" || "@")) {
              obj[key] = `${" "}${val}`;
            }
            if (this.isExcelExportDuplicateRow && key === this.excelExportExpandColumnName && typeof val === "string" && val.length > this.EXCEL_CELL_MAXIMUM_LIMIT) {
              const duplicateRow = { ...obj }; // Create a copy of the object
              let start = val.lastIndexOf(",", this.EXCEL_CELL_MAXIMUM_LIMIT); // Find the nearest comma before the substring
              if (start === -1) { start = 0; }
              // Extract the substring to keep in the original key
              obj[key] = val.substring(0, start);
              // Extract the substring to store in the new key
              duplicateRow[key] = ` ${val.substring(start + 1)}`;
              data.splice(index + 1, 0, duplicateRow);
            }

          });
        });

        this.primeGrid.value = ArrayHelper.isAvailable(data) ? data : [];
        this.primeGrid.exportCSV();
        this.primeGrid.value = savePrevData;
      });
  }
  memberChart(event: boolean) {
    this.memberChartEmit.emit(event);
  }
}
