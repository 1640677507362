import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { LocalService } from "../../../../core/storage/local.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { CreatePendService } from "../../../../shared/pend/create-pend.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { CONTRACT_NUMBER, PLAN_ID, PRODUCT_LIST } from "../../member/chase-detail/chase-detail-chart/attributes";
import { BulkUpdateChaseService } from "../../project/bulk-updates/bulk-update-chase/bulk-update-chase.service";
import { ChaseQueryService } from "../../project/chase-query/chase-query.service";
import { ProjectType } from "../../project/project-type.enum";
import { Project } from "../../project/project.model";
import { RetrievalDocumentServiceService } from "../../retrieval/retrieval-document-service.service";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../service-org-admin/service-org-config/service-org-config.service";
import { AnalyticsRequest } from "../analytics-request.model";
import { AnalyticsRiskService } from "../analytics-risk/analytics-risk.service";
import { AnalyticsService } from "../analytics.service";
import { ContactMethodType } from "../contact-method-type.enum";
import { LookerIvaDashboardType } from "../looker-iva-dashboard-type.enum";
import { AnalyticsItemRequest } from "../models/analytics-item-request.model";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { DatePipe } from "@angular/common";
import { UserToken } from "../../../../auth/user-token.model";
import { NewProjectService } from "../../project/new-project/new-project.service";
import { HideFilters } from "../models/analytics-hide-filters.enum";
import { SharedFilters } from "../analytics-shared-filters.class";
import { VendorDetail } from "../../invoice/vendor-detail/vendor-detail.model";
import { AddressDetailInfoEditService } from "../../retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.service";
import { AddressDetailInfoService } from "../../retrieval/psr/address-detail/address-detail-info/address-detail-info.service";
import { EntityAttribute } from "../entity-attribute.model";

@Component({
  selector: "app-analytics-iva",
  templateUrl: "./analytics-iva.component.html",
  styleUrls: ["./analytics-iva.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class AnalyticsIvaComponent extends SharedFilters implements OnInit, AfterViewInit {
  projectsInput: CheckboxGroup;
  measuresInput: CheckboxGroup;
  form: FormGroup;
  ivaProjectTypeId = ProjectType.IVA;
  isVisible = false;
  lookerUrl: string;
  isFiltersVisible = false;
  dashboardType: string;
  projectIdsAsCsv: string;
  measureIdsAsCsv: string;
  addressId: string;
  formFilterRequest: FormGroup;
  menuItems = List<MenuItem>();
  @Output() removeFilter = new EventEmitter<FormGroup>();
  analyticsFilter: AnalyticsRequest;
  pFormGroupKey: string;
  isFilterUpdated = false;
  filters = ["Project", "Measure"];
  pendCodesInput: CheckboxGroup;
  pendStatusInput: CheckboxGroup;
  pendCreateDateFrom: Calendar;
  pendCreateDateTo: Calendar;
  abstractionFromDate: Calendar;
  abstractionToDate: Calendar;
  overread2FromDate: Calendar;
  overread2ToDate: Calendar;
  overreadFromDate: Calendar;
  overreadToDate: Calendar;
  chartReceivedDateFromInput: Textbox;
  chartReceivedDateToInput: Textbox;
  completionFromDate: Textbox;
  completionToDate: Textbox;
  workflowStatusInput: CheckboxGroup;
  updateDateFrom: Calendar;
  updateDateTo: Calendar;
  retrievalTypeInput: CheckboxGroup;
  fileCreateDateFromInput: Textbox;
  fileCreateDateToInput: Textbox;
  retrievalFromDate: Textbox;
  retrievalToDate: Textbox;
  startDateInput: Calendar;
  endDateInput: Calendar;
  enrolleeIdInput: Textbox;
  memberIdInput: Textbox;
  clientMemberIdInput: Textbox;
  claimIdInput: Textbox;
  dispensingProviderIdInput: Textbox;
  ndcInput: Textbox;
  hcpcsInput: Textbox;
  chaseIdInput: Textbox;
  stratumLevelInput: CheckboxGroup;
  rxcInput: CheckboxGroup;
  submissionStatusInput: CheckboxGroup;
  hccPositionInput: CheckboxGroup;
  hccInput: CheckboxGroup;
  chaseStatusInput: CheckboxGroup;
  reportedByEdgeInput: Radiobutton;
  contactMethodTypeInput: CheckboxGroup;
  callTypeInput: CheckboxGroup;
  contactDateFromInput: Calendar;
  contactDateToInput: Calendar;
  productsInput: Autocomplete;
  npiInput: Textbox;
  benefitYearInput: CheckboxGroup;
  dateRangeFromInput: Textbox;
  dateRangeToInput: Textbox;
  workFlowStatusFilteredValues = [];
  hiosFilteredValues = [];
  rxcFilteredValues = [];
  stratumLevelFilteredValues = [];
  submissionStatusFilteredValues = [];
  hccPositionFilteredValues = [];
  hccFilteredValues = [];
  chaseStatusFilteredValues = [];
  pendCodesFilteredValues = [];
  organizationFilteredValues = [];
  benefitYearFilteredValues = [];
  pendStatusFilteredValues = [];
  clientFilteredValues = [];
  organizationInput: CheckboxGroup;
  clientsInput: CheckboxGroup;
  dateOfServiceDateFromInput: Textbox;
  dateOfServiceDateToInput: Textbox;
  pendOwnerInput: CheckboxGroup;
  isParentOrg = false;
  showBorder = false;
  dateFilterInput: any;
  toDateFilterInput: any;
  currentDateFilters: string;
  currentDateFilterTab: string;
  errorMessage = "Please select at least one Project";
  isDateErrorExist: boolean = false;
  dateErrorMessage: string;
  userToken: UserToken;
  organizationId: number;
  billingDateFrom: Calendar;
  billingDateTo: Calendar;
  createFromDate: Calendar;
  createToDate: Calendar;
  @ViewChild("tabTemplate", { static: true }) tabTemplate: any;
  showErrorMessage = false;
  lineOfBusinessInput: CheckboxGroup;
  hideFilters = [];
  isHelpModalOpen = false;
  pendTypeInput: CheckboxGroup;

  constructor(private readonly formService: FormService,
    private analyticsService: AnalyticsService,
    private analyticsRiskService: AnalyticsRiskService,
    private chaseQueryService: ChaseQueryService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private session: LocalService,
    private readonly router: Router,
    private readonly createPendService: CreatePendService,
    private readonly bulkUpdateChaseService: BulkUpdateChaseService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly userService: UserService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService,
    private datePipe: DatePipe,
    private newProjectService: NewProjectService,
    private addressDetailService: AddressDetailInfoService,
    private addressDetailInfoService: AddressDetailInfoEditService
  ) {
    super();
  }

  ngOnInit() {
    this.userToken = this.userService.getUserToken();
    this.isParentOrg = this.userToken.isParentOrg;
    this.organizationId = this.userToken.organizationId;
    this.getMenuItems();
    this.createForm();
    this.getPendCodes();
    this.getPendStatus();
    this.getAllSelectableInputs();
    this.getWorkflowStatus();
    this.getStatuses();
    this.getSubmissionStatus();
    this.getHccPositions();
    this.getStratumLevels();
    this.getContactMethodTypes();
    this.getCallTypes();
    this.getLineOfBusiness();
    this.getSharedFilterData();
    this.getHealthPlans();
    this.getContractNumbers();
    this.getCurrentChaseStatus();
    this.getVendorInvoiceTypes();

    if (StringHelper.isAvailable(this.getRouteUrlValueForSession)) {
      this.showFiltersOrGetData();
    }
    if (!this.isParentOrg) {
      this.bindMeasureList();
    }
  }

  ngAfterViewInit(): void {
    this.getPendOwners();
    this.getPendTypes();
    this.getContactMethodStatus(() => {
      this.setControlValue(this.contactStatusInput, true);
    });
    this.getProjectStatus(() => {
      this.setControlValue(this.projectStatusInput, true);
    });
  }

  private getSharedFilterData(): void {
    this.genericGet(this.analyticsService, 'getDocumentStateGroups', (options) => {
      this.documentStatus = new CheckboxGroup({ ...this.documentStatus, options: [...options] } as any);
      this.setControlValue(this.documentStatus, false);
    });
    const contactMethod = Object.keys(ContactMethodType).filter(v => !isNaN(Number(v))).join(",");
    this.genericGet(this.analyticsService, 'getContactMethodTypes', (options) => {
      this.contactMethodInput = new CheckboxGroup({ ...this.contactMethodInput, options: [...options] } as any);
      this.setControlValue(this.contactMethodInput, false);
    }, contactMethod);
    this.genericGet(this.addressDetailService, 'getVendors', (options: VendorDetail[]) => {
      const mappedOptions = options.map((vendor) => new Object({text: vendor.name, value: vendor.name}));
      this.vendorNameInput = new CheckboxGroup({ ...this.vendorNameInput, options: [...mappedOptions] } as any);
      this.setControlValue(this.vendorNameInput, false);
    });
    this.genericGet(this.addressDetailInfoService, 'getSpecialHandlingReasons', (options) => {
      const mappedOptions = options.map((items) => new Object({text: items.text, value: items.text}));
      this.specialHandlingInput = new CheckboxGroup({ ...this.specialHandlingInput, options: [...mappedOptions] } as any);
      this.setControlValue(this.specialHandlingInput, false);
    });
    this.genericGet(this.retrievalPageService, "getDocumentSourceTypes", (result) => {
      const expectedRetrievalOptions = result.map(opt => new SelectableInput({text: opt.text, value: opt.text}));
      this.expectedRetrievalInput = new CheckboxGroup({ ...this.expectedRetrievalInput, options: [...expectedRetrievalOptions] } as any);
      this.setControlValue(this.expectedRetrievalInput, false);
    });
  }

  private createForm(): void {
    const futureDate = this.analyticsService.getCalenderFutureDate();
    this.projectsInput =
      new CheckboxGroup({
        key: "Projects",
        label: "IVA Projects",
      });
    this.measuresInput =
      new CheckboxGroup({
        key: "Measures",
        label: "Measure",
      });
    this.pendCreateDateFrom =
      new Calendar({
        key: "pendFromDate",
        label: "From",
        placeholder: "Enter From Date",
      });
    this.pendCreateDateTo =
      new Calendar({
        key: "pendToDate",
        label: "To",
        placeholder: "Enter To Date",
      });
    this.pendCodesInput = new CheckboxGroup({
      key: "pendCodes",
      label: "SELECT PEND(S)",
    });
    this.pendStatusInput = new CheckboxGroup({
      key: "pendStatus",
      label: "SELECT PEND STATUS(S)",
    });
    this.abstractionFromDate =
      new Calendar({
        key: "abstractionFromDate",
        label: "From",
        placeholder: "Enter From Date",
      });
    this.abstractionToDate =
      new Calendar({
        key: "abstractionToDate",
        label: "To",
        placeholder: "Enter To Date",
      });
    this.overread2FromDate =
      new Calendar({
        key: "overread2FromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date(),
      });
    this.overread2ToDate =
      new Calendar({
        key: "overread2ToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.overreadFromDate =
      new Calendar({
        key: "overreadFromDate",
        label: "From",
        placeholder: "Enter From Date",
      });
    this.overreadToDate =
      new Calendar({
        key: "overreadToDate",
        label: "To",
        placeholder: "Enter To Date",
      });
    this.chartReceivedDateFromInput =
      new Textbox({
        key: "chartReceivedDateFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.chartReceivedDateToInput =
      new Textbox({
        key: "chartReceivedDateTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.completionFromDate =
      new Textbox({
        key: "completionFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.completionToDate =
      new Textbox({
        key: "completionToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.updateDateFrom =
      new Calendar({
        key: "updateFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
     
    this.updateDateTo =
      new Calendar({
        key: "updateToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.workflowStatusInput = new CheckboxGroup({
      key: "workflowStatus",
      label: "SELECT Workflow Status(S)",
    });
    this.retrievalTypeInput =
      new CheckboxGroup({
        key: "RetrievalType",
        label: "Retrieval Types",
      });
    this.fileCreateDateFromInput =
      new Textbox({
        key: "fileCreateDateFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.fileCreateDateToInput =
      new Textbox({
        key: "fileCreateDateTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.retrievalFromDate =
      new Textbox({
        key: "retrievalFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.retrievalToDate =
      new Textbox({
        key: "retrievalToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.startDateInput =
      new Calendar({
        key: "startDate",
        label: "Start Date",
        placeholder: "Enter Start Date",
      });
    this.endDateInput =
      new Calendar({
        key: "endDate",
        label: "End Date",
        placeholder: "Enter End Date",
      });
    this.hccInput =
      new CheckboxGroup({
        key: "hcc",
        label: "Condition Category",
      });
    this.chaseStatusInput =
      new CheckboxGroup({
        key: "chaseStatus",
        label: "Chase Status",
      });
    this.reportedByEdgeInput =
      new Radiobutton({
        key: "reportedByEdge",
        label: "Reported by EDGE",
        options: [
          new SelectableInput({ text: "Yes", value: "Yes" }),
          new SelectableInput({ text: "No", value: "No" }),
        ],
      });
    this.enrolleeIdInput =
      new Textbox({
        key: "enrolleeId",
        label: "Enrollee ID",
      });
    this.memberIdInput =
      new Textbox({
        key: "memberId",
        label: "Member ID",
      });
    this.clientMemberIdInput =
      new Textbox({
        key: "clientMemberId",
        label: "Client Member ID",
      });
    this.claimIdInput =
      new Textbox({
        key: "claimId",
        label: "Claim ID",
      });
    this.dispensingProviderIdInput =
      new Textbox({
        key: "dispensingProviderId",
        label: "Dispensing Provider Id",
      });
    this.ndcInput =
      new Textbox({
        key: "ndc",
        label: "NDC",
      });
    this.hcpcsInput =
      new Textbox({
        key: "hcpcs",
        label: "HCPCS",
      });
    this.chaseIdInput =
      new Textbox({
        key: "chaseId",
        label: "Chase ID",
      });
    
    this.stratumLevelInput =
      new CheckboxGroup({
        key: "stratumLevel",
        label: "Stratum Level",
      });
    this.rxcInput =
      new CheckboxGroup({
        key: "rxc",
        label: "RXC",
      });
    this.submissionStatusInput =
      new CheckboxGroup({
        key: "submissionStatus",
        label: "Submission Status",
      });
    this.hccPositionInput =
      new CheckboxGroup({
        key: "hccPosition",
        label: "HCC Position",
      });
    this.contactMethodTypeInput =
      new CheckboxGroup({
        key: "contactMethod",
        label: "SELECT CONTACT METHOD",
      });
    this.callTypeInput =
      new CheckboxGroup({
        key: "callType",
        label: "SELECT STATUS(S)",
      });
    this.contactDateFromInput =
      new Calendar({
        key: "contactFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.contactDateToInput =
      new Calendar({
        key: "contactToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.productsInput =
      new Autocomplete({
        key: "products",
        label: "Products",
      });
    this.npiInput =
      new Textbox({
        key: "npi",
        label: "NPI",
      });
    this.dateRangeFromInput =
      new Textbox({
        key: "dateRangeFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dateRangeToInput =
      new Textbox({
        key: "dateRangeTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.benefitYearInput =
      new CheckboxGroup({
        key: "benefitYear",
        label: "Benefit Year",
        options: [
          new SelectableInput({ text: "BY2019", value: "BY2019" }),
          new SelectableInput({ text: "BY2020", value: "BY2020" }),
        ],
      });
    this.organizationInput =
      new CheckboxGroup({
        key: "organization",
        label: "Service Organizations",
      });
    this.clientsInput =
      new CheckboxGroup({
        key: "client",
        label: "Clients",
      });
    this.dateOfServiceDateFromInput =
      new Textbox({
        key: "dateOfServiceFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dateOfServiceDateToInput =
      new Textbox({
        key: "dateOfServiceToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.billingDateFrom =
      new Calendar({
        key: "billingFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.billingDateTo =
      new Calendar({
        key: "billingToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.createFromDate =
      new Calendar({
        key: "createFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.createToDate =
      new Calendar({
        key: "createToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
      this.pendOwnerInput = new CheckboxGroup({
        key: "pendOwner",
        label: "Pend Owner",
        options: [
          { text: "Client", value: "Client" },
          { text: "Organization", value: "Organization" },
        ],
      });
      this.pendTypeInput = new CheckboxGroup({
        key: "pendType",
        label: "Pend Type",
        options: [
          { text: "RETRIEVAL", value: "RETRIEVAL" },
          { text: "CLINICAL", value: "CLINICAL" },
        ],
      });
      this.lineOfBusinessInput = new CheckboxGroup({
        key: "lineOfBusiness",
        label: "Line Of Business",
      });
    this.CreateForm();
  }
  CreateForm() {
    this.setDateDefaultValues(this.filters);
    this.formService.updateDom.next();
    this.changeDetector.markForCheck();
    this.form = this.formService.createFormGroup([
      this.projectsInput,
      this.measuresInput,
      this.pendCodesInput,
      this.pendStatusInput,
      this.pendCreateDateFrom,
      this.pendCreateDateTo,
      this.abstractionFromDate,
      this.abstractionToDate,
      this.overreadToDate,
      this.overreadFromDate,
      this.chartReceivedDateFromInput,
      this.chartReceivedDateToInput,
      this.completionFromDate,
      this.completionToDate,
      this.workflowStatusInput,
      this.updateDateFrom,
      this.updateDateTo,
      this.retrievalTypeInput,
      this.fileCreateDateFromInput,
      this.fileCreateDateToInput,
      this.retrievalFromDate,
      this.retrievalToDate,
      this.startDateInput,
      this.endDateInput,
      this.hccInput,
      this.chaseStatusInput,
      this.reportedByEdgeInput,
      this.enrolleeIdInput,
      this.memberIdInput,
      this.clientMemberIdInput,
      this.claimIdInput,
      this.dispensingProviderIdInput,
      this.ndcInput,
      this.hcpcsInput,
      this.chaseIdInput,
      this.stratumLevelInput,
      this.rxcInput,
      this.submissionStatusInput,
      this.hccPositionInput,
      this.contactMethodTypeInput,
      this.callTypeInput,
      this.contactDateFromInput,
      this.contactDateToInput,
      this.organizationInput,
      this.addressGroupInput,
      this.addressIdInput,
      this.productsInput,
      this.npiInput,
      this.benefitYearInput,
      this.dateRangeFromInput,
      this.dateRangeToInput,
      this.clientsInput,
      this.dateOfServiceDateFromInput,
      this.dateOfServiceDateToInput,
      this.overread2FromDate,
      this.overread2ToDate,
      this.billingDateFrom,
      this.billingDateTo,
      this.createFromDate,
      this.createToDate,
      this.pendOwnerInput,
      this.lineOfBusinessInput,
      this.retrievalOwnerInput,
      this.expectedRetrievalInput,
      this.projectStatusInput,
      this.vendorNameInput,
      this.specialHandlingInput,
      this.healthPlanInput,
      this.contractNumberInput,
      this.moveDateStart,
      this.moveDateEnd,
      this.currentChaseStatus,
      this.currentProcessStep,
      this.vendorInvoiceType,
      this.pendTypeInput,
    ]);
  }

  private getHealthPlans(): void {
     const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
     const request = new EntityAttribute({
       projectIdsAsCsv: selectedProjectIds,
       projectTypeId: ProjectType.HEDIS,
       attributeId: PLAN_ID.attributeId,
     });
     this.analyticsService.getEntityAttributes(request).pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
       .subscribe(options => {
         this.healthPlanInput = new CheckboxGroup({ ...this.healthPlanInput, options } as any);
         this.setControlValue(this.healthPlanInput);
         this.formService.updateDom.next();
       });
   }

  private getLineOfBusiness() {
    this.newProjectService
      .getLineOfBusiness()
      .pipe(map(this.analyticsService.simpleObjectMapping))
      .subscribe(options => {
        this.lineOfBusinessInput = new CheckboxGroup({ ...this.lineOfBusinessInput, options: [...options] } as any);
        this.setControlValue(this.lineOfBusinessInput, false);
        this.changeDetector.markForCheck();
      });
  }

  private getProjects(): void {
    if (this.isParentOrg) {
      if (this.filters.includes("Client")) {
        this.getProjectsByClient();
      } else {
        this.getProjectsByOrganization();
      }
    } else {
      this.getProjectsByType();
    }
  }
  private getProjectsByClient() {
    const selectedClientIds = this.clientsInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedClientIds)) {
      this.analyticsService.getProjectsByClients(selectedClientIds, ProjectType.IVA)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.bindProjects(options);
        });
    }
  }
  private getProjectsByOrganization() {
    const selectedOrgIds = this.organizationInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedOrgIds)) {
      this.analyticsService.getProjects(selectedOrgIds, ProjectType.IVA)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.bindProjects(options);
        });
    }
  }
  private getProjectsByType(): void {
    const projectFilter = new Project({
      projectTypeId: ProjectType.IVA,
    });
    this.retrievalPageService
      .getProjects(projectFilter).pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.bindProjects(options);
      });
  }
  private bindProjects(options: any): void {
    this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
    this.setControlValue(this.projectsInput, false);
    this.getRxc();
    this.getHccListByProject();
    this.formService.updateDom.next();
  }
  private getOrganizations(): void {
    this.analyticsService.getOrganizations()
      .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
        this.organizationInput = new CheckboxGroup({ ...this.organizationInput, options } as any);
        this.setControlValue(this.organizationInput, false);
        this.setControlValue(this.benefitYearInput, false);
        this.loadClientOrProjectControl();
        this.changeDetector.markForCheck();
      });
  }

  private bindMeasureList() {
    this.measuresInput = new CheckboxGroup({ ...this.measuresInput, options: this.filterMeasuresByReport() } as any);
    this.setControlValue(this.measuresInput, true);
    this.formService.updateDom.next();
  }
  private filterMeasuresByReport() {
    let measureOptions: SelectableInput[] = [];
    switch (this.getRouteUrlValue) {
      case "/analytics/iva/reports/iva_pursuit_prioritization_report":
        measureOptions.push(new SelectableInput({ text: 'ATT', value: 34 }));
        measureOptions.push(new SelectableInput({ text: 'DOB', value: 39 }));
        measureOptions.push(new SelectableInput({ text: 'GEN', value: 40 }));
        return measureOptions;
      case "/analytics/iva/reports/iva_diagnosis_data":
        measureOptions.push(new SelectableInput({ text: 'ATT', value: 34 }));
        measureOptions.push(new SelectableInput({ text: 'HST', value: 35 }));
        return measureOptions;
      default:
        measureOptions.push(new SelectableInput({ text: 'ATT', value: 34 }));
        measureOptions.push(new SelectableInput({ text: 'HST', value: 35 }));
        measureOptions.push(new SelectableInput({ text: 'DOB', value: 39 }));
        measureOptions.push(new SelectableInput({ text: 'GEN', value: 40 }));
        measureOptions.push(new SelectableInput({ text: 'RXCP', value: 36 }));
        measureOptions.push(new SelectableInput({ text: 'RXCM', value: 38 }));
        measureOptions.push(new SelectableInput({ text: 'ENR', value: 37 }));
        measureOptions.push(new SelectableInput({ text: 'MEMBR', value: 46 }));
        return measureOptions;
    }
  }
  private getProducts(): void {
    this.serviceOrgConfigurationService.getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId).subscribe(configuration => {
      this.getProductsInput(configuration);
      this.setProductsInput();
      this.formService.updateDom.next();
      this.changeDetector.markForCheck();
    });
  }
  private getPendTypes(): void {
    this.pendTypeInput = new CheckboxGroup({ ...this.pendTypeInput } as any);
    this.setControlValue(this.pendTypeInput, true);
  }
  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name, value: item.Name,
    }));
    productOptions.unshift(new SelectableInput({ text: "*clear filter", value: "" }));
    this.productsInput = new Autocomplete({ ...this.productsInput, options: productOptions } as any);
  }
  private setProductsInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.productName)) {
      const optionsValue = this.productsInput.options.find(a => a.value === this.analyticsFilter.productName);
      this.productsInput.value = [optionsValue];
      this.form.get(this.productsInput.key).setValue(this.productsInput.value[0]);
    }
  }

  private setHccControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.hccAsCsv)) {
      const hccFilteredValues = this.analyticsFilter.hccAsCsv.split(/\s*,\s*/);
      hccFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.hccInput.options)) {
          filterValue.push(this.hccInput.options.find(a => a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.hccInput.key).setValue(control.selectedOptions);
    }
  }
  private getStatuses(): void {
    this.chaseQueryService.getReportingStatusesList()
      .pipe(map(this.automapper.curryMany("ChaseReportingStatusModel", "SelectableInput"))).subscribe(options => {
        this.chaseStatusInput = new CheckboxGroup({ ...this.chaseStatusInput, options } as any);
        this.setControlValue(this.chaseStatusInput);
        this.formService.updateDom.next();
      });
  }
  private getSubmissionStatus(): void {
    this.analyticsService.getSubmissionStatus().pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.submissionStatusInput = new CheckboxGroup({ ...this.submissionStatusInput, options } as any);
        this.setControlValue(this.submissionStatusInput);
        this.changeDetector.markForCheck();
      });
  }
  private getHccPositions(): void {
    this.analyticsService.getHccPositions().pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.hccPositionInput = new CheckboxGroup({ ...this.hccPositionInput, options } as any);
        this.setControlValue(this.hccPositionInput);
        this.changeDetector.markForCheck();
      });
  }
  
  private getRxc(): void {
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedProjectIds) && this.filters.includes("RXC")) {
      this.analyticsService.getRxc(selectedProjectIds)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.rxcInput = new CheckboxGroup({ ...this.rxcInput, options } as any);
          this.setControlValue(this.rxcInput);
          this.changeDetector.markForCheck();
        });
    }
  }
  private getStratumLevels(): void {
    this.analyticsService.getStratumLevels()
      .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
        this.stratumLevelInput = new CheckboxGroup({ ...this.stratumLevelInput, options } as any);
        this.setControlValue(this.stratumLevelInput);
        this.changeDetector.markForCheck();
      });
  }
  private getContactMethodTypes(): void {
    this.analyticsService
      .getContactMethodTypes(`${ContactMethodType.RecordCollectionCall},${ContactMethodType.Fax},${ContactMethodType.IncomingCall}`)
      .subscribe(options => {
        this.contactMethodTypeInput = new CheckboxGroup({ ...this.contactMethodTypeInput, options }) as any;
        this.formService.updateDom.next();
      });
  }
  private getCallTypes(): void {
    this.analyticsService.getCallTypes().subscribe(options => {
      this.callTypeInput = new CheckboxGroup({ ...this.callTypeInput, options }) as any;
      this.formService.updateDom.next();
    });
  }
  private getClients(): void {
    const selectedOrgIds = this.organizationInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedOrgIds)) {
      this.analyticsService.getClients(selectedOrgIds)
        .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))).subscribe(options => {
          this.clientsInput = new CheckboxGroup({ ...this.clientsInput, options } as any);
          this.setControlValue(this.clientsInput, false);
          this.getProjects();
          this.formService.updateDom.next();
        });
    }
  }

  private getPendOwners(): void {
    this.pendOwnerInput = new CheckboxGroup({ ...this.pendOwnerInput } as any);
    this.setControlValue(this.pendOwnerInput, true);
  }

  onDateChange(fromDate: Calendar, toDate: Calendar) {
    if (this.form.get(fromDate.key).value && this.form.get(toDate.key).value) {
      {
        if ((new Date(this.form.get(fromDate.key).value)) < (new Date(this.form.get(toDate.key).value))) {
          this.showBorder = false;
          this.form.get(fromDate.key).setErrors(null);
          this.form.get(toDate.key).setErrors(null);
          this.isDateErrorExist = false;
          this.getTab(this.selectedTab);
        } else {
          if (this.filters.includes("UpdateDate")) {
            this.form.get(toDate.key).setErrors({ required: true });
          }
          else {
            this.form.get(fromDate.key).setErrors({ required: true });
          }
          this.showBorder = true;
          this.isDateErrorExist = false;
          this.getTab(this.selectedTab);
        }
      }
    }
    else if (this.form.get(fromDate.key).value && !this.form.get(toDate.key).value) {
      this.isDateErrorExist = true;
      this.dateErrorMessage = `Please Select a  ${toDate.label.replace("Date", "")} Date.`;
      this.form.get(fromDate.key).setErrors({ "server-error": "" });
      this.showBorder = false;
    }
    else {
      if (!this.form.get(fromDate.key).value && this.form.get(toDate.key).value) {
        this.isDateErrorExist = true;
        this.form.get(fromDate.key).markAsTouched();
        this.dateErrorMessage = `Please Select a  ${fromDate.label.replace("Date", "")} Date.`;
        this.form.get(fromDate.key).setErrors({ "server-error": "" });
        this.showBorder = false;
      }
      else {
        this.form.get(fromDate.key).setErrors({ "server-error": "" });
      }
    }
  }
  closeFilters(): void {
    if (this.filters.includes(this.currentDateFilters)) {
      this.getTab(this.selectedTab);
    }
    if (!this.filters.includes("UpdateDate")) {
      this.setDefaultDateValues();
    }
    if (this.isValidForm && !this.isDateErrorExist) {
      this.getLookerUrl(true, true);
      this.isFiltersVisible = false; this.showErrorMessage = false;
    } else {
      this.showErrorMessage = true;
    }
  }
  setDefaultDateValues() {
    if (this.dateFilterInput && this.dateFilterInput.key && !this.form.get(this.dateFilterInput.key).value && this.toDateFilterInput && this.toDateFilterInput.key && !this.form.get(this.toDateFilterInput.key).value) {
      this.form.get(this.dateFilterInput.key).setValue("01/01/2020");
      this.form.get(this.toDateFilterInput.key).setValue(new Date());
    }
  }
  checkFromDateGreaterThanToDate() {
    if (this.form.get(this.dateFilterInput.key).value && this.form.get(this.toDateFilterInput.key).value) {
      {
        if ((new Date(this.form.get(this.dateFilterInput.key).value)) < (new Date(this.form.get(this.toDateFilterInput.key).value))) {
          this.errorMessage = "";
          this.form.get(this.dateFilterInput.key).setErrors(null);
        }
        else if(this.form.get(this.dateFilterInput.key).value == null) {
          this.form.get(this.dateFilterInput.key).setErrors({ required: true });
          this.errorMessage = "Please select a To Date that is after the From Date.";
        }
      }
    }
    else if (!this.isDateErrorExist && !this.filters.includes("UpdateDate")) {
      this.form.get(this.dateFilterInput.key).setErrors(null);
    }
  }
  getTab(tab: string) {
    if (tab && (tab === this.currentDateFilterTab)) {
      this.checkFromDateGreaterThanToDate()
    }
    if (tab && (tab !== this.currentDateFilterTab)) {
      this.checkFromDateGreaterThanToDate()
    }
    if (this.form.get(this.updateDateFrom.key).invalid) {
      this.errorMessage = "Please select Update Date";
    }
    if (this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = "Please select at least one Project";
    }
    if (tab && (tab === this.currentDateFilterTab) && !this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = "";
    }
    if (tab && (tab === this.currentDateFilterTab) && this.isDateErrorExist && !this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = this.dateErrorMessage;
    }
    if (this.isDateErrorExist && tab && (tab !== this.currentDateFilterTab)) {
      this.errorMessage = this.dateErrorMessage;
    }
  }
  get selectedTab(): string {
    if (this.tabTemplate.tabPanels) {
      const selectedTab = this.tabTemplate.tabPanels._results.filter(x => x.previousIsActive === true);
      if (ArrayHelper.isAvailable(selectedTab)) {
        return selectedTab[0].header;
      }
    } else {
      return "";
    }
  }

  private getLookerUrl(isUpdateFilter: boolean, isChangeFormat?: boolean): void {
    this.isFilterUpdated = isUpdateFilter;
    if (!isUpdateFilter) {
      this.setFilteredInputValue(this.form, this.analyticsFilter);
    }
    this.formFilterRequest = this.form;
    const analyticsRequest = this.bindFilterData(isChangeFormat);
    this.analyticsService.getLookerUrl(analyticsRequest).subscribe(data => {
      this.session.put(this.getRouteUrlValueForSession, analyticsRequest);
      this.isVisible = true;
      this.lookerUrl = data;
      this.changeDetector.markForCheck();
    });
  }
  bindFilterData(isUpdate?: boolean): AnalyticsRequest {
    let [toDateAbstract, fromDateAbstract] = this.getDateFromDatePicker(this.abstractionToDate, this.abstractionFromDate, isUpdate);
    let [toDateOverread, fromDateOverread] = this.getDateFromDatePicker(this.overreadToDate, this.overreadFromDate, isUpdate);
    let [toDatePend, fromDatePend] = this.getDateFromDatePicker(this.pendCreateDateTo, this.pendCreateDateFrom, isUpdate);
    let [startDate, endDate] = this.getDateFromDatePicker(this.startDateInput, this.endDateInput, isUpdate);
    let [toDateOverread2, fromDateOverread2] = this.getDateFromDatePicker(this.overread2ToDate, this.overread2FromDate, isUpdate);
    let [toDateBilling, fromDateBilling] = this.getDateFromDatePicker(this.billingDateTo, this.billingDateFrom, isUpdate);
    const [toDateUpdate, fromDateUpdate] = this.getDateFromDatePicker(this.updateDateTo, this.updateDateFrom, isUpdate);
    const [toContactDate, fromContactDate] = this.getDateFromDatePicker(this.contactDateToInput, this.contactDateFromInput, isUpdate);
    const [toCreateDate, fromCreateDate] = this.getDateFromDatePicker(this.createToDate, this.createFromDate, isUpdate);
    const [moveStartDate, moveEndDate] = this.getDateFromDatePicker(this.moveDateStart, this.moveDateEnd);

    return new AnalyticsRequest({
      projectIdsAsCsv: this.getFilterValue(this.projectsInput.key, true, true),
      measureIdsAsCsv: this.getFilterValue(this.measuresInput.key, true, false),
      dashboardType: this.pageType,
      abstractionToDate: toDateAbstract,
      abstractionFromDate: fromDateAbstract,
      overreadToDate: toDateOverread,
      overreadFromDate: fromDateOverread,
      pendStatusCsv: this.getFilterValue(this.pendStatusInput.key, true, true),
      pendCodeCsv: this.getFilterValue(this.pendCodesInput.key, true, false),
      pendTypeIdCsv: this.getFilterValue(this.pendCodesInput.key, true, true),
      pendToDate: toDatePend,
      pendFromDate: fromDatePend,
      chartReceivedDateFrom: this.form.get("chartReceivedDateFrom").value,
      chartReceivedDateTo: this.form.get("chartReceivedDateTo").value,
      completionFromDate: this.form.get(this.completionFromDate.key).value,
      completionToDate: this.form.get(this.completionToDate.key).value,
      overread2ToDate: toDateOverread2,
      overread2FromDate: fromDateOverread2,
      workflowStatusCsv: this.getFilterValue(this.workflowStatusInput.key, true, true),
      updateFromDate: fromDateUpdate,
      updateToDate: toDateUpdate,
      retrievalTypeAsCsv: this.getFilterValue(this.retrievalTypeInput.key, true, true),
      fileCreateFromDate: this.form.get("fileCreateDateFrom").value,
      fileCreateToDate: this.form.get("fileCreateDateTo").value,
      retrievalFromDate: this.form.get(this.retrievalFromDate.key).value,
      retrievalToDate: this.form.get(this.retrievalToDate.key).value,
      startDate: startDate,
      endDate: endDate,
      enrolleeId: this.form.get(this.enrolleeIdInput.key).value,
      memberId: this.form.get(this.memberIdInput.key).value,
      clientMemberId: this.form.get(this.clientMemberIdInput.key).value,
      claimId: this.form.get(this.claimIdInput.key).value,
      dispensingProviderId: this.form.get(this.dispensingProviderIdInput.key).value,
      ndc: this.form.get(this.ndcInput.key).value,
      hcpcs: this.form.get(this.hcpcsInput.key).value,
      chaseId: this.form.get(this.chaseIdInput.key).value,
      stratumLevelAsCsv: this.getFilterValue(this.stratumLevelInput.key, true, false),
      rxcAsCsv: this.getFilterValue(this.rxcInput.key, true, false),
      submissionStatusAsCsv: this.getFilterValue(this.submissionStatusInput.key, true, false),
      hccPositionAsCsv: this.getFilterValue(this.hccPositionInput.key, true, false),
      hccAsCsv: this.getFilterValue(this.hccInput.key, true, false),
      chaseStatusAsCsv: this.getFilterValue(this.chaseStatusInput.key, true, false),
      reportedByEdge: this.form.get(this.reportedByEdgeInput.key).value,
      contactMethodTypeAsCsv: this.getFilterValue(this.contactMethodTypeInput.key, true, true),
      contactResultTypeAsCsv: this.getFilterValue(this.callTypeInput.key, true, true),
      serviceOrgIdsAsCsv: this.getFilterValue(this.organizationInput.key, true, true),
      productName: this.form.get(this.productsInput.key).value ? this.form.get(this.productsInput.key).value.value : this.form.get(this.productsInput.key).value,
      addressId: this.getFilterValue(this.addressIdInput.key, false),
      addressGroup: this.getFilterValue(this.addressGroupInput.key, false),
      npi: this.form.get(this.npiInput.key).value,
      benefitYear: this.getFilterValue(this.benefitYearInput.key, true, false),
      dateRangeFrom: this.form.get(this.dateRangeFromInput.key).value,
      dateRangeTo: this.form.get(this.dateRangeToInput.key).value,
      clientIdsAsCsv: this.getFilterValue(this.clientsInput.key, true, true),
      dateOfServiceFromDate: this.form.get("dateOfServiceFromDate").value,
      dateOfServiceToDate: this.form.get("dateOfServiceToDate").value,
      billingFromDate: fromDateBilling,
      billingToDate: toDateBilling,
      contactToDate: toContactDate,
      contactFromDate: fromContactDate,
      createFromDate: fromCreateDate,
      createToDate: toCreateDate,
      pendOwnerAsCsv: this.getFilterValue(this.pendOwnerInput.key, true, true),
      lineOfBusinessAsCsv: this.getFilterValue(this.lineOfBusinessInput.key, true, true),
      retrievalOwner: this.getFilterValue(this.retrievalOwnerInput.key, false, true),
      expectedRetrieval: this.getFilterValue(this.expectedRetrievalInput.key, true, true),
      projectStatus: this.getFilterValue(this.projectStatusInput.key, true, true),
      vendorName: this.getFilterValue(this.vendorNameInput.key, true, true),
      specialHandling: this.getFilterValue(this.specialHandlingInput.key, true, true),
      pendTypeAsCsv: this.getFilterValue(this.pendTypeInput.key, true, true),
      healthPlanNamesAsCsv: this.getFilterValue(this.healthPlanInput.key, true, true),
      contractNumbersAsCsv: this.getFilterValue(this.contractNumberInput.key, true, true),
      moveStartDate,
      moveEndDate,
      currentChaseStatus: this.getFilterValue(this.currentChaseStatus.key, true, true),
      currentProcessStep: this.getFilterValue(this.currentProcessStep.key, true, true),
      vendorInvoiceType: this.getFilterValue(this.vendorInvoiceType.key, true, true),
      hideFilters: this.hideFilters,
    });
  }

  getDateFromDatePicker(toKey: any, fromKey: any, isUpdate?: boolean): [string, string] {
    let todate = "";
    let fromdate = "";
    if (!isUpdate) {
      todate = this.datePipe.transform(this.form.get(toKey.key).value, "MM/dd/yyyy");
      this.form.get(toKey.key).setValue(todate);
      fromdate = this.datePipe.transform(this.form.get(fromKey.key).value, "MM/dd/yyyy");
      this.form.get(fromKey.key).setValue(fromdate);
    }
    todate = this.form.get(toKey.key).value ? new Date(this.form.get(toKey.key).value).toLocaleDateString("en-ZA") : null;
    fromdate = this.form.get(fromKey.key).value ? new Date(this.form.get(fromKey.key).value).toLocaleDateString("en-ZA") : null;

    return [todate, fromdate];
  }

  dateValidationToAndForm(value: string) {
    if (value === "pend") {
      if (StringHelper.isAvailable(this.form.get(this.pendCreateDateTo.key).value)
        || StringHelper.isAvailable(this.form.get(this.pendCreateDateFrom.key).value)
      ) {
        const pendFromDate = this.form.get(this.pendCreateDateFrom.key).value;
        this.pendCreateDateFrom = new Calendar({
          ...this.pendCreateDateFrom,
          value: pendFromDate,
          validators: [Validators.required],
          placeholder: "Enter From Date",
          errorMessages: {
            required: "Enter From Date"
          },
        } as any);
        const pendToDate = this.form.get(this.pendCreateDateTo.key).value;
        this.pendCreateDateTo = new Calendar({
          ...this.pendCreateDateTo,
          value: pendToDate,
          validators: [Validators.required],
          placeholder: "Enter To Date",
          errorMessages: {
            required: "Enter To Date"
          },
        } as any);
      } else {
        const pendFromDate = this.form.get(this.pendCreateDateFrom.key).value;
        this.pendCreateDateFrom = new Calendar({
          ...this.pendCreateDateFrom,
          value: pendFromDate,
          placeholder: "Enter From Date",
        } as any);
        const pendToDate = this.form.get(this.pendCreateDateTo.key).value;
        this.pendCreateDateTo = new Calendar({
          ...this.pendCreateDateTo,
          value: pendToDate,
          placeholder: "Enter To Date",
        } as any);
      }
    }
    if (value === "chartReceived") {
      if (StringHelper.isAvailable(this.form.get(this.chartReceivedDateFromInput.key).value)
        || StringHelper.isAvailable(this.form.get(this.chartReceivedDateToInput.key).value)
      ) {
        const chartReceivedDateFromInput = this.form.get(this.chartReceivedDateFromInput.key).value;
        this.chartReceivedDateFromInput = new Textbox({
          ...this.chartReceivedDateFromInput,
          value: chartReceivedDateFromInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
            required: "Field is required.",
          },
        } as any);
        const chartReceivedDateToInput = this.form.get(this.chartReceivedDateToInput.key).value;
        this.chartReceivedDateToInput = new Textbox({
          ...this.chartReceivedDateToInput,
          value: chartReceivedDateToInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format.", required: "Field is required." },
        } as any);
      } else {
        const chartReceivedDateFromInput = this.form.get(this.chartReceivedDateFromInput.key).value;
        this.chartReceivedDateFromInput = new Textbox({
          ...this.chartReceivedDateFromInput,
          value: chartReceivedDateFromInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format." },
        } as any);
        const chartReceivedDateToInput = this.form.get(this.chartReceivedDateToInput.key).value;
        this.chartReceivedDateToInput = new Textbox({
          ...this.chartReceivedDateToInput,
          value: chartReceivedDateToInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format." },
        } as any);
      }
    }
    if (value === "completion") {
      if (StringHelper.isAvailable(this.form.get(this.completionToDate.key).value) || StringHelper.isAvailable(this.form.get(this.completionFromDate.key).value)
      ) {
        const completionFromDate = this.form.get(this.completionFromDate.key).value;
        this.completionFromDate = new Textbox({
          ...this.completionFromDate, value: completionFromDate,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format.", required: "Field is required." },
        } as any);
        const completionToDate = this.form.get(this.completionToDate.key).value;
        this.completionToDate = new Textbox({
          ...this.completionToDate,
          value: completionToDate,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format.", required: "Field is required." },
        } as any);
      } else {
        const completionFromDate = this.form.get(this.completionFromDate.key).value;
        this.completionFromDate = new Textbox({
          ...this.completionFromDate,
          value: completionFromDate,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format." },
        } as any);
        const completionToDate = this.form.get(this.completionToDate.key).value;
        this.completionToDate = new Textbox({
          ...this.completionToDate,
          value: completionToDate,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: { pattern: "Date should be in YYYY/MM/DD format." },
        } as any);
      }
    }
    const pendStatus = this.form.get(this.pendStatusInput.key).value;
    this.pendStatusInput = new CheckboxGroup({ ...this.pendStatusInput, value: pendStatus } as any);
    this.getPendStatus();
    this.CreateForm();
    this.formService.markAllAsTouched(this.form);
  }
  showFilters(): void {
    this.isFiltersVisible = true;
    if (this.filters.includes(this.currentDateFilters)) {
      this.form.get(this.dateFilterInput.key).setValidators(null);
      this.form.get(this.dateFilterInput.key).setErrors(null);
    }
  }
  get pageType(): LookerIvaDashboardType {
    let lookerDashboardType: LookerIvaDashboardType;
    switch (this.getRouteUrlValue) {
      case "/analytics/iva/reports/project_summary":
        lookerDashboardType = LookerIvaDashboardType.IvaProjectSummary;
        break;
      case "/analytics/iva/reports/top50_aids_with_open_chases":
        lookerDashboardType = LookerIvaDashboardType.Top50AidsWithOpenChases;
        this.filters = ["Project", "LOB", "RetrievalOwner", "Products"];
        break;
      case "/analytics/iva/reports/pend_report":
        lookerDashboardType = LookerIvaDashboardType.PendReport;
        this.filters = ["Project", "Measure", "PendDate", "PendStatus", "PendCode", "PendOwner"];
        break;
      case "/analytics/iva/reports/clinical_productivity_and_accuracy-Overall":
        lookerDashboardType = LookerIvaDashboardType.ClinicalProductivityAndAccuracy;
        this.filters = ["Project", "AbstractionDate"];
        break;
      case "/analytics/iva/reports/project_status_report":
        lookerDashboardType = LookerIvaDashboardType.ProjectStatus;
        this.filters = ["Project", "Measure", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/dw/project_status_report":
          lookerDashboardType = LookerIvaDashboardType.ProjectStatusDW;
          this.filters = ["Project"];
          this.hideFilters=[HideFilters.PROJECT_ID,HideFilters.ORG_ID,HideFilters.USER_ID];
          break;
      case "/analytics/iva/reports/retrieval_report":
        lookerDashboardType = LookerIvaDashboardType.RetrievalReport;
        this.filters = ["Project", "ChartReceived"];
        break;
      case "/analytics/iva/reports/coder_productivity_report":
        lookerDashboardType = LookerIvaDashboardType.CoderProductivityReport;
        this.filters = ["Project"];
        break;
      case "/analytics/iva/reports/intake_productivity_report":
        lookerDashboardType = LookerIvaDashboardType.IntakeProductivityReport;
        this.filters = ["Project", "UpdateDate"];
        break;
      case "/analytics/iva/reports/clinical_productivity_report":
        lookerDashboardType = LookerIvaDashboardType.ClinicalProductivityReport;
        this.filters = ["Project", "completion"];
        break;
      case "/analytics/iva/reports/dw/call_count_detail_report":
        lookerDashboardType = LookerIvaDashboardType.CallCountDetailReport;
        this.filters = ["Project", "workflowStatus", "LOB", "Products", "RetrievalOwner"];
        this.hideFilters = [HideFilters.PROJECT_ID,HideFilters.USER_ID,HideFilters.ORG_ID,HideFilters.WORKFLOW,HideFilters.HEALTH_PLAN2,
          HideFilters.LINE_OF_BUSINESS,HideFilters.CONTRACT_NUMBER,HideFilters.CO_RETRIEVAL2,HideFilters.PRODUCT,HideFilters.MEASURE];
        break;
      case "/analytics/iva/reports/call_count_detail_report":
        lookerDashboardType = LookerIvaDashboardType.CallCountDetailReportLegacy;
        this.filters = ["Project", "workflowStatus", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/training_feedback_report":
        lookerDashboardType = LookerIvaDashboardType.TrainingFeedbackReport;
        this.filters = ["Project", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/client_overread2_report":
        lookerDashboardType = LookerIvaDashboardType.ClientOverread2Report;
        this.filters = ["Project", "Overread2Date"];
        break;
      case "/analytics/iva/reports/clinical_accuracy_by_chase_with_error_detail_report":
        lookerDashboardType = LookerIvaDashboardType.ClinicalAccuracyByChaseWithErrorDetailReport;
        this.filters = ["Project", "AbstractionDate"];
        break;
      case "/analytics/iva/reports/ft_daily_summary_report":
        lookerDashboardType = LookerIvaDashboardType.FtDailySummaryReport;
        this.filters = ["Project"];
        break;
      case "/analytics/iva/reports/auto_close_report":
        lookerDashboardType = LookerIvaDashboardType.AutoCloseReport;
        this.filters = ["Project"];
        break;
      case "/analytics/iva/reports/sftp_report":
        lookerDashboardType = LookerIvaDashboardType.SftpReport;
        this.filters = ["Project", "RetrievalType", "FileCreateDate"];
        break;
      case "/analytics/iva/reports/retrieval_report_by_chase":
        lookerDashboardType = LookerIvaDashboardType.RetrievalReportByChase;
        this.filters = ["Project", "RetrievalType", "RetrievalDate"];
        break;
      case "/analytics/iva/reports/provider_validation_report":
        lookerDashboardType = LookerIvaDashboardType.ProviderValidationReport;
        this.filters = ["Project"];
        break;
      case "/analytics/iva/chart_routing":
        lookerDashboardType = LookerIvaDashboardType.ChartRouting;
        this.filters = ["CreateDate"];
        break;
      case "/analytics/iva/reports/dw/sla":
        lookerDashboardType = LookerIvaDashboardType.SLAMilestoneReport;
        this.filters = ["Project"];
        this.hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
        break;
      case "/analytics/iva/dw/pends/summary":
        lookerDashboardType = LookerIvaDashboardType.PendSummaryDW;
        this.filters = ["Project"];
        this.hideFilters=[HideFilters.ORG_ID,HideFilters.PROJECT_ID,HideFilters.USER_ID];
        break;
      case "/analytics/iva/dw/retrieval/summary":
        lookerDashboardType = LookerIvaDashboardType.RetrievalSummaryDw;
        this.filters = ["Project"];
        this.hideFilters=[HideFilters.ORG_ID,HideFilters.PROJECT_ID,HideFilters.USER_ID, HideFilters.PROJECT_NAME2, HideFilters.CUSTOMER_NAME, HideFilters.PROJECT_TYPE_ID2,HideFilters.TEMPLATE_ID2];
        break
      case "/analytics/iva/dw/reports/top_aids_with_open_chases":
        lookerDashboardType = LookerIvaDashboardType.TopAidsWithOpenChases;
        this.filters = ["Project"];
        this.hideFilters=[HideFilters.ORG_ID,HideFilters.PROJECT_ID,HideFilters.USER_ID];
        break
      case "/analytics/iva/reports/user_productivity_report":
      case "/analytics/iva/reports/enr_status_report":
      case "/analytics/iva/reports/rxc_status_report":
      case "/analytics/iva/reports/iva_diagnosis_data":
      case "/analytics/iva/reports/hcc_by_enrollee_ID":
      case "/analytics/iva/reports/hcc_by_stratum":
      case "/analytics/iva/reports/iva_pursuit_prioritization_report":
      case "/analytics/iva/reports/iva_hst_prioritization_report":
      case "/analytics/iva/reports/provider_outreach_by_contact_type_report":
      case "/analytics/iva/reports/iva_overview":
      case "/analytics/iva/clinical/summary":
      case "/analytics/iva/retrieval/summary":
      case "/analytics/iva/retrieval/program_retrieval_projections":
      case "/analytics/iva/clinical/clinical_production_inventory_and_qa_scores":
      case "/analytics/iva/reports/detail_clinical_productivity_and_accuracy_overall":
      case "/analytics/iva/risk_nlp_icd_dashboard_overview":
      case "/analytics/iva/reports/chase_move_back_report":
      case "/analytics/iva/reports/dw/chase_move_back_history_report":
      case "/analytics/iva/reports/chase_move_back_history_report":
      case "/analytics/iva/reports/iva_client_overread_2_history_report":
      case "/analytics/iva/pends/summary":
      case "/analytics/iva/reports/dob_discrepancy":
      case "/analytics/iva/reports/edge_hcc_performance_summary":
      case "/analytics/iva/reports/customer_invoice_detail":
      case "/analytics/iva/reports/chase_move_report":
      case "/analytics/iva/reports/dw/chase_move_report":
      case "/analytics/iva/reports/chase_counts_by_chase_statuses":
      case "/analytics/iva/reports/dw/pend_report":
      case "/analytics/iva/reports/dw/dob_discrepancy":
      case "/analytics/iva/reports/dw/Chase_Status_Underlying":
      case "/analytics/iva/reports/dw/Life_of_Chase":
        lookerDashboardType = this.getDashboardType();
        break;
      default:
        lookerDashboardType = LookerIvaDashboardType.IvaProjectSummary;
        break;
    }
    return lookerDashboardType;
  }
  getDashboardType(): LookerIvaDashboardType {
    let lookerDashboardType: LookerIvaDashboardType;
    switch (this.getRouteUrlValue) {
      case "/analytics/iva/reports/user_productivity_report":
        lookerDashboardType = LookerIvaDashboardType.UserProductivityReport;
        this.filters = ["StartDate", "EndDate"];
        break;
      case "/analytics/iva/reports/enr_status_report":
        lookerDashboardType = LookerIvaDashboardType.ENRStatusReport;
        this.filters = ["Project", "EnrolleeID", "MemberID", "ClientMemberID", "HIOS", "StratumLevel", "LOB", "Products", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/rxc_status_report":
        lookerDashboardType = LookerIvaDashboardType.RXCStatusReport;
        this.filters = ["Project", "EnrolleeID", "MemberID", "ClientMemberID", "ClaimID", "DispensingProviderID", "NDC", "HCPCS", "ChaseID", "HIOS", "StratumLevel", "RXC", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/iva_diagnosis_data":
        lookerDashboardType = LookerIvaDashboardType.IVADiagnosisData;
        this.filters = ["Project", "EnrolleeID", "MemberID", "ClientMemberID", "Measure", "ClaimID", "ReportedByEdge", "ChaseID", "HIOS", "StratumLevel", "HCC", "ChaseStatus", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/hcc_by_enrollee_ID":
        lookerDashboardType = LookerIvaDashboardType.HCCByEnrolleeID;
        this.filters = ["Project", "EnrolleeID", "MemberID", "ClientMemberID", "HIOS", "StratumLevel", "SubmissionStatus", "HCCPosition", "HCC", "ReportedByEdge", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/hcc_by_stratum":
        lookerDashboardType = LookerIvaDashboardType.HCCByStratum;
        this.filters = ["Project", "HIOS", "StratumLevel", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/iva_pursuit_prioritization_report":
        lookerDashboardType = LookerIvaDashboardType.ATTPrioritization;
        this.filters = ["Project", "EnrolleeID", "MemberID", "ClientMemberID", "Measure", "ClaimID", "ChaseID", "HIOS", "StratumLevel", "HCC", "ChaseStatus", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/iva_hst_prioritization_report":
        lookerDashboardType = LookerIvaDashboardType.HSTPrioritization;
        this.filters = ["Project", "EnrolleeID", "MemberID", "ClientMemberID", "ClaimID", "ChaseID", "HIOS", "StratumLevel", "HCC", "ChaseStatus", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/provider_outreach_by_contact_type_report":
        lookerDashboardType = LookerIvaDashboardType.ProviderOutreachByContactTypeReport;
        this.filters = ["Project", "ContactDate", "ContactMethod", "CallType"];
        break;
      case "/analytics/iva/reports/iva_overview":
        lookerDashboardType = LookerIvaDashboardType.IVAOverview;
        this.filters = ["Project", "Products", "Address", "HCC"];
        break;
      case "/analytics/iva/clinical/summary":
        lookerDashboardType = LookerIvaDashboardType.ClinicalSummary;
        this.filters = ["Project", "Products", "Address", "HCC", "LOB", "RetrievalOwner"];
        break;
      case "/analytics/iva/retrieval/summary":
        lookerDashboardType = LookerIvaDashboardType.RetrievalSummary;
        this.filters = ["Project", "Measure", "Address", "LOB", "Products", "RetrievalOwner", "ExpectedRetrieval", "ProjectStatus", "VendorName", "SpecialHandling", "VendorInvoiceType"];
        break;
      case "/analytics/iva/retrieval/program_retrieval_projections":
        lookerDashboardType = LookerIvaDashboardType.ProgramRetrievalProjections;
        this.filters = ["BenefitYear", "DateRange"];
        break;
      case "/analytics/iva/clinical/clinical_production_inventory_and_qa_scores":
        lookerDashboardType = LookerIvaDashboardType.IvaClinicalProductionInventoryQAScores;
        this.filters = ["Client", "Project"];
        break;
      case "/analytics/iva/reports/detail_clinical_productivity_and_accuracy_overall":
        lookerDashboardType = LookerIvaDashboardType.DetailClinicalProductivityAndAccuracyOverall;
        this.filters = ["Project", "AbstractionDate"];
        break;
      case "/analytics/iva/risk_nlp_icd_dashboard_overview":
        lookerDashboardType = LookerIvaDashboardType.NlpIcdDashboard;
        this.filters = ["Project", "Address", "DOS"];
        break;
      case "/analytics/iva/reports/chase_move_back_report":
        lookerDashboardType = LookerIvaDashboardType.ChaseMoveBackReport;
        this.filters = ["Project"];
        break;
      case "/analytics/iva/reports/chase_move_back_history_report":
        lookerDashboardType = LookerIvaDashboardType.ChaseMoveBackHistoryReportLegacy;
        this.filters = ["Project", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/dw/chase_move_back_history_report":
        lookerDashboardType = LookerIvaDashboardType.ChaseMoveBackHistoryReport;
        this.filters = ["Project", "LOB", "Products", "RetrievalOwner"];
        this.hideFilters = [HideFilters.PROJECT_ID,HideFilters.USER_ID,HideFilters.ORG_ID,HideFilters.MOVEBACK_DATE,HideFilters.HEALTH_PLAN,
          HideFilters.LINE_OF_BUSINESS,HideFilters.CONTRACT_NUMBER,HideFilters.CO_RETRIEVAL,HideFilters.PRODUCT,HideFilters.MOVEBACK_BY];
        break;
      case "/analytics/iva/reports/iva_client_overread_2_history_report":
        lookerDashboardType = LookerIvaDashboardType.IvaClientOverread2HistoryReport;
        this.filters = ["Project", "HIOS", "OverreadDate", "OverreadUser", "RetrievalOwner"];
        break;
      case "/analytics/iva/pends/summary":
        lookerDashboardType = LookerIvaDashboardType.PendSummaryNew;
        this.filters = ["Project", "Address", "Measure", "PendOwner", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/dob_discrepancy":
        lookerDashboardType = LookerIvaDashboardType.DobDiscrepancy;
        this.filters = ["Project", "LOB", "Products", "RetrievalOwner"];
        break;
      case "/analytics/iva/reports/edge_hcc_performance_summary":
        lookerDashboardType = LookerIvaDashboardType.EdgeHccPerformanceSummary;
        this.filters = ["Project", "HCC"];
        break;
      case "/analytics/iva/reports/customer_invoice_detail":
        lookerDashboardType = LookerIvaDashboardType.CustomerInvoiceDetail;
        this.filters = ["Project", "BillingDate"];
        break;
      case "/analytics/iva/reports/chase_move_report":
        lookerDashboardType = LookerIvaDashboardType.ChaseMoveReport;
        this.filters = ["Project", "LOB", "Products", "RetrievalOwner", "ExpectedRetrieval", "HealthPlan", "ContractNumber", "MoveDateStart", "MoveDateEnd"];
        this.hideFilters = [HideFilters.PROJECT_ID,HideFilters.USER_ID2,HideFilters.ORG_ID,HideFilters.PROJECT_NAME,HideFilters.HEALTH_PLAN,HideFilters.LINE_OF_BUSINESS,
          HideFilters.CONTRACT_NUMBER,HideFilters.CO_RETRIEVAL2,HideFilters.PRODUCT,HideFilters.MOVE_DATE,HideFilters.EXPECTED_RETRIEVAL2];
        break;
      case "/analytics/iva/reports/dw/chase_move_report":
        lookerDashboardType = LookerIvaDashboardType.ChaseMoveReportDW;
        this.filters = ["Project"];
        this.hideFilters = [HideFilters.PROJECT_ID,HideFilters.USER_ID,HideFilters.ORG_ID,HideFilters.PROJECT_NAME];
        break;
      case "/analytics/iva/reports/chase_counts_by_chase_statuses":
        lookerDashboardType = LookerIvaDashboardType.ChaseCountsByStatusReport;
        this.filters = ["Project", "LOB", "Products", "RetrievalOwner", "CurrentChaseStatus", "CurrentProcessStep", "ExpectedRetrieval", "VendorInvoiceType", "VendorName"];
        this.hideFilters = [
          HideFilters.USER_ID2,
          HideFilters.ORG_ID,
          HideFilters.PROJECT_ID,
          HideFilters.HEALTH_PLAN,
          HideFilters.CONTRACT_NUMBER,
          HideFilters.PROJECT_NAME2,
          HideFilters.PRODUCT,
          HideFilters.LINE_OF_BUSINESS,
          HideFilters.CO_RETRIEVAL2,
          "Current_Chase_Status",
          "Current_Process_Step",
          "Expected_Retrieval",
          "Chase_Tags",
          "Vendor_Name",
          "Vendor_Invoice_Type",
          "Organization_Name"
        ];
        break;
      case "/analytics/iva/reports/dw/pend_report":
        lookerDashboardType = LookerIvaDashboardType.PendReportDW;
        this.filters = ["Project"];
        this.hideFilters=[HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID];
        break;
      case "/analytics/iva/reports/dw/dob_discrepancy":
        lookerDashboardType = LookerIvaDashboardType.DobDiscrepancyDW;
        this.filters = ["Project"];
        this.hideFilters = [HideFilters.PROJECT_ID,HideFilters.ORG_ID,HideFilters.USER_ID2];
        break;
      case "/analytics/iva/reports/dw/Chase_Status_Underlying":
        lookerDashboardType = LookerIvaDashboardType.ChaseStatusUnderlyingReport;
        this.filters = ["Project"];
        this.hideFilters = [HideFilters.PROJECT_ID,HideFilters.ORG_ID,HideFilters.USER_ID];
        break;
      case "/analytics/iva/reports/dw/Life_of_Chase":
        lookerDashboardType = LookerIvaDashboardType.LifeOfAChaseDW;
        this.filters = ["Project"];
        this.hideFilters = [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN2, HideFilters.CONTRACT_NUMBER,
          HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.MEASURE2, HideFilters.PROJECT_NAME2, "Chase_ID", "Customer_Name"];
        break;
      default:
        lookerDashboardType = LookerIvaDashboardType.IvaProjectSummary;
        break;
    }
    return lookerDashboardType;
  }
  showFilter(filter: string): boolean {
    return this.filters.includes(filter);
  }

  private getContractNumbers(): void {
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    const request = new EntityAttribute({
      projectIdsAsCsv: selectedProjectIds,
      projectTypeId: ProjectType.HEDIS,
      attributeId: CONTRACT_NUMBER.attributeId,
    });
    this.analyticsService.getEntityAttributes(request).pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.contractNumberInput = new CheckboxGroup({ ...this.contractNumberInput, options } as any);
        this.setControlValue(this.contractNumberInput);
        this.formService.updateDom.next();
      });
  }

  showFiltersOrGetData(): void {
    const filterData = this.session.get(this.getRouteUrlValueForSession, new AnalyticsRequest());
    if (StringHelper.isAvailable(this.getRouteUrlValueForSession) && this.session.get(this.getRouteUrlValueForSession, null)) {
      const sessionAnalyticsFilters = this.session.get(this.getRouteUrlValueForSession, null);
      this.analyticsFilter = sessionAnalyticsFilters;
    } else { this.analyticsFilter = filterData; }
    if (this.filters.includes("ReportedByEdge") && StringHelper.isAvailable(this.analyticsFilter.reportedByEdge)) {
      this.form.get(this.reportedByEdgeInput.key).setValue(this.analyticsFilter.reportedByEdge);
    }
    this.dashboardType = String(this.pageType);
    const filterResult = Object.keys(filterData).map(key => ({ key, value: filterData[key] }));
    if (this.filters.includes("Project")) {
      this.projectsInput =
        new CheckboxGroup({
          key: "Projects",
          label: "IVA Projects",
          validators: [Validators.required],
          errorMessages: {
            required: "Please select at least one Project.",
          },
        });
      this.isFiltersVisible = filterResult.filter(c => c.value).length < 2;
      if (this.filters.includes("UpdateDate")) {
        this.updateDateFrom =
          new Calendar({
            key: "updateFromDate",
            label: "From",
            placeholder: "Enter From Date",
            maxDate: new Date(),
            validators: [Validators.required],
            errorMessages: {
              required: "Please select Update Date.",
            },
          });
        this.isFiltersVisible = filterResult.filter(c => c.value).length < 2;
      }
    }
    this.getAbstractDateValidation();
    this.CreateForm();
    this.setServiceOrganizationValidation();
    if (!this.isFiltersVisible) {
      this.getLookerUrl(false);
    }
    this.session.delete(this.getRouteUrlValueForSession);
  }
  private setServiceOrganizationValidation(): void {
    if (this.isParentOrg) {
      this.form.get('organization').setValidators([Validators.required]);
      this.form.get('organization').updateValueAndValidity();
      this.errorMessage = "Please select at least one Service Organization.";
      this.isFiltersVisible = !StringHelper.isAvailable(this.analyticsFilter.serviceOrgIdsAsCsv);
    }
    else {
      this.form.get('organization').clearValidators();
      this.form.get('organization').updateValueAndValidity();
    }
  }
  getAllSelectableInputs(): void {
    if (!this.isParentOrg) {
      this.getProjects();
    }
    this.getProducts();
    this.getRetreivalTypes();
    this.getOrganizations();
  }
  resetAllFilters(): void {
    this.showErrorMessage = false;
    this.dateErrorMessage = "";
    this.isDateErrorExist = false;
    this.form.reset();
    if (this.filters.includes("Project")) {
      this.isVisible = false;
      this.session.delete(this.getRouteUrlValueForSession);
    } else { this.getLookerUrl(false); }
    this.removeFilter.emit(this.formFilterRequest);
  }
  getMenuItems() {
    const analyticsItemRequest = new AnalyticsItemRequest({
      projectType: ProjectType.IVA,
      reportUrl: this.getRouteUrlValue,
    });

    this.analyticsService
      .getAnalyticMenuItems(analyticsItemRequest)
      .subscribe(menuItem => {
        this.menuItems = this.assignAndNotify(menuItem);
      });
  }
  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const items = List(data);
    return items;
  }
  onRemoveFilter(event: FormGroup) {
    this.removeFilter.emit(event);
    const projectFilteredValues = [];
    const measureFilteredValues = [];
    this.pendCodesFilteredValues = [];
    this.workFlowStatusFilteredValues = [];
    this.hiosFilteredValues = [];
    this.rxcFilteredValues = [];
    this.stratumLevelFilteredValues = [];
    this.submissionStatusFilteredValues = [];
    this.hccPositionFilteredValues = [];
    this.hccFilteredValues = [];
    this.chaseStatusFilteredValues = [];
    this.organizationFilteredValues = [];
    this.pendStatusFilteredValues = [];
    Object.keys(event.controls).forEach(key => {
      const controlValue = this.formFilterRequest.get(key);
      if (ArrayHelper.isAvailable(controlValue.value)) {
        controlValue.value.forEach(selectedValue => {
          const projectFilter = (key === selectedValue.text) ? this.projectsInput.options.find(a => a.text === selectedValue.value)
            : this.projectsInput.options.find(a => a.text === selectedValue.text);
          const measureFilter = (key === selectedValue.text) ? this.measuresInput.options.find(a => a.text === selectedValue.value)
            : this.measuresInput.options.find(a => a.text === selectedValue.text);
          const pendStatusFilter = (key === selectedValue.text) ? this.pendStatusInput.options.find(a => a.text === selectedValue.value)
            : this.pendStatusInput.options.find(a => a.text === selectedValue.text);
          const pendCodesFilter = (key === selectedValue.text) ? this.pendCodesInput.options.find(a => a.text === selectedValue.value)
            : this.pendCodesInput.options.find(a => a.text === selectedValue.text);
          const organizationFilter = (key === selectedValue.text) ? this.organizationInput.options.find(a => a.text === selectedValue.value)
            : this.organizationInput.options.find(a => a.text === selectedValue.text);
          const benefitYearFilter = (key === selectedValue.text) ? this.benefitYearInput.options.find(a => a.text === selectedValue.value)
            : this.benefitYearInput.options.find(a => a.text === selectedValue.text);
          const clientFilter = (key === selectedValue.text) ? this.clientsInput.options.find(a => a.text === selectedValue.value)
            : this.clientsInput.options.find(a => a.text === selectedValue.text);
          let workFlowStatusFilter;
          if (this.filters.includes("workflowStatus")) {
            workFlowStatusFilter = (key === selectedValue.text) ? this.workflowStatusInput.options.find(a => a.text === selectedValue.value)
              : this.workflowStatusInput.options.find(a => a.text === selectedValue.text);
          }
          const rxcFilter = (key === selectedValue.text) ? this.rxcInput.options.find(a => a.text === selectedValue.value)
            : this.rxcInput.options.find(a => a.text === selectedValue.text);
          const stratumLevelFilter = (key === selectedValue.text) ? this.stratumLevelInput.options.find(a => a.text === selectedValue.value)
            : this.stratumLevelInput.options.find(a => a.text === selectedValue.text);
          const submissionStatusFilter = (key === selectedValue.text) ? this.submissionStatusInput.options.find(a => a.text === selectedValue.value)
            : this.submissionStatusInput.options.find(a => a.text === selectedValue.text);
          const hccPositionFilter = (key === selectedValue.text) ? this.hccPositionInput.options.find(a => a.text === selectedValue.value)
            : this.hccPositionInput.options.find(a => a.text === selectedValue.text);
          const hccFilter = (key === selectedValue.text) ? this.hccInput.options.find(a => a.text === selectedValue.value)
            : this.hccInput.options.find(a => a.text === selectedValue.text);
          const chaseStatusFilter = (key === selectedValue.text) ? this.chaseStatusInput.options.find(a => a.text === selectedValue.value)
            : this.chaseStatusInput.options.find(a => a.text === selectedValue.text);
          if (typeof projectFilter === "object" && projectFilter != null) {
            projectFilteredValues.push({ text: projectFilter.text, value: projectFilter.value });
            this.form.get(this.projectsInput.key).setValue(projectFilteredValues);
          }
          if (typeof measureFilter === "object" && measureFilter != null) {
            measureFilteredValues.push({ text: measureFilter.text, value: measureFilter.value });
            this.form.get(this.measuresInput.key).setValue(measureFilteredValues);
          }
          this.setWorkFlowStatusFilterValue(workFlowStatusFilter);
          this.setPendCodesFilterValue(pendCodesFilter);
          this.setPendStatusFilterValue(pendStatusFilter);
          this.setRxcFilterValue(rxcFilter);
          this.setHccFilterValue(hccFilter);
          this.setHccPositionFilterValue(hccPositionFilter);
          this.setSubmissionStatusFilterValue(submissionStatusFilter);
          this.setStratumLevelFilterValue(stratumLevelFilter);
          this.setChaseStatusFilterValue(chaseStatusFilter);
          this.setOrganizationFilterValue(organizationFilter);
          this.setBenefitYearFilterValue(benefitYearFilter);
          this.setClientFilterValue(clientFilter);
        });
      }
    });
    if (this.filters.includes("Project")) {
      if (ArrayHelper.isAvailable(projectFilteredValues)) {
        this.getLookerUrl(true);
      } else {
        const analyticsRequest = this.bindFilterData();
        this.session.put(this.getRouteUrlValueForSession, analyticsRequest);
        this.isFiltersVisible = true;
        this.isVisible = false;
      }
    } else {
      this.getLookerUrl(true);
    }
  }
  setRxcFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.rxcFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.rxcInput.key).setValue(this.hiosFilteredValues);
    }
  }
  setHccFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.hccFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.hccInput.key).setValue(this.hccFilteredValues);
    }
  }
  setHccPositionFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.hccPositionFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.hccPositionInput.key).setValue(this.hccPositionFilteredValues);
    }
  }
  setSubmissionStatusFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.submissionStatusFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.submissionStatusInput.key).setValue(this.submissionStatusFilteredValues);
    }
  }
  setStratumLevelFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.stratumLevelFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.stratumLevelInput.key).setValue(this.stratumLevelFilteredValues);
    }
  }
  setChaseStatusFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.chaseStatusFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.chaseStatusInput.key).setValue(this.chaseStatusFilteredValues);
    }
  }
  setWorkFlowStatusFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.workFlowStatusFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.workflowStatusInput.key).setValue(this.workFlowStatusFilteredValues);
    }
  }
  setPendCodesFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.pendCodesFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.pendCodesInput.key).setValue(this.pendCodesFilteredValues);
    }
  }
  setPendStatusFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.pendStatusFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.pendStatusInput.key).setValue(this.pendStatusFilteredValues);
    }
  }
  setOrganizationFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.organizationFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.organizationInput.key).setValue(this.organizationFilteredValues);
    }
  }
  setBenefitYearFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.benefitYearFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.benefitYearInput.key).setValue(this.benefitYearFilteredValues);
    }
  }
  setClientFilterValue(filter: any) {
    if (typeof filter === "object" && filter != null) {
      this.clientFilteredValues.push({ text: filter.text, value: filter.value });
      this.form.get(this.clientsInput.key).setValue(this.clientFilteredValues);
    }
  }
  get getRouteUrlValue(): string {
    return this.router.url;
  }
  get getRouteUrlValueForSession(): string {
    return `${window.location.href}/${this.organizationId}`;
  }
  private setControlValue(control: CheckboxGroup, isStringValue = true): void {
    let filters: string[];
    if (control.key === "Projects" && StringHelper.isAvailable(this.analyticsFilter.projectIdsAsCsv)) {
      filters = this.analyticsFilter.projectIdsAsCsv.split(",");
    } else if (control.key === "Measures" && StringHelper.isAvailable(this.analyticsFilter.measureIdsAsCsv)) {
      filters = this.analyticsFilter.measureIdsAsCsv.split(",");
    } else if (control.key === this.pendStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.pendStatusCsv)) {
      filters = this.analyticsFilter.pendStatusCsv.split(",");
    } else if (control.key === this.pendCodesInput.key && StringHelper.isAvailable(this.analyticsFilter.pendTypeIdCsv)) {
      filters = this.analyticsFilter.pendTypeIdCsv.split(",");
    } else if (control.key === this.workflowStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.workflowStatusCsv)) {
      filters = this.analyticsFilter.workflowStatusCsv.split(",");
    } else if (control.key === this.organizationInput.key && StringHelper.isAvailable(this.analyticsFilter.serviceOrgIdsAsCsv)) {
      filters = this.analyticsFilter.serviceOrgIdsAsCsv.split(",");
    } else if (control.key === this.benefitYearInput.key && StringHelper.isAvailable(this.analyticsFilter.benefitYear)) {
      filters = this.analyticsFilter.benefitYear.split(",");
    } else if (control.key === this.clientsInput.key && StringHelper.isAvailable(this.analyticsFilter.clientIdsAsCsv)) {
      filters = this.analyticsFilter.clientIdsAsCsv.split(",");
    } else if (control.key === this.pendOwnerInput.key && StringHelper.isAvailable(this.analyticsFilter.pendOwnerAsCsv)) {
      filters = this.analyticsFilter.pendOwnerAsCsv.split(",");
    } else if (control.key === this.lineOfBusinessInput.key && StringHelper.isAvailable(this.analyticsFilter.lineOfBusinessAsCsv)) {
      filters = this.analyticsFilter.lineOfBusinessAsCsv.split(",");
    } else if (control.key === this.retrievalOwnerInput.key && StringHelper.isAvailable(this.analyticsFilter.retrievalOwner)) {
      filters = this.analyticsFilter.retrievalOwner.split(",");
    } else if (control.key === this.expectedRetrievalInput.key && StringHelper.isAvailable(this.analyticsFilter.expectedRetrieval)) {
      filters = this.analyticsFilter.expectedRetrieval.split(",");
    } else if (control.key === this.projectStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.projectStatus)) {
      filters = this.analyticsFilter.projectStatus.split(",");
    } else if (control.key === this.vendorNameInput.key && StringHelper.isAvailable(this.analyticsFilter.vendorName)) {
      filters = this.analyticsFilter.vendorName.split(",");
    } else if (control.key === this.specialHandlingInput.key && StringHelper.isAvailable(this.analyticsFilter.specialHandling)) {
      filters = this.analyticsFilter.specialHandling.split(",");
    } else if (control.key === this.healthPlanInput.key && StringHelper.isAvailable(this.analyticsFilter.healthPlanNamesAsCsv)) {
      filters = this.analyticsFilter.healthPlanNamesAsCsv.split(",");
    } else if (control.key === this.contractNumberInput.key && StringHelper.isAvailable(this.analyticsFilter.contractNumbersAsCsv)) {
      filters = this.analyticsFilter.contractNumbersAsCsv.split(",");
    }  else if (control.key === this.currentChaseStatus.key && StringHelper.isAvailable(this.analyticsFilter.currentChaseStatus)) {
      filters = this.analyticsFilter.currentChaseStatus.split(",");
    }  else if (control.key === this.currentProcessStep.key && StringHelper.isAvailable(this.analyticsFilter.currentProcessStep)) {
      filters = this.analyticsFilter.currentProcessStep.split(",");
    } else if (control.key === this.vendorInvoiceType.key && StringHelper.isAvailable(this.analyticsFilter.vendorInvoiceType)) {
      filters = this.analyticsFilter.vendorInvoiceType.split(",");
    } else if (control.key === this.pendTypeInput.key && StringHelper.isAvailable(this.analyticsFilter.pendTypeAsCsv)) {
      filters = this.analyticsFilter.pendTypeAsCsv.split(",");
    }
    filters = this.setHiosControlValues(control, filters);
    if (ArrayHelper.isAvailable(filters)) {
      const filterValue = [];
      filters.forEach(item => {
        if (ArrayHelper.isAvailable(control.options)) {
          const controlValues = isStringValue ? control.options.find(a => a.text === item) : control.options.find(a => a.value === item || a.value === +item);
          filterValue.push(controlValues);
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(control.key).setValue(control.selectedOptions);
    }
  }
  setHiosControlValues(control: CheckboxGroup, filters: string[]): string[] {
    let filter = [];
    if (control.key === this.retrievalTypeInput.key && StringHelper.isAvailable(this.analyticsFilter.retrievalTypeAsCsv)) {
      filter = this.analyticsFilter.retrievalTypeAsCsv.split(",");
    } else if (control.key === this.stratumLevelInput.key && StringHelper.isAvailable(this.analyticsFilter.stratumLevelAsCsv)) {
      filter = this.analyticsFilter.stratumLevelAsCsv.split(",");
    } else if (control.key === this.hccPositionInput.key && StringHelper.isAvailable(this.analyticsFilter.hccPositionAsCsv)) {
      filter = this.analyticsFilter.hccPositionAsCsv.split(",");
    } else if (control.key === this.submissionStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.submissionStatusAsCsv)) {
      filter = this.analyticsFilter.submissionStatusAsCsv.split(",");
    } else if (control.key === this.hccInput.key && StringHelper.isAvailable(this.analyticsFilter.hccAsCsv)) {
      filter = this.analyticsFilter.hccAsCsv.split(",");
    } else if (control.key === this.chaseStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.chaseStatusAsCsv)) {
      filter = this.analyticsFilter.chaseStatusAsCsv.split(",");
    }
    return ArrayHelper.isAvailable(filter) ? filter : filters;
  }
  private setFilteredInputValue(formControls: FormGroup, analyticsFilter: AnalyticsRequest): void {
    Object.keys(formControls.controls).forEach(key => {
      for (const analyticFilterKey of Object.keys(analyticsFilter)) {
        this.pFormGroupKey = analyticFilterKey;
        if (key === analyticFilterKey || key === this.controlKeyToMatch) {
          const analyticFilterValue = analyticsFilter[analyticFilterKey];
          this.form.get(key).setValue(analyticFilterValue);
        }
      }
    });
  }
  get controlKeyToMatch(): string {
    let controlKey: string;
    switch (this.pFormGroupKey) {
      case "productName":
        controlKey = "products";
        break;
      case "addressId":
        controlKey = "address";
        break;
      default:
        break;
    }
    return controlKey;
  }
  private setPendCodesControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.pendTypeIdCsv)) {
      const pendFilteredValues = this.analyticsFilter.pendTypeIdCsv.split(",");
      pendFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.pendCodesInput.options)) {
          filterValue.push(this.pendCodesInput.options.find(a => a.value === Number(item)));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.pendCodesInput.key).setValue(control.selectedOptions);
    }
  }
  getPendCodes() {
    this.createPendService.getPendDropdown().pipe(map((result: any) => {
      return result.pendCodes.map(item => new SelectableInput({ text: item.displayName.split("-")[0], value: item.pendTypeId }));
    })).subscribe(options => {
      this.pendCodesInput = new CheckboxGroup({ ...this.pendCodesInput, options } as any);
      this.setPendCodesControlValue(this.pendCodesInput);
      this.formService.updateDom.next();
    });
  }

  private getPendStatus(): void {
    this.createPendService.getPendStatus(false).pipe(map((result: any) => {
      return result.pendStatus.map(item => new SelectableInput({ text: item.description, value: item.description }));
    })).subscribe(result => {
      this.pendStatusInput = { ...this.pendStatusInput, options: result } as any;
      this.setControlValue(this.pendStatusInput, true);
      this.formService.updateDom.next();
    });
  }
  private getWorkflowStatus(): void {
    this.bulkUpdateChaseService.getWorkflowStatusesList().subscribe(result => {
      const workflowStatus = result.map(item => new SelectableInput({ text: item.workflowStatusName, value: item.workflowStatusName }));
      this.workflowStatusInput = { ...this.workflowStatusInput, options: workflowStatus } as any;
      this.currentProcessStep = new CheckboxGroup({ ...this.currentProcessStep, options: workflowStatus } as any);
      this.setControlValue(this.currentProcessStep, true);
      this.setControlValue(this.workflowStatusInput, true);
      this.formService.updateDom.next();
    });
  }
  getFilterValue(key: string, isCsvValue = true, isUseValue = true): string {
    if (this.isFilterUpdated && isCsvValue) {
      return this.analyticsService.getValueForAnalyticsRequestAsCsv(this.form.get(key).value, isUseValue);
    } else if (this.isFilterUpdated) {
      return this.form.get(key).value;
    } else {
      switch (key) {
        case "Projects":
          return this.analyticsFilter.projectIdsAsCsv;
        case "Measures":
          return this.analyticsFilter.measureIdsAsCsv;
        case "pendCodes":
          if (isUseValue) {
            return this.analyticsFilter.pendTypeIdCsv;
          }
          else {
            return this.analyticsFilter.pendCodeCsv
          }
        case "pendStatus":
          return this.analyticsFilter.pendStatusCsv;
        case this.retrievalTypeInput.key:
          return this.analyticsFilter.retrievalTypeAsCsv;
        case this.workflowStatusInput.key:
          return this.analyticsFilter.workflowStatusCsv;
        case this.hccPositionInput.key:
          return this.analyticsFilter.hccPositionAsCsv;
        case this.submissionStatusInput.key:
          return this.analyticsFilter.submissionStatusAsCsv;
        case this.stratumLevelInput.key:
          return this.analyticsFilter.stratumLevelAsCsv;
        case this.hccInput.key:
          return this.analyticsFilter.hccAsCsv;
        case this.chaseStatusInput.key:
          return this.analyticsFilter.chaseStatusAsCsv;
        case this.rxcInput.key:
          return this.analyticsFilter.rxcAsCsv;
        case this.addressIdInput.key:
        case this.productsInput.key:
        case this.organizationInput.key:
        case this.benefitYearInput.key:
        case this.clientsInput.key:
          return this.getFiltersValue(key);
        case this.pendOwnerInput.key:
          return this.analyticsFilter.pendOwnerAsCsv;
        case this.lineOfBusinessInput.key:
          return this.analyticsFilter.lineOfBusinessAsCsv;
        case this.retrievalOwnerInput.key:
          return this.analyticsFilter.retrievalOwner;
        case this.expectedRetrievalInput.key:
          return this.analyticsFilter.expectedRetrieval;
        case this.projectStatusInput.key:
          return this.analyticsFilter.projectStatus;
        case this.vendorNameInput.key:
          return this.analyticsFilter.vendorName;
        case this.specialHandlingInput.key:
          return this.analyticsFilter.specialHandling;
        case this.healthPlanInput.key:
          return this.analyticsFilter.healthPlanNamesAsCsv;
        case this.contractNumberInput.key:
          return this.analyticsFilter.contractNumbersAsCsv;
        case this.vendorInvoiceType.key:
          return this.analyticsFilter.vendorInvoiceType;
        case this.pendTypeInput.key:
          return this.analyticsFilter.pendTypeAsCsv;
        default:
          return this.analyticsFilter[key];
      }
    }
  }
  getFiltersValue(key: string): string {
    switch (key) {
      case this.addressIdInput.key:
        return this.analyticsFilter.addressId;
      case this.productsInput.key:
        return this.analyticsFilter.productName;
      case this.organizationInput.key:
        return this.analyticsFilter.serviceOrgIdsAsCsv;
      case this.benefitYearInput.key:
        return this.analyticsFilter.benefitYear;
      case this.clientsInput.key:
        return this.analyticsFilter.clientIdsAsCsv;
      default:
        return this.analyticsFilter[key];
    }
  }
  private getRetreivalTypes(): void {
    this.retrievalDocumentService.getRetrievalTypes().pipe(map((result: any) => {
      return result.map(item => new SelectableInput({ text: item.text, value: item.text }));
    })).subscribe(result => {
      this.retrievalTypeInput = new CheckboxGroup({ ...this.retrievalTypeInput, options: result } as any);
      this.setControlValue(this.retrievalTypeInput, false);
      this.changeDetector.markForCheck();
    });
  }
  get isValidForm(): boolean {
    let result = true;
    if (this.form.invalid) {
      this.formService.markAllAsTouched(this.form);
      this.changeDetector.markForCheck();
      result = false;
    }
    return result;
  }

  onSelectChange(): void {
    this.getRxc();
    this.getHccListByProject();
    this.getHealthPlans();
    this.getContractNumbers();
  }

  onOrganizationChange(): void {
    this.loadClientOrProjectControl();
  }
  loadClientOrProjectControl(): void {
    if (this.filters.includes("Client")) {
      this.getClients();
    } else {
      this.getProjects();
    }
  }
  onClientChange(): void {
    this.getProjects();
  }
  getAbstractDateValidation() {
    if (this.filters.includes("Overread2Date")) {
      this.dateFilterInput = this.overread2FromDate;
      this.toDateFilterInput = this.overread2ToDate;
      this.currentDateFilters = "Overread2Date";
      this.currentDateFilterTab = "Overread2 Date";
      const overread2FromDate = this.form.get(this.overread2FromDate.key).value;
      this.overread2FromDate = new Calendar({
        ...this.overread2FromDate,
        value: overread2FromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("PendDate")) {
      this.dateFilterInput = this.pendCreateDateFrom;
      this.toDateFilterInput = this.pendCreateDateTo;
      this.currentDateFilters = "PendDate";
      this.currentDateFilterTab = "Pend Date";
      const pendCreateDateFrom = this.form.get(this.pendCreateDateFrom.key).value;
      this.pendCreateDateFrom = new Calendar({
        ...this.pendCreateDateFrom,
        value: pendCreateDateFrom,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("BillingDate")) {
      this.dateFilterInput = this.billingDateFrom;
      this.toDateFilterInput = this.billingDateTo;
      this.currentDateFilters = "BillingDate";
      this.currentDateFilterTab = "Billing Date";
      const billingDateFrom = this.form.get(this.billingDateFrom.key).value;
      this.billingDateFrom = new Calendar({
        ...this.billingDateFrom,
        value: billingDateFrom,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("UpdateDate")) {
      this.dateFilterInput = this.updateDateFrom;
      this.toDateFilterInput = this.updateDateTo;
      this.currentDateFilters = "UpdateDate";
      this.currentDateFilterTab = "Update Date";
      const updateDateTo = this.form.get(this.updateDateTo.key).value;
      this.updateDateTo = new Calendar({
        ...this.updateDateTo,
        value: updateDateTo,
        validators: [Validators.required],
        placeholder: "Enter To Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("ContactDate")) {
      this.dateFilterInput = this.contactDateFromInput;
      this.toDateFilterInput = this.contactDateToInput;
      this.currentDateFilters = "ContactDate";
      this.currentDateFilterTab = "Contact Date";
      const contactDateFromInput = this.form.get(this.contactDateFromInput.key).value;
      this.contactDateFromInput = new Calendar({
        ...this.contactDateFromInput,
        value: contactDateFromInput,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("CreateDate")) {
      this.dateFilterInput = this.createFromDate;
      this.toDateFilterInput = this.createToDate;
      this.currentDateFilters = "CreateDate";
      this.currentDateFilterTab = "Create Date";
      const createFromDate = this.form.get(this.createFromDate.key).value;
      this.createFromDate = new Calendar({
        ...this.createFromDate,
        value: createFromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a End Date that is after the Start Date.",
        },
      } as any);
    }
  }
  private getHccListByProject(): void {
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedProjectIds)) {
      this.analyticsRiskService
        .getHccListByProject(selectedProjectIds)
        .pipe(map(this.automapper.curryMany("HccModel", "SelectableInput")))
        .subscribe(options => this.bindHccControl(options));
    }
  }
  private bindHccControl(options: any): void {
    this.hccInput = new CheckboxGroup({ ...this.hccInput, options } as any);
    this.setHccControlValue(this.hccInput);
    this.formService.updateDom.next();
  }

  updateMinDate(fromDate: Calendar, toDate: Calendar): void {
    this.moveDateEnd = new Calendar({ ...toDate, minDate: new Date(this.form.get(fromDate.key).value)})
    this.changeDetector.markForCheck();
    this.formService.updateDom.next();
  }

  private getCurrentChaseStatus(): void {
    this.genericGet(this.chaseQueryService, "getReportingStatusesList", (options) => {
      options = [...options.map(o => new SelectableInput({ text: o?.reportingStatusName, value: o?.reportingStatusName }))];
      this.currentChaseStatus = new CheckboxGroup({ ...this.currentChaseStatus, options } as any);
        this.setControlValue(this.currentChaseStatus, true);
        this.formService.updateDom.next();
    }, false);
  }

  private getVendorInvoiceTypes(): void {
    this.analyticsService.getVendorInvoiceTypes().subscribe(
      options => {
        const mappedOptions = options.map(o => new SelectableInput({ text: o.name, value: o.name }));
        this.vendorInvoiceType = new CheckboxGroup({ ...this.vendorInvoiceType, options: mappedOptions});
        this.setControlValue(this.vendorInvoiceType, false);
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      }
    );
  }

  showHelpModal(): void {
    this.isHelpModalOpen = true;
  }

}
