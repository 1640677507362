import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { Observable, Subject, Subscription, of } from "rxjs";
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../auth/auth.service";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ChasePendItem } from "../chase-pend-item.model";
import { CreateEditPendDirective } from "../create-edit-pend-component.model";
import { CreatePendService } from "../create-pend.service";
import { PendType } from "../pend-type.enum";

@Component({
  selector: "app-create-pend-modal",
  templateUrl: "./create-pend-modal.component.html",
  styleUrls: ["./create-pend-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePendModalComponent extends CreateEditPendDirective implements OnInit, OnDestroy {
  private sink = new SubSink();
  private unsubscribe = new Subject();
  private getPendReasonsSubscription: Subscription;
  @Input() chaseIds: number[];
  inputs: DynamicInput[];
  isConfirmationModalVisible = false;

  constructor(
    protected readonly formService: FormService,
    protected readonly automapper: AutomapperService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly messagingService: MessagingService,
    protected readonly createPendService: CreatePendService,
    protected readonly authService: AuthService

  ) {
    super(formService, automapper, changeDetector, messagingService, createPendService, authService);
  }

  ngOnInit() {
    this.setInputs();
    super.ngOnInit();

    this.onShow
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.resetForm();
        this.pendCodesInput = new Dropdown({ ...this.pendCodesInput, disabled: false, hidden: false } as any);
        this.createForm();
        this.setInputs();
        this.subscribeToGetPendReasons();
        this.setControlsVisibiltyForPC304();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.sink.unsubscribe();
  }


  get chaseIdsText(): string {
    if (!ArrayHelper.isAvailable(this.chaseIds)) {
      return "";
    }

    return this.chaseIds.join(", ");
  }


  checkIfInvoicePendCode(): void {
    // TODO: Clean up.
    const pend = this.pendCodesInput.getSelectedOption(this.form);
    const hasPend = pend != null;
    if (hasPend) {
      this.form.get(this.ownerInput.key).setValue(pend.extra.owner);

      if (NumberHelper.isGreaterThan(pend.extra.status, 0)) {
        this.form.get(this.statusInput.key).setValue(pend.extra.status);
      }
    }

    if (hasPend && pend.extra.isThirdParty) {
      this.form.get(this.companyInput.key).enable();
      this.companyInput = new Dropdown({ ...this.companyInput, hidden: false } as any);

      this.form.get(this.amountInput.key).enable();
      this.amountInput = new Textbox({ ...this.amountInput, hidden: false } as any);

      this.form.get(this.invoiceInput.key).enable();
      this.invoiceInput = new Textbox({ ...this.invoiceInput, hidden: false } as any);
    } else {
      this.form.get(this.companyInput.key).setValue(null);
      this.form.get(this.companyInput.key).disable();
      this.companyInput = new Dropdown({ ...this.companyInput, hidden: true } as any);

      this.form.get(this.amountInput.key).setValue(null);
      this.form.get(this.amountInput.key).disable();
      this.amountInput = new Textbox({ ...this.amountInput, hidden: true } as any);

      this.form.get(this.invoiceInput.key).setValue(null);
      this.form.get(this.invoiceInput.key).disable();
      this.invoiceInput = new Textbox({ ...this.invoiceInput, hidden: true } as any);
    }

    this.setInputs();
    this.changeDetector.markForCheck();
  }

  save(): void {
    const notes = this.form.value?.notes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.form.get(this.noteInput.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.form.get(this.noteInput.key).setErrors(null);
    }
    if (this.form.valid) {
      const pend = this.pendCodesInput.getSelectedOption(this.form);
      const hasPend = pend != null;
      if (hasPend) {
        if (pend.extra.isAutoclose) {
          this.isConfirmationModalVisible = true;
        } else {
          this.savePend();
        }
      }
    } else {
      this.markAllAsTouched();
    }
    this.changeDetector.markForCheck();
  }

  savePend(): void {
    const chasePendItem = new ChasePendItem({
      ...this.form.value,
      pendStatusId: this.form.get(this.statusInput.key).value,
      chaseIds: this.chaseIds,
    });

    this.createPendService.save(chasePendItem).subscribe(data => {
      if (data > 0) {
        this.onUpdate.emit();
        this.messagingService.showToast("Pend saved successfully", SeverityType.SUCCESS);
        this.close();
      } else {
        this.messagingService.showToast("Pend saved failed", SeverityType.ERROR);
      }
    });
    this.isConfirmationModalVisible = false;
  }

  setInputs(): void {
    this.inputs = [
      this.statusInput,
      this.ownerInput,
      this.companyInput,
      this.amountInput,
      this.invoiceInput,
      this.severityInput,
      this.pendReasonInput,
    ];
    this.formService.updateDom.next();
  }

  cancelConfirmation($event) {
    this.isConfirmationModalVisible = false;
  }

  private subscribeToGetPendReasons(): void {
    if (this.getPendReasonsSubscription != null) {
      this.getPendReasonsSubscription.unsubscribe();
    }

    this.getPendReasonsSubscription = this.form.get(this.pendCodesInput.key).valueChanges
      .pipe(
        debounceTime(300),
        switchMap(() => this.getPendReasons())
      )
      .subscribe((data: any[]): void => {
        data.forEach(x => x.value = Number(x.value));
        this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, options: data } as any);
        this.setInputs();
      });
  }

  private getPendReasons(): Observable<SelectableInput[]> {
    const pendCodeValue = this.form.get(this.pendCodesInput.key).value;

    if (pendCodeValue === PendType.PC100 || pendCodeValue === PendType.PC107) {
      this.form.get(this.pendReasonInput.key).enable();
      this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: false } as any);
      this.form.get(this.noteInput.key).setValidators([Validators.minLength(4), Validators.maxLength(1000)]);

      return this.createPendService.getPendReasons(pendCodeValue);
    } else {
      this.form.get(this.pendReasonInput.key).disable();
      this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: true } as any);
      this.form.get(this.noteInput.key).setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(1000)]);

      return of([]);
    }
  }

  private setControlsVisibiltyForPC304(): void {
    this.sink.add(
      this.form.get(this.pendCodesInput.getMasterKey()).valueChanges
        .subscribe(value => {
          const pend = this.pendCodesInput.getSelectedOption(this.form);
          this.severityInput = new Dropdown({ ...this.severityInput, hidden: false } as any);
          this.ownerInput = new Dropdown({ ...this.ownerInput, hidden: false } as any);
          if (value === PendType.PC140) {
            this.severityInput.hidden = !this.clinical;
          } else if (value === PendType.PC304 || value === PendType.PC301) {
            this.form.get(this.severityInput.key).setValue(this.severityInput.options[2].value);
            this.ownerInput.hidden = true;
          } else if (!pend.extra.isClinical) {
            this.severityInput.hidden = true;
          } else {
            this.severityInput.hidden = false;
            this.ownerInput.hidden = false;
          }
        })
    );
  }
}
