import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateGreaterThanValidator } from "../../../../dynamic-forms/validators/date-greather-than.validator";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { dateTypes } from "../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { CODING_REVIEW_MODE } from "../../member/chase-detail/chase-detail-chart/attributes";
import { CodingReviewMode } from "../project-config/coding-review-mode.enum";
import { ProjectAttribute } from "../project-config/models/project-attribute.model";
import { ProjectConfigSummary } from "../project-config/models/project-config-summary.model";
import { ProjectConfigurationService } from "../project-config/project-config.service";
import { ProjectType } from "../project-type.enum";
import { ProjectService } from "../project.service";
import { HccId } from "./hcc-id.enum";
import { Hcc } from "./hcc.enum";
import { Hcc2Id } from "./hcc2-id.enum";
import { HccRxId } from "./hccRx-id.enum";
import { LineOfBusiness } from "./line-of-business.enum";
import { LineOfBusinessId } from "./line-of-businessId.enum";
import { NewProjectService } from "./new-project.service";
import { ProductType } from "./product-type.enum";
import { ProjectCreateModel } from "./project-create.model";

@Component({
  selector: "app-new-project",
  templateUrl: "./new-project.component.html",
  styleUrls: ["./new-project.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewProjectComponent implements OnInit {
  measurementInput: Dropdown;
  reportingYearInput: Dropdown;
  @Input() isNewProjectVisible = false;
  @Input() hasClientOrgsOptions = false;
  @Input() hasProductTypesOptions = false;
  @Output() formClose = new EventEmitter();

  selectedProjectType: number;
  projectCreateModel: ProjectCreateModel;

  formGroup: FormGroup;
  riskFormGroup: FormGroup;

  clientOrgs: Dropdown;
  hcc: Dropdown;
  hcc2: Dropdown;
  lineOfBusiness: Dropdown;
  hccRx: Dropdown;
  lineOfBusinessRx: Dropdown;
  productTypes: Dropdown;
  projectName: Textbox;
  projectOwners: Dropdown;
  projectTypes: Dropdown;
  projectRetrievalType: Dropdown;

  projectStartDate: Calendar;
  projectEndDate: Calendar;
  projectReviewPeriodFromDate: Calendar;
  projectReviewPeriodThruDate: Calendar;
  isSaveButtonClicked = false;
  isShowProjectReviewPeriod = false;
  isShowProjectPeriod = false;
  isShowMeasurementAndReportingYear = false;
  productType: any[];
  selectedlineOfBusiness: string;
  selectedlineOfBusinessRx: string;
  codingReviewMode: Dropdown;
  projectId: number;

  get useRiskSettings(): boolean {
    return NumberHelper.isAvailable(this.selectedProjectType) ?
      (this.selectedProjectType === ProjectType.RISK || this.selectedProjectType === ProjectType.IVA) :
      false;
  }

  get isFormValid(): boolean {
    return (this.useRiskSettings ?
      (this.formGroup.valid && this.riskFormGroup.valid) : this.formGroup.valid) && !this.isSaveButtonClicked;
  }

  get isLOBRxMedicareRx(): boolean {
    return this.riskFormGroup.get(this.lineOfBusinessRx.key).value === LineOfBusiness.MEDICARE_RX;
  }

  get isLOBRxMedicare(): boolean {
    return this.riskFormGroup.get(this.lineOfBusiness.key).value === LineOfBusiness.MEDICARE;
  }

  get isChartLakeProject(): boolean {
    return NumberHelper.isAvailable(this.selectedProjectType) ?
      this.selectedProjectType === ProjectType.CHART_LAKE :
      false;
  }

  get isRiskProject(): boolean {
    return NumberHelper.isAvailable(this.selectedProjectType) ?
      this.selectedProjectType === ProjectType.RISK :
      false;
  }

  get isHeidisProject(): boolean {
    return NumberHelper.isAvailable(this.selectedProjectType) ?
      this.selectedProjectType === ProjectType.HEDIS :
      false;
  }

  get isIVAProject(): boolean {
    return NumberHelper.isAvailable(this.selectedProjectType) ?
      this.selectedProjectType === ProjectType.IVA :
      false;
  }

  get isReviewAndProjectDatesFilled(): boolean {
    if (this.isRiskIvaProject) {
    return this.formGroup.get(this.projectReviewPeriodFromDate.key).value &&
      this.formGroup.get(this.projectReviewPeriodThruDate.key).value &&
      this.formGroup.get(this.projectStartDate.key).value &&
      this.formGroup.get(this.projectEndDate.key).value;
    } else {
      return true;
    }
  }

  get isShowRxInputs(): boolean {
    return this.selectedlineOfBusiness === LineOfBusiness.MEDICARE && this.isRiskProject;
  }

  get isRxHccModelSelected(): boolean {
    return StringHelper.isAvailable(this.riskFormGroup.get(this.hccRx.getMasterKey()).value?.toString());
  }

  get isCodingModel_MAHCCV242022FINAL(): boolean {
    const option = this.hcc.options.filter(x => x.text.trim() === Hcc.MAHCCV242022_FINAL)[0];
    return option === undefined ? false : option.value === this.riskFormGroup.get(this.hcc.getMasterKey()).value;
  }

  get isCodingModel_MAHCCV24(): boolean {
    const option = this.hcc.options.filter(x => x.text.trim() === Hcc.MAHCCV24)[0];
    return option === undefined ? false : option.value === this.riskFormGroup.get(this.hcc.getMasterKey()).value;
  }

  get isCodingModel_MAHCCV242023(): boolean {
    const hccOption = this.hcc?.options?.find(option => option.text.trim() === Hcc.MAHCCV242023);
    const hccCodingValue = this.riskFormGroup.get(this.hcc?.getMasterKey())?.value;

    return !!hccOption && hccOption.value === hccCodingValue;
  }

  get isCodingModel2_Selected(): boolean {
    return NumberHelper.isAvailable(this.riskFormGroup.get(this.hcc2.getMasterKey()).value);
  }

  get isRiskIvaProject(): boolean {
    return this.isRiskProject || this.isIVAProject;
  }

  get isShowAllHccOptions(): boolean {
    const reviewFromDate = this.formGroup.get(this.projectReviewPeriodFromDate.getMasterKey()).value as dateTypes;
    return this.isRiskIvaProject && DateHelper.isAfter(reviewFromDate, new Date(2024, 11, 31));
  }

  constructor(
    private automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private formService: FormService,
    private messagingService: MessagingService,
    private router: Router,
    private userService: UserService,
    private newProjectService: NewProjectService,
    private projectConfigurationService: ProjectConfigurationService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.initializeForm();
    if (!this.hasClientOrgsOptions) {
      this.getClientOrgs();
    }
    if (!this.hasProductTypesOptions) {
      this.getProductTypes();
    }
    this.getProjectTypes();
    this.getProjectOwners();
  }

  initializeForm(): void {
    this.projectName = new Textbox({
      key: "projectName",
      label: "Project Name",
      placeholder: "Project Name",
      validators: [Validators.required],
      errorMessages: {
        required: "Project Name is required.",
      },
    });

    this.projectStartDate = new Calendar({
      key: "projectStartDate",
      label: "Project Start Date",
      placeholder: "Enter Project Start Date",
      validators: [Validators.required],
      errorMessages: {
        required: "Enter Project Start Date",
      },
    });
    this.projectEndDate = new Calendar({
      key: "projectEndDate",
      label: "Project End Date",
      placeholder: "Enter Project End Date",
      validators: [Validators.required],
      errorMessages: {
        required: "Enter Project End Date",
      },
    });

    this.clientOrgs = new Dropdown({
      key: "clientOrgs",
      label: "Group",
      placeholder: "Select Group",
      validators: [Validators.required],
      errorMessages: {
        required: "Group is required.",
      },
      appendTo: "body",
    });

    this.productTypes = new Dropdown({
      key: "productTypes",
      label: "Product Type",
      placeholder: "Select Product",
      validators: [Validators.required],
      errorMessages: {
        required: "Product is required.",
      },
      appendTo: "body",
    });

    this.projectTypes = new Dropdown({
      key: "projectTypes",
      label: "Project Type",
      placeholder: "Select Project Type",
      validators: [Validators.required],
      errorMessages: {
        required: "Project Type is required.",
      },
      appendTo: "body",
    });

    this.projectOwners = new Dropdown({
      key: "projectOwners",
      label: "Project Owner",
      placeholder: "Select Project Owner",
      validators: [Validators.required],
      errorMessages: {
        required: "Project Owner Type is required.",
      },
      appendTo: "body",
    });

    this.projectRetrievalType = new Dropdown({
      key: "projectRetrievalType",
      label: "Project Retrieval Type",
      value: "0",
      options: [
        new SelectableInput({ text: "Chase", value: "0" }),
        new SelectableInput({ text: "Member", value: "1" }),
      ],
      appendTo: "body",
    });

    this.lineOfBusiness = new Dropdown({
      key: "lineOfBusiness",
      label: "Line Of Business",
      placeholder: "Select Line Of Business",
      validators: [Validators.required],
      errorMessages: {
        required: "Line Of Business is required.",
      },
      appendTo: "body",
    });

    this.codingReviewMode = new Dropdown({
      key: "codingReviewMode",
      label: "Coding Review Mode",
      placeholder: "Select Coding Review Mode",
      value: "Encounter",
      errorMessages: {
        required: "Coding Review Mode is required.",
      },
      appendTo: "body",
    });

    this.hcc = new Dropdown({
      key: "hcc",
      label: "Coding Model",
      placeholder: "Select Coding Model",
      appendTo: "body",
      clear: true,
    });

    this.hcc2 = new Dropdown({
      key: "hcc2",
      label: "Coding Model 2",
      placeholder: "Select Coding Model",
      appendTo: "body",
      clear: true,
    });

    this.lineOfBusinessRx = new Dropdown({
      key: "lineOfBusinessRx",
      label: "Line Of Business (Rx)",
      placeholder: "Select Line Of Business (Rx)",
      // TODO: Uncomment when we have more than one option for LOBRx.
      // validators: [Validators.required],
      // errorMessages: {
      //   required: "Line Of Business (Rx) is required.",
      // },
      appendTo: "body",
    });

    this.hccRx = new Dropdown({
      key: "hccRx",
      label: "Coding Model (Rx)",
      placeholder: "Select Coding Model (Rx)",
      appendTo: "body",
    });

    this.measurementInput =
      new Dropdown({
        key: "measurement",
        placeholder: "Select MY Year",
        options: [
          new SelectableInput({ text: "2025", value: "2025" }),
          new SelectableInput({ text: "2024", value: "2024" }),
          new SelectableInput({ text: "2023", value: "2023" }),
          new SelectableInput({ text: "2022", value: "2022" }),
          new SelectableInput({ text: "2021", value: "2021" }),
          new SelectableInput({ text: "2020", value: "2020" }),
          new SelectableInput({ text: "2019", value: "2019" }),
          new SelectableInput({ text: "2018", value: "2018" }),
        ],
        label: "Measurement Year",
        errorMessages: {
          required: "Measure year is required.",
        },
        appendTo: "body",
      });

    this.reportingYearInput =
      new Dropdown({
        key: "reporting",
        placeholder: "Select Reporting Year",
        options: [
          new SelectableInput({ text: "2026", value: "2026" }),
          new SelectableInput({ text: "2025", value: "2025" }),
          new SelectableInput({ text: "2024", value: "2024" }),
          new SelectableInput({ text: "2023", value: "2023" }),
          new SelectableInput({ text: "2022", value: "2022" }),
          new SelectableInput({ text: "2021", value: "2021" }),
          new SelectableInput({ text: "2020", value: "2020" }),
          new SelectableInput({ text: "2019", value: "2019" }),
        ],
        label: "Reporting Year",
        errorMessages: {
          required: "Reporting year is required.",
        },
        appendTo: "body",
      });

    this.projectReviewPeriodFromDate = new Calendar({
      key: "projectReviewPeriodFromDate",
      label: "Review Period From Date:",
      placeholder: "Enter Start Date",
      errorMessages: {
        required: "Enter Start Date ",
      },
    });

    this.projectReviewPeriodThruDate = new Calendar({
      key: "projectReviewPeriodThruDate",
      label: "Review Period Thru Date:",
      placeholder: "Enter End Date",
      errorMessages: {
        required: "Enter End Date ",
      },
    });
    this.formGroup = this.formService.createFormGroup([
      this.projectName,
      this.projectStartDate,
      this.projectEndDate,
      this.clientOrgs,
      this.productTypes,
      this.projectTypes,
      this.projectOwners,
      this.projectRetrievalType,
      this.measurementInput,
      this.projectReviewPeriodFromDate,
      this.projectReviewPeriodThruDate,
      this.reportingYearInput,
    ]);

    this.riskFormGroup = this.formService.createFormGroup([
      this.lineOfBusiness,
      this.hcc,
      this.codingReviewMode,
      this.hcc2,
      this.lineOfBusinessRx,
      this.hccRx,
    ]);
  }

  visibleChange($event) {
    this.isNewProjectVisible = $event;
    this.formClose.emit($event);
  }

  getClientOrgs() {
    this.userService
      .getClients()
      .pipe(
        map(this.automapper.curryMany("ClientOrgModel", "SelectableInput"))
      )
      .subscribe(options => {
        this.clientOrgs.options = options;
      });
  }

  getProductTypes() {
    this.projectService.getProjectTemplateList()
      .pipe(
        map(this.automapper.curryMany("ProjectTemplateModel", "SelectableInput"))
      )
      .subscribe(options => {
        this.productTypes.options = options;
        this.changeDetector.markForCheck();
      });
  }

  getProjectTypes() {
    this.newProjectService
      .getProjectTypes()
      .subscribe(options => {
        this.projectTypes.options = options;
      });

    this.changeDetector.markForCheck();
  }

  getProjectOwners() {
    const UserSearchCriteriaModel = {
      status: "A",
      groupName: "",
      EndRecord: 32000,
    };

    this.newProjectService
      .getOwners(UserSearchCriteriaModel)
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput"))
      )
      .subscribe(options => {
        this.projectOwners.options = options;
        this.changeDetector.markForCheck();
      });
  }

  cancel($event) {
    this.selectedProjectType = null;
    this.isShowProjectPeriod = false;
    this.isShowProjectReviewPeriod = false;
    this.isShowMeasurementAndReportingYear = false;
    this.formGroup.reset();
    this.riskFormGroup.reset();
  }

  getLineOfBusiness(modelType: string): void {
    this.newProjectService
      .getLineOfBusiness(modelType)
      .subscribe(options => {
        modelType === "Hcc" ? this.lineOfBusiness = new Dropdown({ ...this.lineOfBusiness, options } as any) :
                              this.lineOfBusinessRx = new Dropdown({ ...this.lineOfBusinessRx, options } as any);
      });

    this.changeDetector.markForCheck();
  }

  getCodingReviewModeOptions(): void {
    this.projectConfigurationService
      .getCodingReviewModeOptionsList(CODING_REVIEW_MODE.attributeId)
      .subscribe(options => {
        if (this.isRiskProject) {
          this.riskFormGroup.get(this.codingReviewMode.getMasterKey()).setValidators(Validators.required);
        }
        this.codingReviewMode = new Dropdown({ ...this.codingReviewMode, options } as any);
        this.codingReviewMode.options.map(opt => {
          switch (opt.value) {
            case CodingReviewMode.ENCOUNTER:
              opt.extra.tooltip = "2.0 Encounter Mode: Allows the user to review each date of service (DOS) and all associated ICD codes for that specific DOS, one DOS at a time. Additionally, custom VRC (Validation Reason Codes) codes can be created and loading of claims data for validation is allowed.";
              break;
            case CodingReviewMode.DIAGNOSIS:
              opt.extra.tooltip = "2.1 Dx Review Mode: Allows the user to see all dates of service (DOS) and associated ICD codes that map to a unique HCC. No ability to create custom VRC codes. VRC00 for Valid ICD and VRC39 for Invalid ICD is auto-appended. Claims data validation is allowed, but the HCC card can only have Valid or Invalid action. No ability to mark one ICD as valid and the other as invalid within one HCC card.";
              break;
            case CodingReviewMode.DIAGNOSIS_V2_2:
              opt.extra.tooltip = "2.2 Dx Review Mode: Allows the user to see all dates of service (DOS) and associated ICD codes that map to a unique HCC in a card. No ability to create custom VRC codes. Claims data validation is allowed. VRC00 for Valid ICD and VRC39 (claims data) or VRC99 (EVE suggested) for Invalid ICD is auto-appended.";
              break;
            case CodingReviewMode.DIAGNOSIS_V3:
              opt.extra.tooltip = "3.0 EVE RISK: Allows the broadest flexibility for risk adjustment coding. Loading/validation of claims data, creation of custom VRC codes, HCC coding, encounter coding and numerous filters for faster, more efficient drill down for your staff members.";
              break;
            default:
              break;
          }

        });
      });

    this.changeDetector.markForCheck();
  }


  createProject(): void {
    this.isSaveButtonClicked = true;
    this.projectCreateModel = new ProjectCreateModel({
      projectId: NumberHelper.isAvailable(this.projectId) ? this.projectId : null,
      projectTypeId: this.formGroup.value.projectTypes,
      clientId: this.formGroup.value.clientOrgs,
      templateId: this.formGroup.get(this.productTypes.getMasterKey()).value,
      projectName: this.formGroup.value.projectName,
    });
    this.newProjectService.createProject(this.projectCreateModel).subscribe({
      next: (newProjectId: number) => {
        this.projectId = newProjectId;
        this.updateProject();
      },
      error: (e: HttpErrorResponse) => {
        this.updateSaveButtonState();
      },
    });
  }

  private updateProject(): void {
    const selectedLineOfBusiness = this.riskFormGroup.get(this.lineOfBusiness.key)?.value;
    const lineOfBusinessId = this.useRiskSettings ? this.mapLineOfBusinessToId(selectedLineOfBusiness) : undefined;
    this.projectCreateModel = new ProjectCreateModel({
      projectId: NumberHelper.isAvailable(this.projectId) ? this.projectId : null,
      projectTypeId: this.formGroup.value.projectTypes,
      clientId: this.formGroup.value.clientOrgs,
      templateId: this.formGroup.get(this.productTypes.getMasterKey()).value,
      projectName: this.formGroup.value.projectName,
      projectOwnerUserId: this.formGroup.value.projectOwners,
      projectStartDate: DateHelper.format(this.formGroup.value.projectStartDate),
      projectEndDate: DateHelper.format(this.formGroup.value.projectEndDate),
      projectRetrievalTypeId: this.formGroup.value.projectRetrievalType == null ? 0 : this.formGroup.value.projectRetrievalType,
      projectReviewStartDate: DateHelper.format(this.formGroup.value.projectReviewPeriodFromDate),
      projectReviewEndDate: DateHelper.format(this.formGroup.value.projectReviewPeriodThruDate),
      measurementYear: this.selectedProjectType === ProjectType.HEDIS ? this.formGroup.value.measurement : null,
      reportingYear: this.selectedProjectType === ProjectType.HEDIS ? this.formGroup.value.reporting : null,
      codingReviewMode: this.riskFormGroup.get(this.codingReviewMode.getMasterKey())?.value,
      lineOfBusiness: lineOfBusinessId,
    });

    this.newProjectService.updateProject(this.projectCreateModel).subscribe({
      next: () => {
        if (this.useRiskSettings) {
          this.updateRiskIVAConfigurations();
        } else {
          this.redirectToProjectPage(this.projectId);
        }
      },
      error: (e: HttpErrorResponse) => {
        this.updateSaveButtonState();
      },
    });
  }

  private updateRiskIVAConfigurations(): void {
    const projectConfigurationSummary = new ProjectConfigSummary({
      projectId: this.projectId,
      projectConfigurationAttributes: [
        new ProjectAttribute({
          attributeId: 234,
          attributeCode: "HccModelID",
          attributeValue: [this.riskFormGroup.get(this.hcc.getMasterKey()).value, this.riskFormGroup.get(this.hcc2.getMasterKey()).value]
            .filter(Boolean)
            .join(","),
        }),
      ],
    });

    if (this.isShowRxInputs && this.isRxHccModelSelected) {
      projectConfigurationSummary.projectConfigurationAttributes.push(new ProjectAttribute({
        attributeId: 570,
        attributeCode: "RxHccModelID",
        attributeValue: this.riskFormGroup.get(this.hccRx.getMasterKey()).value.toString(),
      }));
    }

    if (this.isRiskProject) {
      projectConfigurationSummary.projectConfigurationAttributes.push(new ProjectAttribute({
        attributeId: 584,
        attributeCode: "CodingReviewMode",
        attributeValue: this.riskFormGroup.get(this.codingReviewMode.getMasterKey()).value.toString(),
      }));
    }

    this.projectConfigurationService
      .saveProjectAttributes(projectConfigurationSummary, "")
      .subscribe(() => {
        this.redirectToProjectPage(this.projectId);
      },
                 error => {
          this.updateSaveButtonState();
        });
  }

  updateSaveButtonState(): void {
    this.isSaveButtonClicked = false;
    this.formService.updateDom.next();
  }

  projectTypeSelectionChange($event: SelectableInput): void {
    this.selectedProjectType = Number($event.value);

    switch (this.selectedProjectType) {
      case ProjectType.HEDIS: {
        this.configureForHedis();
        this.configureForNonChartLake();
        break;
      }
      case ProjectType.RISK:
      case ProjectType.IVA: {
        this.configureForNonHedis();
        this.configureForNonChartLake();
        this.configureForRiskOrIva();
        this.getCodingReviewModeOptions();
        break;
      }
      case ProjectType.ADDRESS_VERIFICATION: {
        this.configureForNonHedis();
        this.configureForNonChartLake();
        this.configureForNonHedisRiskIva();
        break;
      }
      case ProjectType.CHART_LAKE: {
        this.configureForNonHedis();
        this.configureForChartLake();
        this.configureForNonHedisRiskIva();
        break;
      }
      case ProjectType.CHART_COLLECTION_ONLY: {
        this.configureForNonHedis();
        this.configureForNonChartLake();
        this.configureForNonHedisRiskIva();
        break;
        }
      default: {
        break;
      }
    }

    this.updateProductTypeOptions([ProductType.FULL_WORKFLOW_V2023, ProductType.FULL_WORKFLOW_V2024]);

    this.modifyFieldsForChartLake();

    this.changeDetector.markForCheck();
  }


  updateProductTypeOptions(productTypeOptions: any) {
    productTypeOptions.forEach(element => {
      const index = this.productTypes.options.findIndex(x => x.value === element);
      if (NumberHelper.isGreaterThan(index, -1)) {
        this.productTypes.options[index].disabled = !this.isHeidisProject;
      }
    });

  }

  isReviewPeriodStartingYear2023(): boolean {
    const reviewFromDate = this.formGroup.get(this.projectReviewPeriodFromDate.getMasterKey()).value as dateTypes;
    return DateHelper.isAfter(reviewFromDate, new Date(2022, 11, 31));
  }

  lineOfBusinessSelectionChange($event: SelectableInput): void {
    this.selectedlineOfBusiness = $event.value.toString();
    this.getHcc(this.selectedlineOfBusiness);
    if (this.isShowRxInputs) {
      this.hccRx = new Dropdown({ ...this.hccRx, options: {} } as any);
      this.getLineOfBusiness("RxHcc");
    }
  }

  setHccRxDefaults(): void {
    this.hccRx.readonly = false;

    const reviewFromDate = this.formGroup.get(this.projectReviewPeriodFromDate.getMasterKey()).value as dateTypes;
    const hccRx = this.riskFormGroup.get(this.hccRx.getMasterKey());
    const lineOfBusiness = this.riskFormGroup.get(this.lineOfBusiness.getMasterKey()).value;

    if (this.isRiskIvaProject && lineOfBusiness === LineOfBusiness.MEDICARE) {
      if (DateHelper.isAfter(reviewFromDate, new Date(2022, 11, 31))) {
        hccRx.setValue(HccRxId.CMS_RxHCC_V08);
      } else if (DateHelper.isAfter(reviewFromDate, new Date(2019, 11, 31))) {
        hccRx.setValue(HccRxId.CMS_RxHCC_V05);
        this.hccRx.readonly = true;
      }
    }
  }

  lineOfBusinessRxSelectionChange($event: SelectableInput): void {
    const lineOfBusinessRxControl = this.riskFormGroup.get(this.lineOfBusinessRx.getMasterKey());
    this.getHcc(lineOfBusinessRxControl.value, lineOfBusinessRxControl.value !== null);
    this.changeDetector.markForCheck();
  }

  redirectToProjectPage(newProjectId: number): void {
    this.isNewProjectVisible = false;
    this.messagingService.showToast("New Project Created.", SeverityType.SUCCESS);
    this.router.navigateByUrl(`project/${newProjectId}`);
    this.changeDetector.markForCheck();
  }

  productTypeSelectionChange($event: SelectableInput): void {
    const projectRetrievalTypeControl = this.formGroup.get(this.projectRetrievalType.getMasterKey());
    projectRetrievalTypeControl.enable();
  }

  projectEndDateChange(): void {
    const dateGreaterThan = `Project end date can not be less than Project start date`;
    const dateValidators = [Validators.required,
                            dateGreaterThanValidator(this.formGroup?.value?.projectStartDate)];

    const projectEndDateControl = this.formGroup.get(this.projectEndDate.getMasterKey());
    projectEndDateControl.clearValidators();
    projectEndDateControl.setValidators(dateValidators);
    projectEndDateControl.setErrors({
      ...this.projectEndDate.errorMessages,
      dateGreaterThan,
    });
    this.projectEndDate.errorMessages = {
      ...this.projectEndDate.errorMessages,
      dateGreaterThan,
    };
    projectEndDateControl.updateValueAndValidity();
  }

  onDateChange(fromDate: Calendar, toDate: Calendar): void {
      const fromDateControl = this.formGroup.get(fromDate.key);
      const toDateControl = this.formGroup.get(toDate.key);
      const hcc2 = this.riskFormGroup.get(this.hcc2.getMasterKey());

      if (fromDateControl.value?.getFullYear() < 2023) {
        hcc2.setValue(null);
        this.riskFormGroup.get(this.lineOfBusinessRx.getMasterKey()).setValue(null);
        this.riskFormGroup.get(this.hccRx.getMasterKey()).setValue(null);
        hcc2.disable();
      } else {
        hcc2.enable();
      }

      if (fromDateControl.value && toDateControl.value) {
      {
        if ((new Date(fromDateControl.value)) < (new Date(toDateControl.value))) {
          fromDateControl.setErrors(null);
        } else {
          fromDateControl.setErrors({ error: "Please select a From Date that is before the Thru Date" });
        }
      }

      if (this.projectReviewPeriodFromDate.getMasterKey() === fromDate.key) {
        this.riskFormGroup.reset();
      }
    }
  }

  private getHcc(LOB: string, setRxDefaults: boolean = true): void {
    this.newProjectService
      .getHCC(LOB)
      .subscribe(options => {
        if (LOB === LineOfBusiness.MEDICARE_RX) {
          this.hccRx = new Dropdown({
            ...this.hccRx,
            options,
          } as any);

          this.setHccRxValidation();
          if (setRxDefaults) {
            this.setHccRxDefaults();
          }

        } else {
          if (this.isLOBRxMedicare) {
            const hcc2Options = [...options.filter(o => o.text.trim().includes("V28"))];
            this.hcc2 = new Dropdown({
              ...this.hcc2,
              options: hcc2Options,
            } as any);
          }
          const hccOptions = this.isLOBRxMedicare && !this.isShowAllHccOptions ? [...options.filter(o => !o.text.trim().includes("V28"))] : options;
          this.hcc = new Dropdown({
            ...this.hcc,
            options: hccOptions,
          } as any);

          this.setHccValidation();
          this.setHccDefaults(setRxDefaults);
        }
      });
  }

  setHccDefaults(setRxDefaults: boolean = true): void {
    this.hcc.readonly = false;
    this.hcc2.readonly = false;
    this.hccRx.readonly = false;
    this.hcc.clear = true;
    this.hcc2.clear = true;
    this.lineOfBusinessRx.readonly = false;

    const reviewFromDate = this.formGroup.get(this.projectReviewPeriodFromDate.getMasterKey()).value as dateTypes;
    const hcc = this.riskFormGroup.get(this.hcc?.getMasterKey());
    const hcc2 = this.riskFormGroup.get(this.hcc2.getMasterKey());
    const lineOfBusinessRx = this.riskFormGroup.get(this.lineOfBusinessRx.getMasterKey());
    const lineOfBusiness = this.riskFormGroup.get(this.lineOfBusiness.getMasterKey()).value;

    if (this.isRiskIvaProject && lineOfBusiness === LineOfBusiness.MEDICARE) {
      if (DateHelper.isAfter(reviewFromDate, new Date(2024, 11, 31))) {
        hcc.setValue(HccId.MAHCC_V28);
        this.hcc.readonly = true;
        this.hcc.clear = false;
      } else if (DateHelper.isAfter(reviewFromDate, new Date(2022, 11, 31))) {
        hcc.setValue(HccId.MAHCC_V24);
        hcc2.setValue(Hcc2Id.MAHCC_V28);
        if (setRxDefaults) {
          lineOfBusinessRx.setValue("Medicare Rx");
          this.lineOfBusinessRxSelectionChange(new SelectableInput({ text: "Medicare Rx", value: "Medicare Rx" }));
        }

        this.hcc.readonly = true;
        this.hcc2.readonly = true;
        this.hcc.clear = false;
        this.hcc2.clear = false;
        this.hccRx.clear = true;
        this.lineOfBusinessRx.clear = true;
      } else if (DateHelper.isAfter(reviewFromDate, new Date(2019, 11, 31))) {
        hcc.setValue(HccId.MAHCC_V24);
        lineOfBusinessRx.setValue("Medicare Rx");
        this.lineOfBusinessRxSelectionChange(new SelectableInput({ text: "Medicare Rx", value: "Medicare Rx" }));

        this.hcc.readonly = true;
        this.lineOfBusinessRx.readonly = true;
      } else if (DateHelper.isAfter(reviewFromDate, new Date(2018, 11, 31))) {
        hcc.setValue(HccId.MAHCC_V24_DOS_2019);
        this.hcc.readonly = true;
        this.hcc.clear = false;
      } else if (DateHelper.isAfter(reviewFromDate, new Date(2017, 11, 31))) {
        hcc.setValue(HccId.MAHCC_V23_DOS_2018);
        this.hcc.readonly = true;
        this.hcc.clear = false;
      } else if (DateHelper.isAfter(reviewFromDate, new Date(2016, 11, 31))) {
        hcc.setValue(HccId.MAHCC_V22_DOS_2017);
        this.hcc.readonly = true;
        this.hcc.clear = false;
      }
    }
  }

  onhcc2Selection(event): void {
    if (NumberHelper.isAvailable(event.value)) {
      this.riskFormGroup.get(this.hcc.getMasterKey()).setErrors(null);
      this.riskFormGroup.get(this.hcc.getMasterKey()).setValidators(null);

      if (this.isCodingModel_MAHCCV242022FINAL && this.isCodingModel2_Selected) {
        this.messagingService.showToast(`${Hcc.MAHCCV242022_FINAL} cannot be combined with V28 Model`, SeverityType.INFO);
        this.riskFormGroup.get(this.hcc2.getMasterKey()).setValue(null);
      }
    } else {
      this.setHccValidation();
    }
  }

  onhccSelection(event): void {
    this.hcc2.readonly = false;
    this.hccRx.readonly = false;
    if (this.isReviewPeriodStartingYear2023() && (this.isCodingModel_MAHCCV24 || this.isCodingModel_MAHCCV242023)) {
        this.riskFormGroup.get(this.hcc2.getMasterKey()).setValue(45);
        this.hcc2.readonly = true;
    }
    if (this.isLOBRxMedicare && !this.isCodingModel_MAHCCV242023 && !this.isCodingModel_MAHCCV24) {
        this.riskFormGroup.get(this.hccRx.getMasterKey()).setValue(null);
        this.riskFormGroup.get(this.lineOfBusinessRx.getMasterKey()).setValue(null);
        this.riskFormGroup.get(this.hcc2.getMasterKey()).setValue(null);
    }
    if (this.isCodingModel_MAHCCV242022FINAL && this.isCodingModel2_Selected) {
      this.messagingService.showToast(`${Hcc.MAHCCV242022_FINAL} cannot be combined with V28 Model`, SeverityType.INFO);
      this.riskFormGroup.get(this.hcc.getMasterKey()).setValue(null);
    }
  }

  private setHccValidation(): void {
    const hccControl = this.riskFormGroup.get(this.hcc.getMasterKey());
    hccControl.reset();
    this.removeValidatorForHccRxControl();
    this.hcc = new Dropdown({
      ...this.hcc,
      validators: [Validators.required],
      errorMessages: {
        required: "Coding Model is required.",
      },
    } as any);
    hccControl.setValidators(this.hcc.validators);
    hccControl.updateValueAndValidity();

  }

  private setHccRxValidation(): void {
    const hccRxControl = this.riskFormGroup.get(this.hccRx.getMasterKey());
    hccRxControl.reset();
    this.hccRx = new Dropdown({
      ...this.hccRx,
      validators: [Validators.required],
      errorMessages: {
        required: "Coding Model (Rx) is required.",
      },
    } as any);
    hccRxControl.setValidators(this.hccRx.validators);
    hccRxControl.updateValueAndValidity();
  }

  private modifyFieldsForChartLake(): void {
    const productTypeControl = this.formGroup.get(this.productTypes.getMasterKey());
    const projectRetrievalTypeControl = this.formGroup.get(this.projectRetrievalType.getMasterKey());
    const projectStartDateControl = this.formGroup.get(this.projectStartDate.getMasterKey());
    const projectEndDateControl = this.formGroup.get(this.projectEndDate.getMasterKey());

    const dateValidators = [
      Validators.required,
    ];

    if (this.isChartLakeProject) {
      productTypeControl.disable();
      productTypeControl.setValue(ProjectType.CHART_LAKE);

      projectRetrievalTypeControl.disable();
      projectRetrievalTypeControl.setValue("0");

      projectStartDateControl.clearValidators();
      projectStartDateControl.updateValueAndValidity();

      projectEndDateControl.clearValidators();
      projectEndDateControl.updateValueAndValidity();
    } else {
      productTypeControl.enable();
      productTypeControl.reset();

      projectRetrievalTypeControl.enable();
      projectRetrievalTypeControl.reset();

      projectStartDateControl.reset();
      projectStartDateControl.setValidators(dateValidators);
      projectStartDateControl.updateValueAndValidity();

      projectEndDateControl.reset();
      projectEndDateControl.setValidators(dateValidators);
      projectEndDateControl.updateValueAndValidity();
    }
  }

  private addValidatorForReviewPeriodControl(): void {
    this.formGroup.get(this.projectReviewPeriodFromDate.key).setValidators(Validators.required);
    this.formGroup.get(this.projectReviewPeriodThruDate.key).setValidators(Validators.required);
  }

  private removeValidatorForReviewPeriodControl(): void {
    this.formGroup.get(this.projectReviewPeriodFromDate.key).clearValidators();
    this.formGroup.get(this.projectReviewPeriodThruDate.key).clearValidators();
    this.formGroup.get(this.projectReviewPeriodFromDate.key).reset();
    this.formGroup.get(this.projectReviewPeriodThruDate.key).reset();
  }

  private removeValidatorForMeasureAndReportingYear(): void {
    this.formGroup.get(this.measurementInput.key).clearValidators();
    this.formGroup.get(this.measurementInput.key).reset();
    this.formGroup.get(this.reportingYearInput.key).clearValidators();
    this.formGroup.get(this.reportingYearInput.key).reset();
  }

  private removeValidatorForHccRxControl(): void {
    const lineOfBusinessRxControl = this.riskFormGroup.get(this.lineOfBusinessRx.getMasterKey());
    const hccRxControl = this.riskFormGroup.get(this.hccRx.getMasterKey());
    lineOfBusinessRxControl.clearValidators();
    hccRxControl.clearValidators();
    lineOfBusinessRxControl.reset();
    hccRxControl.reset();
  }

  private configureForHedis(): void {
    this.projectRetrievalType.options.push(new SelectableInput({ text: "Gap", value: "2" }));
    this.isShowMeasurementAndReportingYear = true;
    this.isShowProjectReviewPeriod = false;
    this.isShowProjectPeriod = true;
    this.formGroup.get(this.measurementInput.key).setValidators(Validators.required);
    this.formGroup.get(this.reportingYearInput.key).setValidators(Validators.required);
    this.removeValidatorForReviewPeriodControl();
  }

  private configureForRiskOrIva(): void {
    this.getLineOfBusiness("Hcc");
    if (this.isShowRxInputs) {
      this.hccRx = new Dropdown({ ...this.hccRx, options: {} } as any);
      this.getLineOfBusiness("RxHcc");

    }
    this.isShowProjectReviewPeriod = true;
    this.isShowProjectPeriod = true;
    this.isShowMeasurementAndReportingYear = false;
    this.removeValidatorForMeasureAndReportingYear();
    this.addValidatorForReviewPeriodControl();
  }

  private configureForNonHedisRiskIva(): void {
    this.isShowProjectReviewPeriod = false;
    this.isShowProjectPeriod = true;
    this.isShowMeasurementAndReportingYear = false;
    this.removeValidatorForMeasureAndReportingYear();
    this.removeValidatorForReviewPeriodControl();
  }

  private configureForNonHedis(): void {
    const index = this.projectRetrievalType.options.findIndex(x => x.value === "2");

    if (index > -1) {
      this.projectRetrievalType.options.splice(index, 1);
      this.formGroup.get("projectRetrievalType").reset();
    }
  }

  private configureForChartLake(): void {
    const index = this.productTypes.options.findIndex(x => x.value === ProductType.CHART_LAKE);
    if (index === -1) {
      this.productTypes.options.push(this.productType[0]);
    }
  }

  private configureForNonChartLake(): void {
    const index = this.productTypes.options.findIndex(x => x.value === ProductType.CHART_LAKE);
    if (index > -1) {
      this.productType = this.productTypes.options.splice(index, 1);
      this.formGroup.get("productTypes").reset();
    }
  }
  private mapLineOfBusinessToId(lineOfBusiness?: string): number | undefined {
    const lineOfBusinessMapping: Record<string, LineOfBusinessId> = {
        [LineOfBusiness.COMMERCIAL]: LineOfBusinessId.COMMERCIAL,
        [LineOfBusiness.MEDICARE]: LineOfBusinessId.MEDICARE,
        [LineOfBusiness.MEDICAID]: LineOfBusinessId.MEDICAID,
    };

    return lineOfBusinessMapping[lineOfBusiness ?? ""];
}
}
