import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-clinical-productivity-report-dw",
  templateUrl: "./clinical-productivity-report-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalProductivityReportDWComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.CLINICAL_PRODUCTIVITY_REPORT_DW;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects"];
    this.hideFilters = [
      HideFilters.USER_ID,
      HideFilters.PROJECT_ID,
      HideFilters.ORG_ID,
    ];
  }

}
