import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
    selector: "app-intake-productivity",
    templateUrl: "./intake-productivity.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntakeProductivityComponent {
    filters: string[] = ["Projects"];
    dashboardType = LookerRiskDashboardType.INTAKE_PRODUCTIVITY_REPORT;
    hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
