<div [formGroup]="formGroup" [ngClass]="model.classOverride" class="control control--input control--textbox" [hidden]="hidden">
  <!-- Label Section -->
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">
      {{ getLabel(model.label, useTitleCase) }}
    </label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
  </div>

  <!-- Textbox and Button -->
  <div class="textbox-with-button">
    <input  #textbox
      pInputText
      [ngClass]="classes"
      [readonly]="model.readonly"
      [type]="model.type"
      [autocomplete]="model.autocomplete"
      [id]="model.key"
      [placeholder]="model.placeholder"
      (change)="onChangeEvent($event)"
      (input)="onInput.emit($event)"
      (onKeyUp)="onKeyUp.emit($event)"
      (focus)="onFocus.emit($event)"
      (click)="onClickEvent($event)"
      (blur)="onBlur.emit($event)"
      [formControlName]="model.key"
      [title]="model.title"
      [maxlength]="model.maxlength"
    />
    <app-button
      class="control__button"
      styleType="pi"
      type="pi"
      (onClick)="onButtonClick(textbox.value)"
      [text]="model.buttonText">
    </app-button>
  </div>
</div>