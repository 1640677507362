import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-confidence-score",
  templateUrl: "./confidence-score-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfidenceScoreReportComponent {
      filters: string[] = ["Projects"];
      dashboardType = LookerRiskDashboardType.ACCURACY_REPORT;
      hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
